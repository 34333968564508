import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import { Link as RRLink, useNavigate } from '@reach/router';
import format from 'date-fns/format';
import { useCallback, useMemo, useState } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';
import { ThemeUIStyleObject } from 'theme-ui';

import { analytics } from '../../analytics/analytics';
import {
  Advert,
  advertApi,
  getJobTypeByValue,
  getPositionTypeByValue,
  JobType,
  PositionType,
} from '../../api/advert';
import { ApiAdvertTag } from '../../api/advert-tags';
import { ApiUser } from '../../api/auth';
import { ColouredTooltip } from '../../components/ColouredTooltip';
import { Box } from '../../components/common/Box';
import { Flex } from '../../components/common/Flex';
import { Hide } from '../../components/common/Hide';
import { Link } from '../../components/common/Link';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalKeyInformation,
  ModalTextButton,
  ModalTitle,
} from '../../components/common/Modal';
import { Text } from '../../components/common/Text';
import { calculateIndicatorColour } from '../../components/ContentEditor/AdGrader/ScorePanel';
import { Rotate } from '../../components/Headless/Rotate';
import { Bell, ChevronDown, Search } from '../../components/Icon';
import { MultipleUserSelector } from '../../components/MultipleUserSelector';
import { SelectedItemContainer, SelectedItemLabel } from '../../components/Tag';
import { useUser } from '../../context/UserContext';
import { useWindowSize } from '../../context/WindowSizeContext';
import { RuntimeError } from '../../Error/BaseErrors';
import { useTheme } from '../../hooks/useTheme';
import { useToggle } from '../../hooks/useToggle';
import logger from '../../utils/logger';
import {
  useAdvertTableMachine,
  useAdvertTableMachineActions,
  useAdvertTableMachineState,
} from './AdvertTableMachineContext';
import {
  ActiveFilterPill,
  ActiveFilterPillList,
  Filterable,
  InlineFilterable,
  SortableHeader,
} from './DashboardTable';
import { FavouriteAdvertButton } from './FavouriteAdvertButton';
import { FilterCollectionFormModal } from './FilterCollectionFormModal';
import { FilterCollectionListModal } from './FilterCollectionListModal';
import { AdvertTableFilter } from './machines/advertTableMachine';
import { TagAutocomplete } from './TagAutocomplete';

function validateIsFilterArray(
  value: AdvertTableFilter | AdvertTableFilter[] | undefined
): asserts value is AdvertTableFilter[] | undefined {
  if (!Array.isArray(value)) {
    throw new RuntimeError(
      'Tag filter should be an array, single item given',
      'Something went wrong loading the dashboard'
    );
  }
}

function validateIsSingularFilter(
  value: AdvertTableFilter | AdvertTableFilter[] | undefined
): asserts value is AdvertTableFilter | undefined {
  if (Array.isArray(value)) {
    throw new RuntimeError(
      'Tag filter should be a single item, array given',
      'Something went wrong loading the dashboard'
    );
  }
}

const SORT_KEYS = {
  JOB_TITLE: 'jobTitle',
  COMPANY: 'company',
  LOCATION: 'location',
  SCORE_INCLUSIVITY: 'scoreInclusivity',
  SCORE_READABILITY: 'scoreReadability',
  SCORE_GENDER_TONE: 'scoreGenderTone',
  CREATED_AT: 'createdAt',
  CREATED_BY: 'createdBy',
  SHARED_AT: 'sharedAt',
};

const FILTER_KEYS = {
  JOB_TYPE: 'jobType',
  POSITION_TYPE: 'positionType',
  COMPANY: 'company',
  SCORE_READABILITY: 'scoreReadability',
  SCORE_GENDER_TONE: 'scoreGenderTone',
  LOCATION: 'location',
  TAGS: 'tags',
  CREATED_BY: 'createdBy',
};

interface ColumnOptions {
  width: string;
}

export const buildFavouriteOrEditActionColumn = (options: ColumnOptions) => {
  return {
    id: 'favouriteOrEdit',
    Header: () => {
      const [{ hasActiveFilters }] = useAdvertTableMachine();

      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
          <OpenSavedFiltersButton isActive={hasActiveFilters} />
        </Box>
      );
    },
    accessor: (props: Advert) => props,
    Cell: ({ value: advert }: { value: Advert }) => {
      return (
        <Box></Box>
        /*<FavouriteOrEditActionCell
          advert={advert}
          onFavourite={async (variationId) => {
            actions.onFavourite(advert, variationId);
          }}
          onUnfavourite={async () => {
            actions.onUnfavourite(advert);
          }}
          onQuickView={(advert, variationId) => {
            actions.openQuickViewModal(advert, variationId);
          }}
        />*/
      );
    },
    width: options.width,
  };
};

interface OpenSavedFiltersButtonProps {
  isActive: boolean;
}

const OpenSavedFiltersButton: React.FC<OpenSavedFiltersButtonProps> = ({ isActive }) => {
  const { on, setOn, setOff } = useToggle();

  const iconColor = isActive ? '#FF2779' : '#d8d8d8';

  return (
    <React.Fragment>
      <ColouredTooltip sx={{ bg: 'accent' }} label="View saved filters">
        <Box as="button" sx={{ bg: 'unset', border: 'none', cursor: 'pointer' }} onClick={setOn}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
            height="22px"
            width="22px"
            fill={iconColor}
            stroke={iconColor}
          >
            <g>
              <path
                d="M17.93 28.9L33.22 2.42c.24-.41-.06-.92-.53-.92H2.11c-.47 0-.76.51-.53.92L16.87 28.9c.24.41.83.41 1.06 0z"
                strokeWidth="3"
                strokeMiterlimit="2.6131"
              />
              <path
                d="M20.91 38.83H13.9c-1.1 0-2-.9-2-2v-20.3c0-1.1.9-2 2-2h7.01c1.1 0 2 .9 2 2v20.3c0 1.1-.9 2-2 2z"
                strokeMiterlimit="10"
              />
            </g>
          </svg>
        </Box>
      </ColouredTooltip>

      <FilterCollectionListModal isOpen={on} onDismiss={setOff} />
    </React.Fragment>
  );
};

interface JobTitleCellValues {
  original: Advert;
  id: number;
  isSharedAndUnseen: boolean;
  jobTitle: string;
  jobType: {
    label: string;
    value: JobType;
  };
  positionType: {
    label: string;
    value: PositionType;
  };
  locationType: {
    label: string;
    value: string;
  };
}

export const buildJobTitleColumn = (options: ColumnOptions) => {
  return {
    id: 'jobTitle',
    Header: () => {
      const [{ filters }, actions] = useAdvertTableMachine();

      const jobTypeFilter = filters.get(FILTER_KEYS.JOB_TYPE);
      const positionTypeFilter = filters.get(FILTER_KEYS.POSITION_TYPE);
      const locationTypeFilter = filters.get(FILTER_KEYS.LOCATION);

      validateIsSingularFilter(jobTypeFilter);
      validateIsSingularFilter(positionTypeFilter);
      validateIsSingularFilter(locationTypeFilter);

      const hasActiveFilter = jobTypeFilter || positionTypeFilter || locationTypeFilter;

      return (
        <Box
          sx={{
            paddingLeft: 'calc(1.5rem - 7px)',
            marginLeft: 0,
          }}
        >
          <SortableHeader sortName={SORT_KEYS.JOB_TITLE}>Job title</SortableHeader>

          {hasActiveFilter ? <Box sx={{ height: '4px' }} /> : null}

          <ActiveFilterPillList>
            {locationTypeFilter ? (
              <ActiveFilterPill onClear={() => actions.clearFilter(FILTER_KEYS.LOCATION)}>
                {locationTypeFilter.label}
              </ActiveFilterPill>
            ) : null}
            {jobTypeFilter ? (
              <ActiveFilterPill onClear={() => actions.clearFilter(FILTER_KEYS.JOB_TYPE)}>
                {jobTypeFilter.label}
              </ActiveFilterPill>
            ) : null}
            {positionTypeFilter ? (
              <ActiveFilterPill onClear={() => actions.clearFilter(FILTER_KEYS.POSITION_TYPE)}>
                {positionTypeFilter.label}
              </ActiveFilterPill>
            ) : null}
          </ActiveFilterPillList>
        </Box>
      );
    },
    accessor: (props: Advert): JobTitleCellValues => {
      return {
        original: props,
        id: props.id,
        isSharedAndUnseen: props.isSharedAndUnseen,
        jobTitle: props.jobTitle,
        jobType: {
          label: getJobTypeByValue(props.jobType)?.label?.toLowerCase(),
          value: props.jobType,
        },
        positionType: {
          label: getPositionTypeByValue(props.positionType)?.label?.toLowerCase(),
          value: props.positionType,
        },
        locationType: {
          label: 'Location',
          value: props.jobLocation,
        },
      };
    },
    Cell: ({ value }: { value: JobTitleCellValues }) => {
      const actions = useAdvertTableMachineActions();

      const prefetchAdvertData = usePrefetchAdvertData({
        advertId: value.id,
      });

      const jobTitleStyles: any = {
        p: 0,
        bg: 'transparent',
        textAlign: 'left',
        color: 'black',
        fontWeight: 500,
        fontSize: 2,
        letterSpacing: '-0.02rem',
        lineHeight: '1.25',
        borderRadius: '0',
        border: 'none',
        borderBottom: '2px solid',
        borderBottomColor: 'transparent',
        textDecoration: 'none',
        outline: 'none',
        cursor: 'pointer',
        minWidth: 'auto',
        transition: 'all 300ms',
        '&:hover, &:focus': {
          bg: 'transparent',
          textDecoration: 'none',
          color: 'accent',
          borderBottomColor: 'accent',
        },
      };

      return (
        <div>
          <ColouredTooltip label={'View your advert'} sx={{ bg: 'primary' }}>
            <div>
              <Link
                to={`/advert/${value.id}`}
                sx={jobTitleStyles}
                onMouseEnter={prefetchAdvertData}
              >
                {value.jobTitle}
              </Link>

              {value.isSharedAndUnseen && (
                <Box sx={{ display: 'inline', color: 'accent', ml: 1, fontSize: 1 }}>
                  <Bell />
                </Box>
              )}
            </div>
          </ColouredTooltip>
          <div>
            <InlineFilterable
              helperText={`Filter by ${value.locationType.label}`}
              onClick={() => {
                actions.setFilter(FILTER_KEYS.LOCATION, value.locationType);
              }}
            >
              {value.locationType.value}
            </InlineFilterable>
            {value.locationType.value.length > 0 ? ', ' : ''}
            <InlineFilterable
              helperText={`Filter by ${value.jobType.label}`}
              onClick={() => {
                actions.setFilter(FILTER_KEYS.JOB_TYPE, value.jobType);
              }}
            >
              {value.jobType.label}
            </InlineFilterable>
            ,{' '}
            <InlineFilterable
              helperText={`Filter by ${value.positionType.label}`}
              onClick={() => {
                actions.setFilter(FILTER_KEYS.POSITION_TYPE, value.positionType);
              }}
            >
              {value.positionType.label}
            </InlineFilterable>
          </div>
        </div>
      );
    },
    width: options.width,
  };
};

interface ScoreIndicatorProps {
  score: number | null;
}

export const ScoreIndicator = (props: ScoreIndicatorProps) => {
  const colour = React.useMemo(() => {
    return calculateIndicatorColour(props.score);
  }, [props.score]);

  return (
    <Flex
      sx={{
        backgroundColor: colour,
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 700,
        fontSize: 2,
        height: '35px',
        width: '55px',
        letterSpacing: ' -0.05rem',
      }}
    >
      {/* eslint-disable-next-line eqeqeq */}
      <Box>{props.score === null || props.score == -1 ? '--' : `${props.score}%`}</Box>
    </Flex>
  );
};

const calculateGenderToneIndicatorColour = (
  genderTone: string | null
): GenderToneIndicatorColorProps => {
  let genderToneLowerCase = genderTone == null ? '' : genderTone.toLowerCase();

  if (genderToneLowerCase.includes('masculine')) {
    return { color: '#3292cf', backgroundColor: 'rgb(193, 222, 241)' };
  }

  if (genderToneLowerCase === 'neutral') {
    return { color: 'rgb(0, 0, 0)', backgroundColor: 'rgb(232, 232, 232)' };
  }

  if (genderToneLowerCase.includes('feminine')) {
    return { color: 'rgb(223, 0, 116)', backgroundColor: 'rgb(255, 192, 203)' };
  }

  return { color: '#fff', backgroundColor: '#e8e8e8' };
};

interface GenderToneIndicatorColorProps {
  color: string;
  backgroundColor: string;
}

interface GenderToneIndicatorProps {
  score: string | null;
  sx?: ThemeUIStyleObject;
}

export const GenderToneIndicator = (props: GenderToneIndicatorProps) => {
  const colour = React.useMemo(() => {
    return calculateGenderToneIndicatorColour(props.score);
  }, [props.score]);

  return (
    <Flex
      sx={{
        backgroundColor: colour.backgroundColor,
        color: colour.color,
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 500,
        fontSize: '13px',
        height: '35px',
        width: 'auto',
        letterSpacing: ' -0.015rem',
        ...(props.sx || {}),
      }}
    >
      {/* eslint-disable-next-line eqeqeq */}
      <Box>{props.score === null || props.score == '-1' ? '--' : `${props.score}`}</Box>
    </Flex>
  );
};

export const buildCompanyColumn = (options: ColumnOptions) => {
  return {
    id: 'company',
    Header: () => {
      const [{ filters }, actions] = useAdvertTableMachine();

      const companyFilter = filters.get(FILTER_KEYS.COMPANY);

      validateIsSingularFilter(companyFilter);

      return (
        <div>
          <SortableHeader sortName={SORT_KEYS.COMPANY}>Company</SortableHeader>

          {companyFilter ? (
            <React.Fragment>
              <Box sx={{ height: '4px' }} />

              <ActiveFilterPillList>
                <ActiveFilterPill onClear={() => actions.clearFilter(FILTER_KEYS.COMPANY)}>
                  {companyFilter.label}
                </ActiveFilterPill>
              </ActiveFilterPillList>
            </React.Fragment>
          ) : null}
        </div>
      );
    },
    accessor: (props: Advert) => {
      return {
        companyId: props.advertCompany.rootId,
        companyName: props.advertCompany.name,
      };
    },
    Cell: ({ value }: { value: { companyId: number; companyName: string } }) => {
      const { setFilter } = useAdvertTableMachineActions();

      return (
        <Filterable
          helperText="Filter list by this company"
          onClick={() => {
            setFilter(FILTER_KEYS.COMPANY, {
              label: value.companyName,
              value: value.companyId,
            });
          }}
        >
          {value.companyName}
        </Filterable>
      );
    },
    width: options.width,
  };
};

export const buildScoreInclusivityColumn = (options: ColumnOptions) => {
  return {
    id: 'scoreInclusivity',
    Header: () => (
      <SortableHeader sortName={SORT_KEYS.SCORE_INCLUSIVITY}>Inclusivity</SortableHeader>
    ),
    accessor: 'scoreInclusivity',
    Cell: ({ value }: { value: number }) => {
      return <ScoreIndicator score={value} />;
    },
    width: options.width,
  };
};

export const buildScoreReadabilityColumn = (options: ColumnOptions) => {
  return {
    id: 'scoreReadability',
    Header: () => (
      <SortableHeader sortName={SORT_KEYS.SCORE_READABILITY}>Readability</SortableHeader>
    ),
    accessor: 'scoreReadability',
    Cell: ({ value }: { value: number }) => {
      return <ScoreIndicator score={value} />;
    },
    width: options.width,
  };
};

export const buildScoreGenderToneColumn = (options: ColumnOptions) => {
  return {
    id: 'scoreGenderTone',
    Header: () => {
      const [{ filters }, actions] = useAdvertTableMachine();

      const filter = filters.get(FILTER_KEYS.SCORE_GENDER_TONE);

      validateIsSingularFilter(filter);

      return (
        <div>
          <SortableHeader sortName={SORT_KEYS.SCORE_GENDER_TONE}>Gender Tone</SortableHeader>

          {filter ? (
            <React.Fragment>
              <Box sx={{ height: '4px' }} />

              <ActiveFilterPillList>
                <ActiveFilterPill
                  onClear={() => actions.clearFilter(FILTER_KEYS.SCORE_GENDER_TONE)}
                >
                  {filter.label}
                </ActiveFilterPill>
              </ActiveFilterPillList>
            </React.Fragment>
          ) : null}
        </div>
      );
    },
    accessor: (props: Advert) => {
      return {
        scoreGenderTone: props.scoreGenderTone,
      };
    },
    Cell: ({ value }: { value: { scoreGenderTone: string } }) => {
      const { setFilter } = useAdvertTableMachineActions();

      return (
        <Filterable
          helperText="Filter list by gender tone"
          onClick={() => {
            setFilter(FILTER_KEYS.SCORE_GENDER_TONE, {
              label: value.scoreGenderTone + '',
              value: value.scoreGenderTone,
            });
          }}
        >
          <GenderToneIndicator score={value.scoreGenderTone} />
        </Filterable>
      );
    },
    width: options.width,
  };
};

export const buildLocationColumn = (options: ColumnOptions) => {
  return {
    id: 'location',
    Header: () => {
      const [{ filters }, actions] = useAdvertTableMachine();

      const locationFilter = filters.get(FILTER_KEYS.LOCATION);

      validateIsSingularFilter(locationFilter);

      return (
        <div>
          <SortableHeader sortName={SORT_KEYS.LOCATION}>Location</SortableHeader>

          {locationFilter ? (
            <React.Fragment>
              <Box sx={{ height: '4px' }} />

              <ActiveFilterPillList>
                <ActiveFilterPill onClear={() => actions.clearFilter(FILTER_KEYS.LOCATION)}>
                  {locationFilter.label}
                </ActiveFilterPill>
              </ActiveFilterPillList>
            </React.Fragment>
          ) : null}
        </div>
      );
    },
    accessor: 'jobLocation',
    Cell: ({ value }: { value: string }) => {
      const { setFilter } = useAdvertTableMachineActions();

      return (
        <Filterable
          helperText="Filter list by this location"
          onClick={() => {
            setFilter(FILTER_KEYS.LOCATION, {
              label: value,
              value: value,
            });
          }}
        >
          {value}
        </Filterable>
      );
    },
    width: options.width,
  };
};

export const buildTagsColumn = (options: ColumnOptions) => {
  const getSelectedTags = (
    availableTags: ApiAdvertTag[],
    selectedFilterTags: AdvertTableFilter | AdvertTableFilter[] = []
  ) => {
    validateIsFilterArray(selectedFilterTags);

    const selectedTags = [];

    for (const filterTag of selectedFilterTags) {
      const tag = availableTags.find((tag) => tag.id === filterTag.value);

      if (tag) {
        selectedTags.push(tag);
      }
    }

    return selectedTags;
  };

  const formatTagToFilterTag = (tag: ApiAdvertTag): AdvertTableFilter => {
    return {
      label: tag.label,
      value: tag.id,
    };
  };

  return {
    id: 'tags',
    Header: () => {
      const [
        { tags: availableTags, filters },
        { setFilter, clearFilter },
      ] = useAdvertTableMachine();
      const { on, toggle } = useToggle();

      const selectedFilterTags = filters.get(FILTER_KEYS.TAGS);

      const selectedTags = useMemo(() => {
        return getSelectedTags(availableTags, selectedFilterTags);
      }, [availableTags, selectedFilterTags]);

      return (
        <React.Fragment>
          <Flex
            sx={{
              alignItems: 'center',
              '.chevron-wrapper': {
                opacity: 0,
                ml: '-8px',
                color: 'textDim',
                lineHeight: 0,
                transition: 'all 150ms ease-in',
              },
              '&:hover': {
                '.chevron-wrapper': {
                  opacity: 1,
                  ml: 0,
                },
              },
            }}
            onClick={toggle}
          >
            <Flex sx={{ flexDirection: 'column' }}>
              <Text as="span">Tags</Text>

              <TagAutocomplete
                isOpen={on}
                tags={availableTags}
                selectedTags={selectedTags}
                onTagsChanged={(tags) => {
                  const filterTags = tags.map(formatTagToFilterTag);
                  setFilter(FILTER_KEYS.TAGS, filterTags);
                }}
                onClose={() => {
                  toggle();
                }}
              />
            </Flex>

            <Flex sx={{ flexDirection: 'column', ml: '2' }}>
              <Text className="chevron-wrapper">
                <Search />
              </Text>
            </Flex>
          </Flex>

          {selectedTags.length > 0 ? <Box sx={{ height: '4px' }} /> : null}

          <ActiveFilterPillList>
            {selectedTags.map((tag) => {
              return (
                <ActiveFilterPill
                  key={tag.id}
                  borderColor={tag.borderColor}
                  onClear={() => {
                    const currentFilterTags = filters.get(FILTER_KEYS.TAGS);

                    if (Array.isArray(currentFilterTags) && currentFilterTags.length <= 1) {
                      clearFilter(FILTER_KEYS.TAGS);
                      return;
                    }

                    setFilter(FILTER_KEYS.TAGS, (currentFilterTags = []) => {
                      validateIsFilterArray(currentFilterTags);

                      return currentFilterTags.filter((filterTag) => {
                        return filterTag.value !== tag.id;
                      });
                    });
                  }}
                >
                  {tag.label}
                </ActiveFilterPill>
              );
            })}
          </ActiveFilterPillList>
        </React.Fragment>
      );
    },
    accessor: 'tags',
    Cell: ({ value: tags }: { value: ApiAdvertTag[] }) => {
      const windowSize = useWindowSize();
      const [{ tags: availableTags, filters }, { setFilter }] = useAdvertTableMachine();
      const { on: advertTagsDropdownOpen, toggle: toggleAdvertTagsDropdown } = useToggle();

      const orderedTags = tags.sort((a) => (a.system ? -1 : 0));

      const maxTagsToRender = Boolean(windowSize.width && windowSize.width < 768) ? 1 : 3;
      const remainingTagCount = orderedTags.length - maxTagsToRender;

      const selectedFilterTags = filters.get(FILTER_KEYS.TAGS);

      const selectedTags = useMemo(() => {
        return getSelectedTags(availableTags, selectedFilterTags);
      }, [availableTags, selectedFilterTags]);

      return (
        <Box sx={{ position: 'relative' }}>
          {orderedTags.slice(0, maxTagsToRender).map((tag) => (
            <SelectedItemContainer
              key={tag.id}
              backgroundColor={tag.backgroundColor}
              borderColor={tag.borderColor}
              padding={'0.5rem'}
              onClick={() => {
                setFilter(FILTER_KEYS.TAGS, (currentFilterTags = []) => {
                  validateIsFilterArray(currentFilterTags);

                  return [...currentFilterTags, formatTagToFilterTag(tag)];
                });
              }}
            >
              <ColouredTooltip label="Filter list by this tag" sx={{ bg: 'black' }}>
                <SelectedItemLabel>{tag.label}</SelectedItemLabel>
              </ColouredTooltip>
            </SelectedItemContainer>
          ))}

          {remainingTagCount > 0 && (
            <React.Fragment>
              <span
                style={{ cursor: 'pointer', fontSize: '0.9rem' }}
                onClick={() => toggleAdvertTagsDropdown()}
                onKeyDown={() => toggleAdvertTagsDropdown()}
                role="button"
                tabIndex={0}
              >
                + {remainingTagCount} more
              </span>
              <TagAutocomplete
                isOpen={advertTagsDropdownOpen}
                tags={orderedTags}
                selectedTags={selectedTags}
                onTagsChanged={(tags) => {
                  const filterTags = tags.map(formatTagToFilterTag);

                  setFilter(FILTER_KEYS.TAGS, filterTags);
                }}
                onClose={toggleAdvertTagsDropdown}
              />
            </React.Fragment>
          )}
        </Box>
      );
    },
    width: options.width,
  };
};

export const buildCreatedAtColumn = (options: ColumnOptions) => {
  return {
    id: 'createdAt',
    Header: () => <SortableHeader sortName={SORT_KEYS.CREATED_AT}>Created</SortableHeader>,
    accessor: 'createdAt',
    Cell: ({ value }: { value: Date }) => <DateCell>{value}</DateCell>,
    width: options.width,
  };
};

export const buildSharedAtColumn = (options: ColumnOptions) => {
  return {
    id: 'sharedAt',
    Header: () => 'Shared',
    accessor: 'sharedAt',
    Cell: ({ value }: { value: Date }) => <DateCell>{value}</DateCell>,
    width: options.width,
  };
};

export const buildCreatedByColumn = (options: ColumnOptions) => {
  return {
    id: 'createdBy',
    Header: () => {
      const theme = useTheme();
      const [{ filters }, actions] = useAdvertTableMachine();

      const createdByFilter = filters.get(FILTER_KEYS.CREATED_BY);

      validateIsSingularFilter(createdByFilter);

      return (
        <div>
          <SortableHeader sortName={SORT_KEYS.CREATED_BY}>Builder</SortableHeader>

          {createdByFilter ? (
            <React.Fragment>
              <Box sx={{ height: '4px' }} />

              <ActiveFilterPillList>
                <ActiveFilterPill
                  borderColor={theme.colors.accent}
                  onClear={() => actions.clearFilter(FILTER_KEYS.CREATED_BY)}
                >
                  {createdByFilter.label}
                </ActiveFilterPill>
              </ActiveFilterPillList>
            </React.Fragment>
          ) : null}
        </div>
      );
    },
    accessor: (props: Advert) => {
      return {
        createdById: props.createdBy.id,
        createdByDisplayName: props.createdBy.displayName,
      };
    },
    Cell: ({ value }: { value: { createdById: number; createdByDisplayName: string } }) => {
      const { setFilter } = useAdvertTableMachineActions();

      return (
        <Filterable
          helperText="Filter list by this user"
          onClick={() => {
            setFilter(FILTER_KEYS.CREATED_BY, {
              label: value.createdByDisplayName,
              value: value.createdById,
            });
          }}
        >
          {value.createdByDisplayName}
        </Filterable>
      );
    },
    width: options.width,
  };
};

interface ClearFiltersButtonProps {
  onClick: () => void;
}

const ClearFiltersButton: React.FC<ClearFiltersButtonProps> = ({ onClick }) => {
  return (
    <Box as="button" sx={{ bg: 'unset', border: 'none', cursor: 'pointer' }} onClick={onClick}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 36 36"
        fill="none"
        stroke="#FF2779"
        strokeWidth="4"
        strokeMiterlimit="2.6131"
        height="22px"
        width="22px"
      >
        <path d="M1.41 35.41l34-34" />
        <path d="M35.41 35.41l-34-34" />
      </svg>
    </Box>
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const SaveFilterCollectionButton: React.FC = React.memo(() => {
  const { filters } = useAdvertTableMachineState();

  const { on, setOn, setOff } = useToggle();

  return (
    <React.Fragment>
      <ColouredTooltip sx={{ bg: 'accent' }} label="Save filters">
        <Box as="button" sx={{ bg: 'unset', border: 'none', cursor: 'pointer' }} onClick={setOn}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 36 36"
            fill="none"
            stroke="#FF2779"
            strokeWidth="3"
            strokeMiterlimit="2.6131"
            height="22px"
            width="22px"
          >
            <path d="M35.5 1.5h-34v25.42l8.58 8.58H35.5z" />
            <path d="M10.65 26.76h15.7v8.74h-15.7zM7.42 1.5h22.16v17.27H7.42z" />
          </svg>
        </Box>
      </ColouredTooltip>

      <FilterCollectionFormModal isOpen={on} filtersToSave={filters} onDismiss={setOff} />
    </React.Fragment>
  );
});

export const buildActionsColumn = (options: ColumnOptions) => {
  return {
    id: 'actions',
    Header: () => {
      const [{ hasActiveFilters }, { clearAllFilters }] = useAdvertTableMachine();

      return (
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          {hasActiveFilters ? (
            <React.Fragment>
              {/*<SaveFilterCollectionButton />*/}

              <ClearFiltersButton onClick={clearAllFilters} />
            </React.Fragment>
          ) : null}
        </Box>
      );
    },
    accessor: (props: Advert) => props,
    Cell: ({ value: advert }: { value: Advert }) => {
      const user = useUser();
      const [state, actions] = useAdvertTableMachine();

      return (
        <ActionMenuCell advertId={advert.id} path={`/advert/${advert.id}`}>
          <DuplicateAction advert={advert} />
          {advert.createdBy.id === user.id ? (
            <DeleteAction
              advert={advert}
              onDelete={() => {
                actions.deleteAdvert(advert);
              }}
            />
          ) : null}
          <ShareAction advert={advert} users={state.teamMembers} />
        </ActionMenuCell>
      );
    },
    width: options.width,
  };
};

interface DateCellProps {
  children: Date;
}

const DateCell: React.FC<DateCellProps> = (props) => {
  return (
    <React.Fragment>
      <Hide xs sm md lg>
        <Text sx={{ m: 0, fontSize: 1 }}>{format(props.children, 'do LLLL yyyy')}</Text>
      </Hide>

      <Hide xl>
        <Text sx={{ m: 0, fontSize: 1 }}>{format(props.children, 'do LLL')}</Text>
      </Hide>
    </React.Fragment>
  );
};

interface ActionMenuCellProps {
  advertId: number;
  path: string;
}

export const ActionMenuCell: React.FC<ActionMenuCellProps> = (props) => {
  const navigate = useNavigate();
  const prefetchAdvertData = usePrefetchAdvertData({
    advertId: props.advertId,
  });

  const buttonColor = 'primary';

  return (
    <Flex
      sx={{
        alignItems: 'center',
        height: '2.25rem',
        ml: '0.2rem',
        borderRadius: '4px',
        fontWeight: 500,
        border: `2px solid ${buttonColor}`,
      }}
    >
      <Link
        as={RRLink}
        to={props.path}
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          height: '2rem',
          fontSize: 1,
          fontWeight: 500,
          textDecoration: 'none',
          color: buttonColor,
          borderTopLeftRadius: '4px',
          borderBottomLeftRadius: '4px',
          border: '2px solid',
          borderRight: 'none',
          borderColor: buttonColor,
          px: 3,
          minWidth: '5.8rem',
          transition: '200ms all ease-in-out',
          '&:hover': {
            color: 'white',
            backgroundColor: buttonColor,
          },
          '@media (max-width: 1100px)': {
            display: 'none',
          },
        }}
        onMouseEnter={prefetchAdvertData}
      >
        Edit
      </Link>

      <Menu>
        {({ isOpen }: { isOpen: boolean }) => (
          <React.Fragment>
            <Flex
              as={MenuButton}
              aria-label="View advert actions"
              data-testid="advert-actions-menu-toggle"
              sx={{
                height: '2rem',
                width: '2rem',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                borderTopRightRadius: '4px',
                borderBottomRightRadius: '4px',
                border: '2px solid',
                transition: '200ms all ease-in-out',
                borderColor: buttonColor,
                color: buttonColor,
                '&:hover': {
                  backgroundColor: buttonColor,
                  borderColor: buttonColor,
                  color: 'white',
                },
                '@media (max-width: 1100px)': {
                  width: '100%',
                  borderRadius: '4px',
                  minWidth: '2rem',
                },
              }}
            >
              <Rotate active={isOpen}>
                <ChevronDown />
              </Rotate>
            </Flex>
            <MenuList data-testid={`advert-actions-menu-${props.advertId}`}>
              <Hide lg xl>
                <MenuItem
                  key="open"
                  data-testid="advert-actions-open"
                  onSelect={async () => {
                    navigate(props.path);
                  }}
                >
                  Edit
                </MenuItem>
              </Hide>

              {props.children}
            </MenuList>
          </React.Fragment>
        )}
      </Menu>
    </Flex>
  );
};

interface FavouriteOrEditActionCellProps {
  advert: Advert;
  onFavourite: (variationId: number) => Promise<void>;
  onUnfavourite: () => Promise<void>;
  onQuickView: (advert: Advert, variationId: number) => void;
}

export const FavouriteOrEditActionCell: React.FC<FavouriteOrEditActionCellProps> = (props) => {
  return (
    <FavouriteAdvertButton
      advert={props.advert}
      onFavourite={props.onFavourite}
      onUnfavourite={props.onUnfavourite}
      onQuickView={(variationId) => {
        props.onQuickView(props.advert, variationId);
      }}
    />
  );
};

interface DuplicateActionProps {
  advert: Advert;
}

export const DuplicateAction: React.FC<DuplicateActionProps> = ({ advert }) => {
  const navigate = useNavigate();
  const { on: modalOpen, setOn, setOff } = useToggle();

  const onDuplicate = useCallback(async () => {
    try {
      const response = await advertApi.duplicateAdvert(advert.id);
      analytics.advertDuplicated(response.advert.id);
      navigate(`/advert/${response.advert.id}`);
    } catch (e) {
      logger.logError(e);
      toast.error('Something went wrong whilst duplicating the advert');
    }
  }, [advert.id, navigate]);

  return (
    <React.Fragment>
      <Modal aria-label="Duplicate advert confirmation" isOpen={modalOpen} onDismiss={setOff}>
        <ModalClose onClick={setOff} />
        <ModalContainer>
          <ModalTitle>Are you sure you want to duplicate this advert?</ModalTitle>

          <JobSummary advert={advert} />

          <Box py="3" />

          <ModalActions>
            <ModalButton type="button" data-testid="duplicate-advert" onClick={onDuplicate}>
              I'm sure, duplicate this advert
            </ModalButton>

            <ModalTextButton type="button" onClick={setOff}>
              Cancel
            </ModalTextButton>
          </ModalActions>
        </ModalContainer>
      </Modal>

      <MenuItem key="duplicate" data-testid="advert-actions-duplicate" onSelect={setOn}>
        Duplicate Advert
      </MenuItem>
    </React.Fragment>
  );
};

interface DeleteActionProps {
  advert: Advert;
  onDelete: () => void;
}

export const DeleteAction: React.FC<DeleteActionProps> = (props) => {
  const { advert, onDelete } = props;

  const { on: modalOpen, setOn, setOff } = useToggle();

  const onDeleteAdvert = useCallback(async () => {
    try {
      onDelete();
      setOff();
    } catch (e) {
      logger.logError(e);
      toast.success('Advert deleted successfully');
    }
  }, [setOff, onDelete]);

  return (
    <React.Fragment>
      <Modal
        aria-label="Delete advert confirmation"
        variant="destructive"
        isOpen={modalOpen}
        onDismiss={setOff}
      >
        <ModalClose onClick={setOff} />
        <ModalContainer>
          <ModalTitle>Are you sure you want to delete this advert?</ModalTitle>

          <JobSummary advert={advert} />

          <Box py="3" />

          <ModalActions>
            <ModalButton type="button" data-testid="delete-advert" onClick={onDeleteAdvert}>
              I'm sure, delete this advert
            </ModalButton>

            <ModalTextButton type="button" onClick={setOff}>
              Cancel
            </ModalTextButton>
          </ModalActions>
        </ModalContainer>
      </Modal>

      <MenuItem key="delete" data-testid="advert-actions-delete" onSelect={setOn}>
        Delete Advert
      </MenuItem>
    </React.Fragment>
  );
};

interface ShareActionProps {
  advert: Advert;
  users: ApiUser[];
}

export const ShareAction: React.FC<ShareActionProps> = (props) => {
  const { on: modalOpen, setOn, setOff } = useToggle();
  const user = useUser();
  const [selectedUserIds, setSelectedUserIds] = useState<number[]>([]);
  const [shareError, setShareError] = useState<Error | undefined>(undefined);

  const userList = props.users
    .filter((member) => member.id !== user.id)
    .map((member) => ({
      ...member,
      selected: selectedUserIds.includes(member.id),
    }));

  const onShare = useCallback(async () => {
    setShareError(undefined);

    try {
      await advertApi.shareAdvert(props.advert.id, selectedUserIds);

      analytics.advertShared(props.advert.id);
      toast.success('Advert shared successfully');

      setOff();
      setSelectedUserIds([]);
    } catch (e) {
      logger.logError(e);
      setShareError(e);
      toast.error('Something went wrong whilst sharing the advert');
    }
  }, [props.advert, selectedUserIds, setOff]);

  return (
    <React.Fragment>
      <Modal
        aria-label="Share advert with team members"
        isOpen={modalOpen}
        data-testid="share-advert-modal"
        onDismiss={setOff}
      >
        <ModalClose onClick={setOff} />
        <ModalContainer>
          <ModalTitle>Who do you want to share with?</ModalTitle>

          <JobSummary advert={props.advert} />

          <Box py="3" />

          <ModalContent>
            <Box sx={{ minHeight: '310px' }}>
              <MultipleUserSelector
                users={userList}
                onUserToggled={(userId) => {
                  if (selectedUserIds.includes(userId)) {
                    setSelectedUserIds(selectedUserIds.filter((id) => id !== userId));
                    return;
                  }

                  setSelectedUserIds([...selectedUserIds, userId]);
                }}
              />
            </Box>

            {shareError && <Text>Something went wrong whilst sharing</Text>}
          </ModalContent>

          <ModalActions>
            <ModalButton
              type="button"
              data-testid="share-advert"
              variant={selectedUserIds.length === 0 ? 'disabled' : 'primaryInverted'}
              disabled={selectedUserIds.length === 0}
              onClick={onShare}
            >
              {selectedUserIds.length ? (
                <React.Fragment>
                  Share with {selectedUserIds.length} user{selectedUserIds.length === 1 ? '' : 's'}
                </React.Fragment>
              ) : (
                <React.Fragment>Share</React.Fragment>
              )}
            </ModalButton>

            <ModalTextButton type="button" onClick={setOff}>
              Cancel
            </ModalTextButton>
          </ModalActions>
        </ModalContainer>
      </Modal>

      <MenuItem key="share" data-testid="advert-actions-share" onSelect={setOn}>
        Share Advert
      </MenuItem>
    </React.Fragment>
  );
};

interface JobSummaryProps {
  advert: Advert;
}

const JobSummary: React.FC<JobSummaryProps> = ({ advert }) => {
  const jobSummary = [advert.jobTitle, advert.jobLocation, advert.advertCompany.name]
    .filter(Boolean)
    .join(', ');

  return <ModalKeyInformation>{jobSummary}</ModalKeyInformation>;
};

interface UsePrefetchAdvertDataVariables {
  advertId: number;
}

const usePrefetchAdvertData = (variables: UsePrefetchAdvertDataVariables) => {
  const prefetchAdvert = advertApi.usePrefetchAdvertFn(variables.advertId);

  const prefetchAdvertData = useCallback(() => {
    prefetchAdvert();
  }, [prefetchAdvert]);

  return prefetchAdvertData;
};
