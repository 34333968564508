import { Redirect } from '@reach/router';
import { AxiosError } from 'axios';
import { useContext, useMemo } from 'react';
import * as React from 'react';
import { useQuery } from 'react-query';

import { Advert, advertApi } from '../../api/advert';
import { ResponseStatus } from '../../api/response-status';
import { Flex } from '../../components/common/Flex';
import { FullPageSpinner } from '../../components/FullPageSpinner';
import logger from '../../utils/logger';
import { AdvertBuilderHeader } from './AdvertBuilderHeader';

type AdvertId = number | 'new';

interface ContextValue {
  advert: Advert | undefined;
  isNewAdvert: boolean;
}

const AdvertBuilderContext = React.createContext<ContextValue | undefined>(undefined);

interface AdvertBuilderProviderProps {
  advertId: AdvertId;
}

export const AdvertBuilderProvider: React.FC<AdvertBuilderProviderProps> = ({
  children,
  advertId,
}) => {
  const isNewAdvert = advertId === 'new';

  const { status, data: advert, error } = useQuery(
    ['advert', advertId],
    async () => {
      const response = await advertApi.fetchAdvert(advertId as number);

      return response.advert;
    },
    {
      enabled: !isNewAdvert,
      refetchOnWindowFocus: false,
      onError: (error: AxiosError) => {
        logger.logError(error);
      },
    }
  );

  const contextValue = useMemo(() => {
    return {
      advert,
      isNewAdvert,
    };
  }, [advert, isNewAdvert]);

  if (!isNewAdvert && ['idle', 'loading'].includes(status)) {
    return (
      <FullPageSpinner>
        <Flex
          sx={{
            flex: '1',
            minHeight: '100%',
            flexDirection: 'column',
          }}
        >
          <AdvertBuilderHeader title="Loading advert" helperText="Fetching the advert content" />
        </Flex>
      </FullPageSpinner>
    );
  }

  if (status === 'error' && error?.response?.status === ResponseStatus.NotFound) {
    return <Redirect path="" from="" to="/dashboard" noThrow />;
  }

  if (status === 'error') {
    // TODO - handle the error better...
    return (
      <FullPageSpinner>
        <Flex sx={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          Something went wrong whilst loading the advert
        </Flex>
      </FullPageSpinner>
    );
  }

  return (
    <AdvertBuilderContext.Provider value={contextValue}>{children}</AdvertBuilderContext.Provider>
  );
};

export const useAdvertBuilder = () => {
  const ctx = useContext(AdvertBuilderContext);

  if (typeof ctx === undefined) {
    throw new Error('useAdvertBuilder must be used within a AdvertBuilderProvider');
  }

  return ctx as ContextValue;
};
