import * as React from 'react';

import { ApiCompanyAnalyticsDetails } from '../../../api/company-analytics';
import { Flex } from '../../../components/common/Flex';
import { Grid } from '../../../components/common/Grid';
import { Text } from '../../../components/common/Text';
import {
  ReadingDifficultyValue,
  TimeToReadValue,
  WordCountValue,
} from '../../../components/ContentEditor/AdGrader/ScorePanel';
import { GradedWordsTable } from './GradedWordsTable';
import { ScoreIndicator } from './ScoreIndicator';
import { StatisticRowSection } from './StatisticRow';
import { StatsBox } from './StatsBox';

interface IAnalyticsReadabilityProps {
  companyAnalytics: ApiCompanyAnalyticsDetails;
}

export const AnalyticsReadability: React.FC<IAnalyticsReadabilityProps> = ({
  companyAnalytics,
}) => {
  return (
    <StatisticRowSection title="Readability">
      <Grid sx={{ gridTemplateColumns: '1fr 1fr', gap: 4 }}>
        <StatsBox sx={{ p: 3 }}>
          <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', gap: 1 }}>
            <Text sx={{ fontSize: 3, fontWeight: 'semibold' }}>Avg. Readability Score</Text>

            <ScoreIndicator
              score={companyAnalytics?.StatsAverages?.GradingReadabilityScore ?? null}
            />
          </Flex>
        </StatsBox>

        <StatsBox sx={{ p: 3 }}>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
              height: '100%',
            }}
          >
            <Text sx={{ fontSize: 3, fontWeight: 'semibold' }}>Avg. Read Time</Text>

            <div>
              {companyAnalytics ? (
                <TimeToReadValue
                  wordCount={companyAnalytics.StatsAverages.WordCount}
                  sx={{ p: 3 }}
                />
              ) : null}
            </div>
          </Flex>
        </StatsBox>

        <StatsBox sx={{ p: 3 }}>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
              height: '100%',
            }}
          >
            <Text sx={{ fontSize: 3, fontWeight: 'semibold' }}>Avg. Reading Difficulty</Text>

            <div>
              {companyAnalytics ? (
                <ReadingDifficultyValue
                  wordCount={companyAnalytics.StatsAverages.WordCount}
                  readingEase={companyAnalytics.StatsAverages.GradingReadabilityReadingEase}
                  readingGrade={companyAnalytics.StatsAverages.GradingReadabilityReadingGrade}
                  sx={{ p: 3, cursor: 'auto' }}
                />
              ) : null}
            </div>
          </Flex>
        </StatsBox>

        <StatsBox sx={{ p: 3 }}>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
              height: '100%',
            }}
          >
            <Text sx={{ fontSize: 3, fontWeight: 'semibold' }}>Avg. Word Count</Text>

            <div>
              {companyAnalytics ? (
                <WordCountValue
                  wordCount={companyAnalytics.StatsAverages.WordCount}
                  sx={{ p: 3, fontSize: '1.25rem', width: 'auto' }}
                />
              ) : null}
            </div>
          </Flex>
        </StatsBox>

        <StatsBox sx={{ py: 4, px: 3 }}>
          {companyAnalytics ? (
            <GradedWordsTable
              average={companyAnalytics.GradedWordsAverages['complex_phrases']}
              frequency={companyAnalytics.GradedWordsFrequencies['complex_phrases']}
              color="#00713b"
              textAvg="complex phrases"
              textMostCommon="complex phrases"
            />
          ) : null}
        </StatsBox>

        <StatsBox sx={{ py: 4, px: 3 }}>
          {companyAnalytics ? (
            <GradedWordsTable
              average={companyAnalytics.GradedWordsAverages['corporate_cliche']}
              frequency={companyAnalytics.GradedWordsFrequencies['corporate_cliche']}
              color="#d66600"
              textAvg="corporate cliches"
              textMostCommon="corporate cliches"
            />
          ) : null}
        </StatsBox>

        <StatsBox sx={{ py: 4, px: 3 }}>
          {companyAnalytics ? (
            <GradedWordsTable
              average={companyAnalytics.GradedWordsAverages['qualifying_phrases']}
              frequency={companyAnalytics.GradedWordsFrequencies['qualifying_phrases']}
              color="#7b5042"
              textAvg="qualifying phrases"
              textMostCommon="qualifying phrases"
            />
          ) : null}
        </StatsBox>
      </Grid>
    </StatisticRowSection>
  );
};
