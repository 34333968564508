import styled from '@emotion/styled/macro';
import { Redirect, RouteComponentProps } from '@reach/router';
import { useMachine } from '@xstate/react';
import React, { useEffect, useState } from 'react';
import { FormattedPlural } from 'react-intl';

import { analytics } from '../../analytics/analytics';
import { ApiCompanySubscriptionBillingFrequency } from '../../api/company-subscription';
import {
  ApiSubscriptionPlanPeriodUnit,
  ApiSubscriptionPlanTierType,
  DEFAULT_CURRENCY,
  SubscriptionPlanTier,
} from '../../api/subscription-plan-pricing';
import { Banner } from '../../components/common/Banner';
import { Box } from '../../components/common/Box';
import { Button } from '../../components/common/Button';
import { Flex } from '../../components/common/Flex';
import { Label, TextField } from '../../components/common/form';
import { Grid } from '../../components/common/Grid';
import { Heading } from '../../components/common/Heading';
import { Hide } from '../../components/common/Hide';
import { Text } from '../../components/common/Text';
import { Fade } from '../../components/Headless/Fade';
import { ScrollToTop } from '../../components/Headless/ScrollToTop';
import { SubscriptionPlanTierPrice } from '../../components/SubscriptionPlanTierPrice';
import { useCompany } from '../../context/CompanyContext';
import { useTheme } from '../../hooks/useTheme';
import logger from '../../utils/logger';
import {
  ExistingSubscriptionInformation,
  pricingPageMachine,
} from './machines/pricingPage.machine';
import { SubscriptionPageError } from './SubscriptionPageError';
import { SubscriptionPageHeader } from './SubscriptionPageHeader';
import { SubscriptionPageLoading } from './SubscriptionPageLoading';

interface PricingPageProps extends RouteComponentProps {
  existingSubscription: ExistingSubscriptionInformation;
  onTierSelected: (planTier: SubscriptionPlanTier, seats: number, discountCode?: string) => void;
}

export const PricingPage = (props: PricingPageProps) => {
  const { company } = useCompany();

  const [current, send] = useMachine(pricingPageMachine, {
    context: {
      existingSubscription: props.existingSubscription,
      selectedCurrency: company?.companySubscription.currency ?? DEFAULT_CURRENCY,

      billingPeriod: ApiSubscriptionPlanPeriodUnit.Year,
      customTierSeats: '50',
      discountCode: '',
    },
    devTools: process.env.NODE_ENV === 'development',
  });

  if (!company) {
    logger.logError(
      new Error('The user does not have a company yet. This should not be reachable')
    );

    return <Redirect path="/" from="/" to="/dashboard" />;
  }

  switch (true) {
    case current.matches({ plans: { fetchingPlans: 'initialFetch' } }):
      return <FetchingInitialData />;
    case current.matches({ plans: 'failed' }):
      return (
        <FetchingPlansFailed
          errorMessage={current.context.userFacingErrorMessage}
          onRetry={() => {
            send({ type: 'RETRY_PLAN_TIER_FETCH' });
          }}
        />
      );
    default:
      return (
        <ScrollToTop>
          <SubscriptionPageHeader
            title="Choose the best package for your business."
            subTitle="We have three standard plans available, either billed monthly or annually and a custom option if you need more users. Select the plan that is the best fit for your business and your preferred billing period."
          />

          <ExistingSubscriptionBanner existingSubscription={current.context.existingSubscription} />

          <Box sx={{ height: '3.75rem' }} />

          <Box
            sx={{ maxWidth: '1280px', margin: '0 auto', px: 5, pb: 8, letterSpacing: '-0.01rem' }}
          >
            <Flex sx={{ justifyContent: 'center' }}>
              <BillingPeriodToggle
                billingPeriod={current.context.billingPeriod}
                onToggle={() => {
                  send({ type: 'TOGGLE_BILLING_PERIOD' });
                }}
              />
            </Flex>

            <Box sx={{ height: '3.75rem' }} />

            <Tiers
              tiers={current.context.planTiers}
              existingSubscription={current.context.existingSubscription}
              customSeats={current.context.customTierSeats}
              customContactSales={current.matches({
                plans: { idle: { customSeats: 'contactSales' } },
              })}
              isFetchingPricing={current.matches({ plans: { fetchingPlans: 'subsequentFetch' } })}
              isFetchingCustomPricing={
                current.matches({ plans: { fetchingPlans: 'subsequentFetch' } }) ||
                current.matches({
                  plans: {
                    idle: { customSeats: { inRange: 'fetchingCustomSeatPricing' } },
                  },
                })
              }
              onChangeCustomSeats={(seats) => {
                send({ type: 'CHANGE_CUSTOM_SEATS', data: { seats } });
              }}
              onTierSelected={(planTier: SubscriptionPlanTier) => {
                const seats =
                  planTier.type === ApiSubscriptionPlanTierType.Fixed
                    ? planTier.seats
                    : parseInt(current.context.customTierSeats, 10);

                props.onTierSelected(
                  planTier,
                  seats,
                  current.matches({ discountCode: 'applied' })
                    ? current.context.discountCode
                    : undefined
                );
              }}
            />

            <Box sx={{ height: '3.75rem' }} />

            <DiscountCode
              isValid={current.matches({ discountCode: { idle: 'valid' } })}
              isDisabled={
                current.matches({ plans: 'idle' }) === false ||
                current.matches({ discountCode: 'idle' }) === false
              }
              isApplied={current.matches({ discountCode: 'applied' })}
              value={current.context.discountCode}
              onChange={(value) => {
                send({
                  type: 'UPDATE_DISCOUNT_CODE',
                  data: { discountCode: value },
                });
              }}
              onApply={() => {
                send({ type: 'APPLY_DISCOUNT_CODE' });
              }}
            />
          </Box>
        </ScrollToTop>
      );
  }
};

const FetchingInitialData = () => {
  return (
    <SubscriptionPageLoading title="Loading" subTitle="Loading the available subscription plans" />
  );
};

interface FetchingPlansFailedProps {
  errorMessage?: string;
  onRetry: () => void;
}

const FetchingPlansFailed = (props: FetchingPlansFailedProps) => {
  return (
    <SubscriptionPageError
      title="Oops. We encountered an error"
      subTitle="Something went wrong whilst fetching the subscription tiers"
      errorMessage={props.errorMessage ?? ''}
      renderAction={
        <Button variant="primary" onClick={props.onRetry}>
          Try again
        </Button>
      }
    />
  );
};

interface ExistingSubscriptionBannerProps {
  existingSubscription: ExistingSubscriptionInformation;
}

const ExistingSubscriptionBanner = (props: ExistingSubscriptionBannerProps) => {
  return (
    <Banner>
      <Flex sx={{ justifyContent: 'center', width: '100%' }}>
        <Text as="span" sx={{ color: 'whitefade80', fontWeight: 300 }}>
          <Text as="span" sx={{ mr: 2 }}>
            Your current plan:
          </Text>{' '}
          <Text as="span" sx={{ color: 'white', fontWeight: 600 }}>
            {props.existingSubscription.tierName}
          </Text>
          , up to {props.existingSubscription.seats} seat{' '}
          <FormattedPlural
            value={props.existingSubscription.seats}
            one="licence"
            other="licences"
          />
          , billed{' '}
          {props.existingSubscription.billingFrequency ===
          ApiCompanySubscriptionBillingFrequency.Monthly
            ? 'monthly'
            : 'annually'}
        </Text>
      </Flex>
    </Banner>
  );
};

interface BillingPeriodToggleProps {
  billingPeriod: ApiSubscriptionPlanPeriodUnit;
  onToggle: () => void;
}

const BillingPeriodToggle = (props: BillingPeriodToggleProps) => {
  return (
    <Flex sx={{ justifyContent: ['flex-start', 'center'], fontSize: 2 }}>
      <Flex sx={{ alignItems: 'center', p: 1, cursor: 'pointer' }} onClick={props.onToggle}>
        <Flex
          sx={{
            color:
              props.billingPeriod === ApiSubscriptionPlanPeriodUnit.Month ? 'accent' : 'grey.9',
            letterSpacing: '-0.015rem',
          }}
        >
          Monthly Billing
        </Flex>
        <Flex sx={{ mx: 2 }}>
          <BillingModeToggle on={props.billingPeriod === ApiSubscriptionPlanPeriodUnit.Year} />
        </Flex>
        <Flex
          sx={{
            color: props.billingPeriod === ApiSubscriptionPlanPeriodUnit.Year ? 'accent' : 'grey.9',
            letterSpacing: '-0.015rem',
          }}
        >
          Annual Billing
          <Hide sx={{ ml: 1 }} xs>
            (save 20%)
          </Hide>
        </Flex>
      </Flex>
    </Flex>
  );
};

interface BillingModeToggleProps {
  on: boolean;
}

const BillingModeToggle: React.FC<BillingModeToggleProps> = (props) => {
  const width = 26;

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'inline-block',
        width: '50px',
        height: `${width}px`,
      }}
    >
      <Box
        as="span"
        sx={{
          position: 'absolute',
          cursor: 'pointer',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'accent',
          transition: '250ms',
          borderRadius: `${width}px`,
          border: '2px solid #cccccc',
        }}
      >
        <Box
          as="span"
          sx={{
            position: 'absolute',
            height: '18px',
            width: '18px',
            left: `${props.on ? width - 2 : 2}px`,
            top: '2px',
            backgroundColor: 'white',
            borderRadius: '50%',
            transition: 'all cubic-bezier(0.18, 1.02, 0.18, 1.03) 250ms',
          }}
        />
      </Box>
    </Box>
  );
};

interface TiersProps {
  tiers: SubscriptionPlanTier[];
  existingSubscription: ExistingSubscriptionInformation;
  customSeats: string;
  customContactSales: boolean;
  isFetchingPricing: boolean;
  isFetchingCustomPricing: boolean;
  onChangeCustomSeats: (seats: string) => void;
  onTierSelected: (planTier: SubscriptionPlanTier) => void;
}

const Tiers = (props: TiersProps) => {
  return (
    <TierGrid>
      {props.tiers.map((tier) => {
        const isExistingPlanTier =
          props.existingSubscription.planId === tier.planId &&
          props.existingSubscription.tierId === tier.id;

        if (tier.type === ApiSubscriptionPlanTierType.Custom) {
          return (
            <Flex
              key="tier-custom"
              sx={{ justifyContent: 'center', height: '420px' }}
              data-testid={`tier-custom`}
            >
              <CustomTier
                tier={tier}
                isExistingPlanTier={isExistingPlanTier}
                isCurrentSeatsSameAsExisting={
                  props.existingSubscription.seats === parseInt(props.customSeats, 10)
                }
                isFetchingPrice={props.isFetchingCustomPricing}
                seatsNumber={props.customSeats}
                showContactSalesNotice={props.customContactSales}
                onChangeSeatNumber={props.onChangeCustomSeats}
                onSelect={() => {
                  props.onTierSelected(tier);
                }}
              />
            </Flex>
          );
        }

        return (
          <Flex
            key={`${tier.planId}_${tier.id}`}
            sx={{ justifyContent: 'center', height: '420px' }}
            data-testid={`tier-${tier.name.toLowerCase()}`}
          >
            <FixedTier
              tier={tier}
              isExistingPlanTier={isExistingPlanTier}
              isFetchingPrice={props.isFetchingPricing}
              onSelect={() => {
                props.onTierSelected(tier);
              }}
            />
          </Flex>
        );
      })}
    </TierGrid>
  );
};

const TierGrid = styled(Grid)`
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
    max-width: 650px;
    margin: 0 auto;
  }

  @media (max-width: 650px) {
    grid-template-columns: 1fr;
    margin: 0 auto;
  }
`;

interface FixedTierProps {
  tier: SubscriptionPlanTier;
  isExistingPlanTier: boolean;
  isFetchingPrice: boolean;
  onSelect: () => void;
}

const FixedTier: React.FC<FixedTierProps> = (props) => {
  const theme = useTheme();

  return (
    <TierContainer isExistingPlanTier={props.isExistingPlanTier}>
      <Flex sx={{ flexDirection: 'column', justifyContent: 'space-between', height: '100%', p: 5 }}>
        <Box>
          <TierTitle>{props.tier.name}</TierTitle>

          <SubscriptionPlanTierPrice
            amount={props.tier.monthlyPrice / 100}
            period="month"
            currencyCode={props.tier.currencyCode}
            numberTextSx={{
              color: props.isFetchingPrice ? theme.colors.grey[5] : undefined,
              transition: 'all 200ms ease-in-out',
            }}
          />

          <TierFeatures features={props.tier.features} />
        </Box>

        <Box>
          <TierSelectButton
            variant={props.isExistingPlanTier ? 'disabled' : 'primaryInverted'}
            disabled={props.isExistingPlanTier || props.isFetchingPrice}
            onClick={props.onSelect}
          >
            {props.isExistingPlanTier ? 'Your active plan' : 'Select this plan'}
          </TierSelectButton>
        </Box>
      </Flex>
    </TierContainer>
  );
};

interface CustomTierProps {
  tier: SubscriptionPlanTier;
  isExistingPlanTier: boolean;
  isCurrentSeatsSameAsExisting: boolean;
  isFetchingPrice: boolean;
  seatsNumber: string;
  showContactSalesNotice: boolean;
  onChangeSeatNumber: (seats: string) => void;
  onSelect: () => void;
}

const CustomTier: React.FC<CustomTierProps> = (props) => {
  const theme = useTheme();

  const isMatchingPlanTierAndSeta = props.isExistingPlanTier && props.isCurrentSeatsSameAsExisting;

  return (
    <TierContainer isExistingPlanTier={isMatchingPlanTierAndSeta}>
      <Flex sx={{ flexDirection: 'column', justifyContent: 'space-between', height: '100%', p: 5 }}>
        <Box>
          <TierTitle>{props.tier.name}</TierTitle>
          <Box sx={{ height: '0.25rem' }} />
          {props.showContactSalesNotice && (
            <React.Fragment>
              <Text sx={{ fontWeight: 'bold', fontSize: 4 }}>We want to talk to you!</Text>
              <Box sx={{ height: '0.25rem' }} />
            </React.Fragment>
          )}
          <TextField
            sx={{ width: '40%', textAlign: 'center', mr: 2, py: '0.6rem', bg: '#f6f6f6' }}
            type="number"
            value={props.seatsNumber}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              props.onChangeSeatNumber(e.currentTarget.value);
            }}
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
          />
          user licences
          <Box sx={{ mt: '0.75rem' }}>
            {!props.showContactSalesNotice ? (
              <SubscriptionPlanTierPrice
                amount={props.tier.monthlyPrice / 100}
                period="month"
                currencyCode={props.tier.currencyCode}
                numberTextSx={{
                  color: props.isFetchingPrice ? theme.colors.grey[5] : undefined,
                  transition: 'all 200ms ease-in-out',
                }}
              />
            ) : null}
          </Box>
          <TierFeatures features={props.tier.features} />
        </Box>

        <Flex>
          {props.showContactSalesNotice ? (
            <TierSelectButton
              as="a"
              href="mailto:support@adgrader.io"
              variant={isMatchingPlanTierAndSeta ? 'disabled' : 'primaryInverted'}
              onClick={() => {
                analytics.contact();
              }}
            >
              Contact sales
            </TierSelectButton>
          ) : (
            <TierSelectButton
              variant={isMatchingPlanTierAndSeta ? 'disabled' : 'primaryInverted'}
              disabled={isMatchingPlanTierAndSeta || props.isFetchingPrice}
              onClick={props.onSelect}
            >
              Select this plan
            </TierSelectButton>
          )}
        </Flex>
      </Flex>
    </TierContainer>
  );
};

interface TierContainerProps {
  isExistingPlanTier: boolean;
}

const TierContainer = styled(Box)<TierContainerProps>`
  width: 280px;
  height: 100%;
  background-color: ${(props) => (props.isExistingPlanTier ? '#e8e8e8' : props.theme.colors.white)};
  box-shadow: 0 0 0 1px hsla(0, 0%, 45.9%, 0.4), 0 2px 10px rgba(3, 18, 42, 0.15);
  border: 1px solid #ffffff;
  border-radius: 4px;
  transition: 0.2s ease-in-out;

  &:hover {
    background-color: ${(props) =>
      props.isExistingPlanTier ? '#e8e8e8' : 'rgba(50, 146, 207, 0.3)'};
    box-shadow: 0 0 0 1px hsla(0, 0%, 45.9%, 0.4), 0 2px 20px rgba(3, 18, 42, 0.45);
  }
`;

const TierTitle: React.FC = (props) => {
  return (
    <Heading
      sx={{
        mx: '0',
        mt: 0,
        mb: '0.75rem',
        fontSize: '2.2rem',
        fontWeight: 500,
        color: 'accent',
        letterSpacing: '-0.05rem',
      }}
    >
      {props.children}
    </Heading>
  );
};

interface TierFeaturesProps {
  features: string[];
}

const TierFeatures: React.FC<TierFeaturesProps> = React.memo((props) => {
  return (
    <Box as="ul" sx={{ m: 0, p: 0, listStyle: 'none' }}>
      {props.features.map((feature, index) => (
        <Box as="li" key={index} sx={{ pb: 1 }}>
          <Text
            as="span"
            sx={{
              fontSize: [2, null, null, null, null, 1],
              letterSpacing: '-0.0125rem',
              color: 'blackfade50',
            }}
          >
            - {feature}
          </Text>
        </Box>
      ))}
    </Box>
  );
});

const TierSelectButton = styled(Button)({
  padding: '1.25rem',
  fontSize: '1.1rem',
  fontWeight: 500,
  letterSpacing: '-0.02rem',
  width: '100%',
  textDecoration: 'none',
});

interface DiscountCodeProps {
  isValid: boolean;
  isDisabled: boolean;
  isApplied: boolean;
  value: string;
  onChange: (value: string) => void;
  onApply: () => void;
}

const DiscountCode = (props: DiscountCodeProps) => {
  const canApplyCode = props.isValid && !props.isDisabled;

  const [showDiscountAppliedMessage, setShowDiscountAppliedMessage] = useState(false);

  useEffect(() => {
    if (props.isApplied) {
      setShowDiscountAppliedMessage(true);
      setTimeout(() => {
        setShowDiscountAppliedMessage(false);
      }, 2000);
    }
  }, [props.isApplied]);

  return (
    <Flex sx={{ flexDirection: 'column', alignItems: 'center' }}>
      <Label htmlFor="discountCode" sx={{ fontSize: '0.8rem', fontWeight: 700, mb: 2 }}>
        Got a promo code?
      </Label>
      <Grid sx={{ gridTemplateColumns: ['1fr 1fr', '300px auto'], columnGap: '10px', mt: '3px' }}>
        <React.Fragment>
          <TextField
            name="discountCode"
            type="text"
            disabled={props.isDisabled}
            value={props.value}
            onChange={(e) => {
              props.onChange(e.currentTarget.value);
            }}
          />
        </React.Fragment>

        <Box>
          <Button
            type="button"
            variant={canApplyCode ? 'accent' : 'disabled'}
            disabled={!canApplyCode}
            onClick={props.onApply}
          >
            Apply
          </Button>
        </Box>
      </Grid>

      <Box sx={{ pt: 3 }}>
        <Fade active={showDiscountAppliedMessage}>Discount code accepted</Fade>
      </Box>
    </Flex>
  );
};
