import { RouteComponentProps, useNavigate } from '@reach/router';
import format from 'date-fns/format';
import * as React from 'react';
import { useMutation } from 'react-query';

import { analytics } from '../../analytics/analytics';
import { ApiCompany } from '../../api/auth';
import {
  ApiCompanyBillingInformation,
  ApiCompanySubscriptionBillingFrequency,
  companySubscriptionApi,
} from '../../api/company-subscription';
import {
  ApiSubscriptionPlanPeriodUnit,
  SubscriptionPlanTier,
} from '../../api/subscription-plan-pricing';
import { Box } from '../../components/common/Box';
import { Button } from '../../components/common/Button';
import { Flex } from '../../components/common/Flex';
import { Heading } from '../../components/common/Heading';
import {
  Modal,
  ModalActions,
  ModalContainer,
  ModalContent,
  ModalTitle,
} from '../../components/common/Modal';
import { Text } from '../../components/common/Text';
import { ScrollToTop } from '../../components/Headless/ScrollToTop';
import { PlanHeader } from '../../components/PlanHeader';
import { SubscriptionPlanTierPrice } from '../../components/SubscriptionPlanTierPrice';
import { useAuth } from '../../context/AuthContext';
import { useCompany } from '../../context/CompanyContext';
import { RuntimeError } from '../../Error/BaseErrors';
import countries from '../../utils/countries.json';
import { Divider } from './Divider';
import { ExistingSubscriptionInformation } from './machines/pricingPage.machine';
import { SubscriptionPageError } from './SubscriptionPageError';
import { SubscriptionPageHeader } from './SubscriptionPageHeader';

interface OrderConfirmationPageProps extends RouteComponentProps {
  existingSubscription: ExistingSubscriptionInformation;
  selectedPlanTier: SubscriptionPlanTier;
  seats: number;
  discountToken?: string;
}

export const OrderConfirmationPage = (props: OrderConfirmationPageProps) => {
  const navigate = useNavigate();
  const { fetchUser } = useAuth();
  const { company } = useCompany();

  if (!company) {
    throw new RuntimeError(
      'Something went wrong whilst showing the order summary',
      'The user does not have a company yet. This should not be reachable'
    );
  }

  const { companyBillingInformation } = company;

  if (!companyBillingInformation) {
    throw new RuntimeError(
      'Something went wrong whilst showing the order summary',
      'No company billing information was found. Is it being exposed?'
    );
  }

  const {
    isLoading: isConfirmingOrder,
    isSuccess: isOrderConfirmed,
    isError: isConfirmingOrderError,
    mutate: confirmOrder,
  } = useMutation<
    unknown,
    unknown,
    { companyId: number; planId: string; seats: number; discountToken?: string }
  >(
    (options) => {
      return companySubscriptionApi.updateSubscription({
        companyId: options.companyId,
        planId: options.planId,
        seats: options.seats,
        discountToken: options.discountToken,
      });
    },
    {
      onSuccess: async () => {
        analytics.subscriptionCreated(company.businessType, props.seats);
        await fetchUser();
      },
    }
  );

  if (isConfirmingOrderError) {
    return (
      <Failed
        goToPricingPage={() => {
          navigate('/subscriptions/change');
        }}
      />
    );
  }

  return (
    <ScrollToTop>
      <Box sx={{ letterSpacing: '-0.01rem' }}>
        <SubscriptionPageHeader title="Confirm you're happy." />

        <Box sx={{ height: '3.75rem' }} />

        <Flex sx={{ justifyContent: 'center', px: 5 }}>
          <Box sx={{ maxWidth: '1000px' }}>
            <SummaryPreface />

            <Divider />

            <PlanSummary
              company={company}
              existingSubscription={props.existingSubscription}
              selectedPlanTier={props.selectedPlanTier}
              selectedSeats={props.seats}
              goToPricingPage={() => {
                navigate('/subscriptions/change');
              }}
            />

            <Divider />

            <CardDetailsSummary lastFour={companyBillingInformation.lastFour ?? ''} />

            <Divider />

            <CompanyInformationSummary
              company={company}
              billingDetails={companyBillingInformation}
            />

            <Box sx={{ height: '4rem' }} />

            <Button
              variant={isConfirmingOrder ? 'disabled' : 'primaryInverted'}
              disabled={isConfirmingOrder}
              sx={{ fontWeight: 500, letterSpacing: '-0.01rem' }}
              onClick={() => {
                confirmOrder({
                  companyId: company.id,
                  planId: props.selectedPlanTier.planId,
                  seats: props.seats,
                  discountToken: props.discountToken,
                });
              }}
            >
              I'm happy to go ahead &rarr;
            </Button>

            <Box sx={{ height: '8rem' }} />
          </Box>
        </Flex>
      </Box>

      <OrderCompleteModal
        isOpen={isOrderConfirmed}
        nextStepText={
          props.seats < props.existingSubscription.seats || props.seats === 1
            ? 'Go to the dashboard'
            : 'Allocate new users'
        }
        onNextPressed={() => {
          if (props.seats < props.existingSubscription.seats || props.seats === 1) {
            navigate('/dashboard');
            return;
          }

          navigate('/settings/users');
        }}
      />
    </ScrollToTop>
  );
};

interface FailedProps {
  goToPricingPage: () => void;
}

const Failed = (props: FailedProps) => {
  return (
    <SubscriptionPageError
      title="Oops. We encountered an error"
      subTitle="Something went wrong whilst processing the order"
      errorMessage=""
      renderAction={
        <Button variant="primary" onClick={props.goToPricingPage}>
          Go back to pricing page
        </Button>
      }
    />
  );
};

const SummaryPreface: React.FC = () => {
  return (
    <React.Fragment>
      <Heading
        as="h3"
        sx={{
          mt: 0,
          mb: 5,
          fontSize: 6,
          color: 'accent',
          fontWeight: 500,
          letterSpacing: '-0.05rem',
        }}
      >
        Here's a summary of your order:
      </Heading>

      <Text sx={{ color: 'grey.9' }}>
        Make sure you're happy with everything below, and if you are, click on the green button at
        the bottom of the screen and we'll get your account launched.
      </Text>
    </React.Fragment>
  );
};

interface PlanSummaryProps {
  company: ApiCompany;
  existingSubscription: ExistingSubscriptionInformation;
  selectedPlanTier: SubscriptionPlanTier;
  selectedSeats: number;
  goToPricingPage: () => void;
}

const PlanSummary: React.FC<PlanSummaryProps> = (props) => {
  const isSelectedPlanTierBilledMonthly =
    props.selectedPlanTier.billingPeriod === ApiSubscriptionPlanPeriodUnit.Month;

  return (
    <Box>
      <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', mt: 0, mb: 3, pb: 0 }}>
        <PlanHeader
          title="Selected Plan:"
          plan={
            <Text as="span">
              <Text as="span">{props.selectedPlanTier.name}</Text>{' '}
              <Text as="span" sx={{ fontWeight: 400 }}>
                (billed {isSelectedPlanTierBilledMonthly ? 'monthly' : 'annually'})
              </Text>
            </Text>
          }
        />

        <Box>
          <Box onClick={props.goToPricingPage}>
            <Text
              sx={{
                color: 'primary',
                cursor: 'pointer',
                textDecoration: 'underline',
                fontWeight: 500,
                mb: 0,
              }}
            >
              Change Plan &rarr;
            </Text>
          </Box>
        </Box>
      </Flex>

      <Box>
        <Flex
          sx={{
            alignItems: 'center',
            backgroundColor: 'rgba(0,0,0,0.1)',
            fontSize: '1.5rem',
            padding: '0.5rem 1rem',
            borderRadius: '2px',
          }}
        >
          <Text
            sx={{
              color: 'accent',
              fontSize: 4,
              fontWeight: 700,
              mb: 0,
              mr: 2,
              lineHeight: '1.8rem',
              letterSpacing: '-0.03rem',
            }}
          >
            Total Cost:
          </Text>

          <Flex sx={{ margin: '0.5rem 0 0 0' }}>
            <React.Fragment>
              <SubscriptionPlanTierPrice
                amount={props.selectedPlanTier.price / 100}
                period={isSelectedPlanTierBilledMonthly ? 'month' : 'year'}
                currencyCode={props.selectedPlanTier.currencyCode}
                textSx={{ fontSize: '2.5rem' }}
              />
            </React.Fragment>
          </Flex>
        </Flex>

        <Text sx={{ mt: 5, color: 'grey.9' }}>
          <PaymentScheduleNotice
            existingSubscription={props.existingSubscription}
            selectedPlanTier={props.selectedPlanTier}
            selectedSeats={props.selectedSeats}
            isExistingSubscriptionATrial={props.company.subscriptionStatus === 'in_trial'}
            currentPeriodEnds={new Date(props.company.subscriptionCurrentPeriodEnds)}
          />
        </Text>
      </Box>
    </Box>
  );
};

interface PaymentScheduleNoticeProps {
  existingSubscription: ExistingSubscriptionInformation;
  selectedPlanTier: SubscriptionPlanTier;
  selectedSeats: number;
  isExistingSubscriptionATrial: boolean;
  currentPeriodEnds: Date;
}

const PaymentScheduleNotice = (props: PaymentScheduleNoticeProps) => {
  const isSelectedPlanTierBilledMonthly =
    props.selectedPlanTier.billingPeriod === ApiSubscriptionPlanPeriodUnit.Month;
  const isSelectedPlanTierBilledAnnually =
    props.selectedPlanTier.billingPeriod === ApiSubscriptionPlanPeriodUnit.Year;

  const isExistingSubscriptionBilledAnnually =
    props.existingSubscription.billingFrequency === ApiCompanySubscriptionBillingFrequency.Annually;

  if (props.isExistingSubscriptionATrial) {
    return (
      <React.Fragment>
        As you're currently in a trial, your first payment will not be taken until{' '}
        {format(props.currentPeriodEnds, 'do LLL')}.
        <Text sx={{ pt: 2 }}>
          {isSelectedPlanTierBilledMonthly ? (
            <React.Fragment>
              Once your trial has elapsed, your payments will be start being taken monthly on the{' '}
              {format(props.currentPeriodEnds, 'do')}. You can cancel at any time.
            </React.Fragment>
          ) : (
            <React.Fragment>
              Once your trial has elapsed, your payments will be start being taken annually on the{' '}
              {format(props.currentPeriodEnds, 'do LLL')}. You can cancel at any time.
            </React.Fragment>
          )}
        </Text>
      </React.Fragment>
    );
  }

  if (isExistingSubscriptionBilledAnnually && isSelectedPlanTierBilledMonthly) {
    return (
      <React.Fragment>
        As your downgrading from an annual plan to a monthly plan, you won't be charged until{' '}
        {format(props.currentPeriodEnds, 'do LLL yyyy')}. After this time you will be charged
        monthly on the {format(props.currentPeriodEnds, 'do')} of the month thereafter. You can
        cancel at any time.
      </React.Fragment>
    );
  } else if (
    isExistingSubscriptionBilledAnnually &&
    isSelectedPlanTierBilledAnnually &&
    props.existingSubscription.seats > props.selectedSeats
  ) {
    <React.Fragment>
      As your downgrading the amount of seats on your plan, you won't be charged until{' '}
      {format(props.currentPeriodEnds, 'do LLL yyyy')}. After this time you will be charged monthly
      on the {format(props.currentPeriodEnds, 'do LLL')} annually thereafter. You can cancel at any
      time.
    </React.Fragment>;
  }

  return isSelectedPlanTierBilledMonthly ? (
    <React.Fragment>
      Your first payment will be taken immediately, and on the {format(new Date(), 'do')} of the
      month thereafter. You can cancel at any time.
    </React.Fragment>
  ) : (
    <React.Fragment>
      Your first payment will be taken immediately, and on the {format(new Date(), 'do LLL')}{' '}
      annually thereafter. You can cancel at any time.
    </React.Fragment>
  );
};

interface CardDetailsSummaryProps {
  lastFour: string;
}

const CardDetailsSummary: React.FC<CardDetailsSummaryProps> = (props) => {
  return (
    <Box>
      <Heading
        sx={{
          mt: 0,
          pb: 3,
          color: 'accent',
          fontSize: 5,
          fontWeight: 500,
          letterSpacing: '-0.05rem',
        }}
      >
        Credit Card Details:
      </Heading>

      <Text sx={{ my: 0, color: 'grey.9' }}>
        <Text as="span" sx={{ mr: 12 }}>
          Card ending in {props.lastFour}.
        </Text>
      </Text>
    </Box>
  );
};

interface CompanyInformationSummaryProps {
  company: ApiCompany;
  billingDetails: ApiCompanyBillingInformation;
}

const CompanyInformationSummary: React.FC<CompanyInformationSummaryProps> = (props) => {
  const countryName = countries.find(
    (country) => country.code === props.billingDetails.address.country
  );

  return (
    <Box>
      <Heading
        sx={{
          mt: 0,
          pb: 3,
          color: 'accent',
          fontSize: 5,
          fontWeight: 500,
          letterSpacing: '-0.05rem',
        }}
      >
        Company Information:
      </Heading>

      <Text sx={{ my: 0, color: 'grey.9' }}>
        {props.company.companyName} <br />
        {props.billingDetails.address.lineOne} <br />
        {props.billingDetails.address.lineTwo && (
          <React.Fragment>
            {props.billingDetails.address.lineTwo}
            <br />
          </React.Fragment>
        )}
        {props.billingDetails.address.lineThree && (
          <React.Fragment>
            {props.billingDetails.address.lineThree}
            <br />
          </React.Fragment>
        )}
        {props.billingDetails.address.city} <br />
        {props.billingDetails.address.postalCode} <br />
        {countryName && countryName.name}
        <br />
        <br />
        <Text as="label" sx={{ fontWeight: 600 }}>
          Accounting Email:
        </Text>{' '}
        {props.billingDetails.accountingEmail} <br />
      </Text>
    </Box>
  );
};

interface OrderCompleteModalProps {
  isOpen: boolean;
  nextStepText: string;
  onNextPressed: () => void;
}

const OrderCompleteModal: React.FC<OrderCompleteModalProps> = (props) => {
  return (
    <Modal aria-label="Order complete confirmation" isOpen={props.isOpen}>
      <ModalContainer>
        <ModalTitle>That's it, you're done!</ModalTitle>
        <ModalContent>Your new plan has been confirmed and you're good to go.</ModalContent>

        <ModalActions>
          <Button variant="primary" onClick={props.onNextPressed}>
            {props.nextStepText} &rarr;
          </Button>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};
