import styled from '@emotion/styled/macro';
import { Link as RRLink } from '@reach/router';
import * as React from 'react';

import { Box } from '../../components/common/Box';
import { Text } from '../../components/common/Text';

const Link = styled(RRLink)`
  color: ${(props) => props.theme.colors.grey[9]};

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }
`;

interface AuthFormFooterProps {
  email: string;
}

export const AuthFormFooter: React.FC<AuthFormFooterProps> = ({ email }) => {
  return (
    <React.Fragment>
      <Box
        as="hr"
        sx={{
          backgroundColor: '#cccccc',
          marginBottom: '2rem',
        }}
      />

      <Text
        sx={{
          fontSize: 1,
          color: '#8c8c8c',
          mb: 1,
          lineHeight: '1.4rem',
          letterSpacing: '-0.01rem',
        }}
      >
        Need an AdGrader account?{' '}
        <Box as="span" sx={{ fontWeight: 'bold' }}>
          <Link to={`/register?email=${encodeURIComponent(email)}`}>Create your account</Link>
        </Box>{' '}
        now.
      </Text>

      <Text sx={{ mt: 3, fontSize: 1, color: '#8c8c8c', lineHeight: '1.4rem' }}>
        Copyright AdGrader.io. All rights reserved.
      </Text>
    </React.Fragment>
  );
};
