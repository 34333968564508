import * as React from 'react';

import { Box } from '../../../components/common/Box';
import { Flex } from '../../../components/common/Flex';
import { calculateIndicatorColour } from '../../../components/ContentEditor/AdGrader/ScorePanel';

interface ScoreIndicatorProps {
  score: number | null;
}

export const ScoreIndicator = (props: ScoreIndicatorProps) => {
  const colour = React.useMemo(() => {
    return calculateIndicatorColour(props.score);
  }, [props.score]);

  return (
    <Flex
      sx={{
        backgroundColor: colour,
        color: 'white',
        justifyContent: 'center',
        alignItems: 'center',
        fontWeight: 700,
        fontSize: 5,
        height: '60px',
        width: '80px',
        letterSpacing: ' -0.05rem',
      }}
    >
      {/* eslint-disable-next-line eqeqeq */}
      <Box>{props.score === null || props.score == -1 ? '--' : `${props.score}%`}</Box>
    </Flex>
  );
};
