import { Menu, MenuButton, MenuItem, MenuList } from '@reach/menu-button';
import { Link, Match, navigate } from '@reach/router';
import * as React from 'react';
import { animated, useTransition } from 'react-spring';
import { ThemeUIStyleObject } from 'theme-ui';

import { useWindowSize } from '../context/WindowSizeContext';
import { Box } from './common/Box';
import { Flex } from './common/Flex';
import { Heading } from './common/Heading';
import { Rotate } from './Headless/Rotate';
import { CaretDown } from './Icon';

const MOBILE_TAB_MAX_WIDTH = 750;

const AnimatedFlex = animated(Flex);

interface AppHeaderProps {
  title: React.ReactNode;
  renderRight?: React.ReactNode;
  sx?: ThemeUIStyleObject;
}

export const AppHeader: React.FC<AppHeaderProps> = (props) => {
  return (
    <Flex
      sx={{
        height: '10rem',
        px: ['2.25rem', null, null, null, '3.5rem'],
        py: 0,
        justifyContent: 'space-between',
        alignItems: 'center',
        background: (theme) => theme.backgrounds.header.background,
        backgroundSize: (theme) => theme.backgrounds.header.backgroundSize,
        // https://gitlab.com/adbuilder_angel/ad-builder-app/-/issues/611
        marginTop: '-1px',
      }}
    >
      <Heading as="h1" variant="pageHeader">
        {props.title}
      </Heading>

      <Box>{props.renderRight}</Box>
    </Flex>
  );
};

interface AppHeaderTabsProps {
  tabs?: {
    label: string | JSX.Element;
    path: string;
  }[];
}

export const AppHeaderTabs: React.FC<AppHeaderTabsProps> = (props) => {
  const { width } = useWindowSize();

  const flattenedChildren = React.Children.toArray(props.children).reduce<any[]>(
    (acc, child: any) => {
      if (Array.isArray(child)) {
        return acc.concat(child);
      }

      acc.push(child);
      return acc;
    },
    []
  );

  const Tabs = width <= MOBILE_TAB_MAX_WIDTH ? MobileAppHeaderTabs : DesktopAppHeaderTabs;

  return <Tabs>{flattenedChildren}</Tabs>;
};

const DesktopAppHeaderTabs: React.FC = (props) => {
  const transitions = useTransition(props.children, (child: any) => child.key, {
    initial: null,
    from: { opacity: 0, transform: 'translate3d(0,-20px,0)' },
    enter: { opacity: 1, transform: 'translate3d(0,0px,0)' },
    leave: { opacity: 0, transform: 'translate3d(0,-20px,0)' },
  });

  return (
    <StickyContainer>
      <Flex sx={{ justifyContent: 'space-evenly' }}>
        {transitions.map(
          ({ item, key, props }) =>
            item && (
              <AnimatedFlex key={key} style={props}>
                {item}
              </AnimatedFlex>
            )
        )}
      </Flex>
    </StickyContainer>
  );
};

const MobileAppHeaderTabs: React.FC = (props) => {
  const linkChildren = React.Children.toArray(props.children).filter((child: any) => {
    return Boolean(child?.props?.to);
  });

  const activeTab = linkChildren.map((child: any, index) => (
    <Match key={index} path={`${child.props.to}/*`}>
      {({ match }) => (match ? child?.props?.children : null)}
    </Match>
  ));

  return (
    <StickyContainer styles={{ alignItems: 'flex-end' }}>
      <Menu>
        {({ isOpen }: { isOpen: boolean }) => (
          <React.Fragment>
            <MenuButton sx={{ alignSelf: 'flex-end' }}>
              <AppHeaderTab
                to=""
                active
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {activeTab}{' '}
                <Box as="span" ml={2}>
                  <Rotate active={isOpen}>
                    <CaretDown />
                  </Rotate>
                </Box>
              </AppHeaderTab>
            </MenuButton>

            <MenuList>
              {linkChildren.map((child: any, index) => (
                <MenuItem key={index} onSelect={() => navigate(child?.props?.to)}>
                  {child?.props?.children}
                </MenuItem>
              ))}
            </MenuList>
          </React.Fragment>
        )}
      </Menu>
    </StickyContainer>
  );
};

interface StickyContainerProps {
  styles?: ThemeUIStyleObject;
}

const StickyContainer: React.FC<StickyContainerProps> = (props) => {
  return (
    <Flex
      sx={{
        position: 'sticky',
        top: 0,
        px: ['2.25rem', null, null, null, '3.5rem'],
        minHeight: '60px',
        justifyContent: ['center', 'flex-start'],
        background: (theme) => theme.backgrounds.header.background,
        backgroundSize: (theme) => theme.backgrounds.header.backgroundSize,
        boxShadow:
          '0 4px 5px -1px rgba(0, 0, 0, 0.1), 0 7px 10px 0 rgba(0, 0, 0, 0.07), 0 12px 18px 0 rgba(0, 0, 0, 0.06)',
        zIndex: 2,
        // https://gitlab.com/adbuilder_angel/ad-builder-app/-/issues/611
        marginTop: '-1px',
      }}
      {...props}
    >
      {props.children}
    </Flex>
  );
};

interface AppHeaderTabProps extends Record<string, any> {
  as?: React.ComponentType;
  to?: string;
  active?: boolean;
  sx?: ThemeUIStyleObject;
  style?: any;
}

export const AppHeaderTab: React.FC<AppHeaderTabProps> = ({
  as = Link,
  sx: outerPropsSx,
  active: outerPropsActive = false,
  ...outerProps
}) => {
  const StyledTab: React.FC<{
    tabSx?: ThemeUIStyleObject;
    afterSx?: ThemeUIStyleObject;
  }> = (props) => {
    return (
      <Flex
        as={as}
        sx={{
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'flex-end',
          minWidth: '150px',
          ml: 2,
          pb: '1.25rem',
          fontSize: 1,
          fontWeight: 'semibold',
          color: 'inactive',
          textAlign: 'center',
          textDecoration: 'none',
          '::after': {
            content: '""',
            position: 'absolute',
            display: 'block',
            bottom: 0,
            left: '50%',
            transform: 'translate(-50%, 0)',
            height: '6px',
            width: '80%',
            opacity: 0,
            bg: 'textDim',
            transition: 'all 250ms',
            ...props.afterSx,
          },
          '&:hover, &:focus': {
            '::after': {
              width: '100%',
              opacity: 1,
            },
          },
          '@media (max-width: 1024px)': {
            minWidth: '100px',
          },
          ...props.tabSx,
          ...outerPropsSx,
        }}
        {...outerProps}
      />
    );
  };

  if (typeof outerProps.to === 'undefined') {
    return <StyledTab />;
  }

  return (
    <Match path={`${outerProps.to}/*`}>
      {({ match }) => {
        const isMatched = outerPropsActive || Boolean(match);

        return (
          <StyledTab
            tabSx={
              isMatched
                ? {
                    color: 'white',
                  }
                : undefined
            }
            afterSx={
              isMatched
                ? {
                    width: '100%',
                    opacity: 1,
                    bg: 'primary',
                  }
                : undefined
            }
          />
        );
      }}
    </Match>
  );
};
