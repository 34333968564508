import { Link as RRLink, RouteComponentProps } from '@reach/router';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import { useState } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';

import { analytics } from '../../../analytics/analytics';
import { ApiCompany } from '../../../api/auth';
import { Box } from '../../../components/common/Box';
import { Button } from '../../../components/common/Button';
import { ExternalLink } from '../../../components/common/ExternalLink';
import { Heading } from '../../../components/common/Heading';
import { Link } from '../../../components/common/Link';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTitle,
} from '../../../components/common/Modal';
import { Text } from '../../../components/common/Text';
import { TrialAccountRestricted } from '../../../components/TrialAccountRestricted';
import { useCompany } from '../../../context/CompanyContext';
import { RuntimeError } from '../../../Error/BaseErrors';
import { useToggle } from '../../../hooks/useToggle';
import logger from '../../../utils/logger';

export const PauseSubscription: React.FC<RouteComponentProps> = () => {
  const { company } = useCompany();

  if (!company) {
    throw new RuntimeError(
      'Something went wrong whilst loading',
      "User doesn't have belong to a company yet so they shouldn't have access"
    );
  }

  return (
    <TrialAccountRestricted
      title="Pause Subscription"
      restrictedMessage={
        <React.Fragment>
          <Text sx={{ mb: 4, fontSize: 2 }}>Pausing is not enabled for trial accounts</Text>
          <Text sx={{ mb: 5, fontSize: 2 }}>
            Once you setup a paid subscription, you'll be able to access this area.
          </Text>
        </React.Fragment>
      }
      containerProps={{ px: 8 }}
    >
      {company.subscriptionStatus === 'pausing' ? (
        <SubscriptionPaused subscriptionEndDate={parseISO(company.subscriptionCurrentPeriodEnds)} />
      ) : (
        <PauseSubscriptionDetails company={company} />
      )}
    </TrialAccountRestricted>
  );
};

interface PauseSubscriptionDetailsProps {
  company: ApiCompany;
}

const PauseSubscriptionDetails = (props: PauseSubscriptionDetailsProps) => {
  const { pauseSubscription } = useCompany();

  const { on: showPauseModal, set: setPauseModalVisiblity } = useToggle();
  const [pausingSubscription, setPausingSubscription] = useState(false);

  return (
    <Box pr="4" mb="4">
      <Heading
        sx={{
          m: 0,
          pb: 5,
          letterSacing: '-0.05rem',
          color: 'accent',
          fontSize: '1.75rem',
          fontWeight: 500,
          letterSpacing: '-0.05rem',
        }}
      >
        Pause Your Subscription
      </Heading>

      <React.Fragment>
        <Text sx={{ mb: 4, fontSize: 2 }}>
          If you’re not recruiting right now, but you want to keep your AdGrader account live and
          not lose your previous adverts, then you can pause your subscription.
        </Text>

        <Text mb="4">It’s really easy to do.</Text>

        <Text mb="4">
          Just click on the button below and your account will be indefinitely paused from the end
          of your current billing cycle.
        </Text>

        <Text mb="4">Your card will be charged £10 per month to keep your account active.</Text>

        <Text mb="4">
          And when you're ready, just log back into AdGrader and reactivate your account by
          selecting which package you want to move forward with.
        </Text>

        <Text mb="4">It couldn't be easier.</Text>

        <Text mb="6">
          If you’ve got any questions about how this works, email us at{' '}
          <ExternalLink
            href="mailto:support@adgrader.io"
            target="_top"
            sx={{ color: 'primary', cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => {
              analytics.contact();
            }}
          >
            support@adgrader.io
          </ExternalLink>
        </Text>

        <React.Fragment>
          <Button
            variant="primary"
            sx={{ fontWeight: 500, fontSize: '2', letterSpacing: '-0.02rem', mr: 4 }}
            onClick={() => setPauseModalVisiblity(true)}
          >
            Pause my subscription &rarr;
          </Button>

          <Modal
            aria-label="Pause subscription confirmation"
            isOpen={showPauseModal}
            data-testid="pausing-subscription-modal"
            onDismiss={() => setPauseModalVisiblity(false)}
          >
            <ModalClose onClick={() => setPauseModalVisiblity(false)} />
            <ModalContainer>
              <ModalTitle>Are you sure you want to pause your subscription?</ModalTitle>
              <ModalContent>
                You will still be able to access you adverts until{' '}
                {format(parseISO(props.company.subscriptionCurrentPeriodEnds), 'do LLL yyyy')}, but
                after that date you won't until you reactive your account.
              </ModalContent>
              <ModalActions
                sx={{
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                }}
              >
                <ModalButton
                  disabled={pausingSubscription}
                  mb="6"
                  onClick={async () => {
                    setPausingSubscription(true);
                    try {
                      await pauseSubscription();
                    } catch (e) {
                      logger.logError(e);
                      toast.error('Something went wrong whilst pausing the subscription');
                    } finally {
                      setPausingSubscription(false);
                    }
                  }}
                >
                  I'm sure, pause my subscription &rarr;
                </ModalButton>

                <Link to="/subscriptions/change" sx={{ color: 'text', fontSize: 1 }}>
                  Change my current plan &rarr;
                </Link>
              </ModalActions>
            </ModalContainer>
          </Modal>
        </React.Fragment>
        <Button
          as={RRLink}
          // @ts-ignore
          to="/subscriptions/change"
          variant="accent"
          sx={{ textDecoration: 'none', fontWeight: 500, fontSize: '2', letterSpacing: '-0.02rem' }}
        >
          Change my current plan &rarr;
        </Button>
      </React.Fragment>
    </Box>
  );
};

interface SubscriptionPausedProps {
  subscriptionEndDate: Date;
}

const SubscriptionPaused: React.FC<SubscriptionPausedProps> = ({ subscriptionEndDate }) => (
  <Box pr="4" mb="4">
    <Heading
      sx={{
        m: 0,
        pb: 5,
        letterSacing: '-0.05rem',
        color: 'accent',
        fontSize: '1.75rem',
        fontWeight: 500,
        letterSpacing: '-0.05rem',
      }}
    >
      Pause Your Subscription
    </Heading>

    <Text sx={{ mb: 4, fontSize: 2 }}>That's it, you're done.</Text>

    <Text sx={{ mb: 4, fontSize: 2 }}>
      You'll still be able to access AdGrader until the end of this current billing cycle (which is{' '}
      {format(subscriptionEndDate, 'do LLL yyyy')}). After that date your account will be in
      lockdown, with all of your adverts safe and secure.
    </Text>
    <Text sx={{ mb: 4, fontSize: 2 }}>
      To reactive your account, just log back in as normal, and follow the on-screen prompts.
    </Text>

    <Text sx={{ mb: 4, fontSize: 2 }}>
      If you've changed you mind and you'd rather upgrade or downgrade the current plan you're on,
      click the button below.
    </Text>

    <Button
      as={RRLink}
      // @ts-ignore
      to="/subscriptions/change"
      variant="accent"
      sx={{ textDecoration: 'none', fontWeight: 500, fontSize: '2', letterSpacing: '-0.02rem' }}
    >
      Change my current plan &rarr;
    </Button>
  </Box>
);
