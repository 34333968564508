import { RouteComponentProps } from '@reach/router';
import { useMachine } from '@xstate/react';
import { useMemo } from 'react';
import * as React from 'react';

import { advertApi } from '../../../api/advert';
import { advertTagsApi } from '../../../api/advert-tags';
import { BusinessType } from '../../../api/auth';
import { companyApi } from '../../../api/company';
import { useCompany } from '../../../context/CompanyContext';
import { RuntimeError } from '../../../Error/BaseErrors';
import {
  buildActionsColumn,
  buildCreatedAtColumn,
  buildJobTitleColumn,
  buildScoreGenderToneColumn,
  buildScoreInclusivityColumn,
  buildScoreReadabilityColumn,
  buildTagsColumn,
} from '../AdvertTableColumnDefinitions';
import {
  AdvertTableMachineProvider,
  useAdvertTableMachineState,
} from '../AdvertTableMachineContext';
import { useDashboardActions } from '../DashboardContext';
import { DashboardTable } from '../DashboardTable';
import {
  EmptyListContainer,
  EmptyListFiltersActiveView,
  EmptyListImage,
  EmptyListTitle,
} from '../EmptyList';
import { buildAdvertTableMachine } from '../machines/advertTableMachine';

interface SharedAdvertsProps extends RouteComponentProps {}

export const SharedAdverts: React.FC<SharedAdvertsProps> = () => {
  const { company } = useCompany();

  if (!company) {
    throw new RuntimeError(
      'Something went wrong whilst loading the advert',
      "User doesn't have belong to a company yet so they shouldn't have access"
    );
  }

  const { id: companyId, businessType: companyBusinessType } = company;
  const { incrementCount, decrementCount } = useDashboardActions();

  const machine = useMemo(() => {
    const config = {
      id: 'shared-adverts',
      fetchAdverts: advertApi.fetchSharedAdverts,
      fetchCompanyMembers: async () => companyApi.fetchCompanyMembers(companyId),
      fetchAdvertTags: async () => advertTagsApi.fetchTags(),
      incrementDashboardCounter: incrementCount,
      decrementDashboardCounter: decrementCount,
    };

    return buildAdvertTableMachine(config);
  }, [companyId, incrementCount, decrementCount]);

  const columns = useMemo(() => buildColumns(companyBusinessType), [companyBusinessType]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [state, _send, service] = useMachine(machine, {
    context: {
      columns,
    },
  });

  return (
    <div>
      <AdvertTableMachineProvider service={service}>
        <DashboardTable service={service} ListEmptyComponent={EmptyListView} />

      </AdvertTableMachineProvider>
    </div>
  );
};

const EmptyListView = React.memo(() => {
  const { hasActiveFilters } = useAdvertTableMachineState();

  if (hasActiveFilters) {
    return <EmptyListFiltersActiveView />;
  }

  return (
    <EmptyListContainer>
      <EmptyListTitle>No one has shared an advert with you yet.</EmptyListTitle>
      <EmptyListImage />
    </EmptyListContainer>
  );
});

const buildColumns = (businessType: BusinessType) => {
  return [
    //buildFavouriteOrEditActionColumn({ width: '3%' }),
    buildJobTitleColumn({ width: '28%' }),
    buildScoreInclusivityColumn({ width: '7%' }),
    buildScoreReadabilityColumn({ width: '7%' }),
    buildScoreGenderToneColumn({ width: '11%' }),
    buildTagsColumn({ width: '25%' }),
    buildCreatedAtColumn({ width: '15%' }),
    buildActionsColumn({ width: '7%' }),
  ];
};
