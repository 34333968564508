import { Redirect, RouteComponentProps, useNavigate } from '@reach/router';
import { ErrorMessage, FieldArray, Form, Formik } from 'formik';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';

import { invitationApi } from '../../api/invitation';
import { Box } from '../../components/common/Box';
import { Button } from '../../components/common/Button';
import { Flex } from '../../components/common/Flex';
import { ErrorMessage as ThemedErrorMessage, TextField } from '../../components/common/form';
import { Grid } from '../../components/common/Grid';
import { Image } from '../../components/common/Image';
import { Link } from '../../components/common/Link';
import { Text } from '../../components/common/Text';
import { RegistrationWizard } from '../../components/Registration/RegistrationWizardContainer';
import { RegistrationFormLabel } from '../../components/RegistrationFormLabel';
import { REGISTRATION_WIZARD_STEPS } from '../../config/registration';
import { useCompany } from '../../context/CompanyContext';
import { useTheme } from '../../hooks/useTheme';
import { ApiFormError } from '../../utils/errors/ApiFormError';
import { EmailSchema, FirstNameSchema } from '../../utils/form-validation/user-details';
import logger from '../../utils/logger';

export const SetupInviteTeamMembers: React.FC<RouteComponentProps> = () => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Helmet title="Company information" />

      <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
        <Box p={4}>
          <a href="https://adgrader.io">
            <Image sx={{ width: '12rem' }} src={theme.assets.logo.dark} alt="AdGrader logo" />
          </a>
        </Box>
      </Box>

      <RegistrationWizard.Container>
        <RegistrationWizard.FreeTrialHeader />
        <RegistrationWizard.StepIndicator activeStepIndex={2} steps={REGISTRATION_WIZARD_STEPS} />
        <RegistrationWizard.Content>
          <InviteTeamMembersForm />
        </RegistrationWizard.Content>
      </RegistrationWizard.Container>
    </React.Fragment>
  );
};

const InviteTeamFormSchema = Yup.object().shape({
  team: Yup.array().of(
    Yup.object()
      .shape({
        firstName: FirstNameSchema.optional(),
        email: EmailSchema.optional(),
      })
      .test('both_fields_required', 'Please fill in both fields', (value) => {
        const firstNamePresent = value.firstName ? value.firstName.trim().length > 1 : false;
        const emailPresent = value.email ? value.email.trim().length > 1 : false;

        return (firstNamePresent && emailPresent) || (!firstNamePresent && !emailPresent);
      })
  ),
});

const InviteTeamMembersForm = () => {
  const { company } = useCompany();
  const navigate = useNavigate();

  if (!company) {
    logger.logError(
      new Error('The user does not have a company yet. This should not be reachable')
    );

    return <Redirect path="/" from="/" to="/dashboard" />;
  }

  const initialTeam = [
    { firstName: '', email: '' },
    { firstName: '', email: '' },
    { firstName: '', email: '' },
    { firstName: '', email: '' },
  ];

  return (
    <Box sx={{ p: 6 }}>
      <Text sx={{ textAlign: 'center', pb: 3, fontSize: 3 }}>
        You can add up to four more users from your business on your trial.
      </Text>
      <Flex sx={{ pb: 5, justifyContent: 'center' }}>
        <Text sx={{ textAlign: 'center', maxWidth: '475px', fontSize: 3 }}>
          Just add their details below and we'll email them an invite to join. If there's nobody you
          want to add, just click the green button.
        </Text>
      </Flex>

      <Formik
        initialValues={{ team: initialTeam }}
        validationSchema={InviteTeamFormSchema}
        validateOnBlur={false}
        onSubmit={async (values, { setErrors }) => {
          try {
            const invites = values.team.filter((invite) => {
              return invite.email.trim().length > 0;
            });

            const uniqueInvites = invites.filter((value, index, self) => {
              const matchingIndex = self.findIndex((invite) => invite.email === value.email);

              return matchingIndex === index;
            });

            await invitationApi.batchInvite({
              companyId: company.id,
              invites: uniqueInvites,
            });

            navigate('/dashboard');
          } catch (e) {
            if (e instanceof ApiFormError) {
              setErrors(e.errors);
            } else {
              navigate('/dashboard');

              logger.logError(e);
            }
          }
        }}
      >
        {({ values, errors, isSubmitting, handleChange, handleBlur }) => {
          return (
            <Form>
              <FieldArray name="team">
                {() => {
                  return (
                    <Box sx={{ pb: 8, px: 5 }}>
                      {values.team.map((member, index) => {
                        return (
                          <Box key={index}>
                            <Grid
                              sx={{
                                gridTemplateColumns: '70px 225px auto',
                                alignItems: 'center',
                                pb: 2,
                              }}
                            >
                              <Text
                                as="p"
                                sx={{ color: 'accent', fontWeight: 500, mt: 2, fontSize: 3 }}
                              >
                                User #{index + 1}:
                              </Text>
                              <Box sx={{ pr: 3 }}>
                                <RegistrationFormLabel>First name:</RegistrationFormLabel>
                                <TextField
                                  type="text"
                                  name={`team[${index}].firstName`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={member.firstName}
                                />
                                <ErrorMessage name={`team[${index}].firstName`}>
                                  {(msg) => <ThemedErrorMessage>{msg}</ThemedErrorMessage>}
                                </ErrorMessage>
                              </Box>

                              <Box>
                                <RegistrationFormLabel>Email address:</RegistrationFormLabel>
                                <TextField
                                  type="email"
                                  name={`team[${index}].email`}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={member.email}
                                />
                                <ErrorMessage name={`team[${index}].email`}>
                                  {(msg) => <ThemedErrorMessage>{msg}</ThemedErrorMessage>}
                                </ErrorMessage>
                              </Box>
                            </Grid>

                            <Box sx={{ pl: '70px', pb: 3 }}>
                              <ErrorMessage name={`team[${index}]`}>
                                {(msg) =>
                                  typeof msg === 'string' ? (
                                    <ThemedErrorMessage>{msg}</ThemedErrorMessage>
                                  ) : null
                                }
                              </ErrorMessage>
                            </Box>
                          </Box>
                        );
                      })}
                    </Box>
                  );
                }}
              </FieldArray>

              <Flex sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
                <Button
                  type="submit"
                  variant="primaryInverted"
                  disabled={isSubmitting}
                  sx={{
                    fontWeight: 500,
                    letterSpacing: '-0.01rem',
                    fontSize: '1.1rem',
                  }}
                >
                  Start my trial &rarr;
                </Button>

                <Box>
                  <Text sx={{ fontWeight: 500 }}>
                    <Link to="/dashboard" sx={{ color: 'accent' }}>
                      Skip &rarr;
                    </Link>
                  </Text>
                </Box>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
