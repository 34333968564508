import { ApiCompany, ApiUser, AuthorityLevel } from './auth';
import { client } from './client';

export interface ApiInvite {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  sentAt: Date;
  expiresAt: Date;
  createdBy: ApiUser;
  company: ApiCompany;
}

export interface InviteData {
  email: string;
  firstName: string;
  lastName: string;
  authorityLevel: AuthorityLevel;
}

const fetchInvite = async (email: string, token: string): Promise<{ invite: ApiInvite }> => {
  const response = await client.get(`/invite/${email}/${token}`);

  return {
    invite: response.data.invite,
  };
};

const sendInvite = async (
  companyId: number,
  inviteData: InviteData
): Promise<{ invite: ApiInvite }> => {
  const response = await client.post(`/company/${companyId}/invites`, inviteData);

  return {
    invite: response.data.invite,
  };
};

interface BatchInviteRequestParams {
  companyId: number;
  invites: {
    email: string;
    firstName: string;
  }[];
}

const batchInvite = async (params: BatchInviteRequestParams): Promise<{ invites: ApiInvite[] }> => {
  const response = await client.post(`/company/${params.companyId}/invites/batch`, {
    invites: params.invites,
  });

  return {
    invites: response.data.invites,
  };
};

export const invitationApi = {
  fetchInvite,
  sendInvite,
  batchInvite,
};
