import * as React from 'react';

import { Box } from '../common/Box';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTextButton,
  ModalTitle,
} from '../common/Modal';

interface DowngradeUsersModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  users: number;
}

export const DowngradeUsersModal: React.FC<DowngradeUsersModalProps> = ({ isOpen, onClose, onSubmit, users }) => {
  return (
    <Modal aria-label='Too many users to downgrade' isOpen={isOpen} onDismiss={() => onClose()}>
      <ModalClose onClick={() => onClose()} />

      <ModalContainer>
        <ModalTitle style={{ paddingBottom: 0 }}>Oops, looks like you've got too many users to downgrade.</ModalTitle>

        <ModalContent>
          <Box sx={{ mt: 6 }}>
            Oops, looks like you've got too many users to downgrade to this account.
          </Box>
          <Box sx={{ mt: 4 }}>
            Please remove {users} users and then try again.
          </Box>
        </ModalContent>

        <ModalActions>
          <ModalButton type='button' onClick={() => onSubmit()}>Manage Users</ModalButton>
          <ModalTextButton type='button' onClick={() => onClose()}>
            Cancel
          </ModalTextButton>
        </ModalActions>
      </ModalContainer>

    </Modal>
  );
};
