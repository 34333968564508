import * as React from 'react';

import { GradedWordAverage, GradedWordFrequency } from '../../../api/company-analytics';
import { Flex } from '../../../components/common/Flex';
import { Grid } from '../../../components/common/Grid';
import { Text } from '../../../components/common/Text';

interface GradedWordsTableProps {
  average?: GradedWordAverage;
  frequency?: GradedWordFrequency;
  color: string;
  textAvg: string;
  textMostCommon: string;
}

export const GradedWordsTable: React.FC<GradedWordsTableProps> = ({
  average,
  frequency,
  color,
  textAvg,
  textMostCommon,
}) => {
  const freqMapped = frequency
    ? Object.keys(frequency)
        .sort((a, b) => frequency[b] - frequency[a])
        .map((word) => ({ word, freq: frequency[word] }))
        .slice(0, 5)
    : [];

  return (
    <Flex sx={{ flexDirection: 'column', flex: 1 }}>
      <Grid sx={{ gridTemplateColumns: '36px 1fr', columnGap: 3 }}>
        <Flex
          sx={{
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 99,
            width: 36,
            height: 36,
            background: color,
            opacity: 0.5,
          }}
        >
          <svg width={18} viewBox="0 0 65 110" xmlns="http://www.w3.org/2000/svg">
            <path
              stroke="#fff"
              strokeWidth={12}
              d="M0 30.02h65M0 54.1h26.48M0 78.17h65M38.52 54.1H65"
            />
          </svg>
        </Flex>

        <Text sx={{ fontSize: 6, fontWeight: 600, pt: 1 }}>{average}</Text>
        <div></div>
        <Text sx={{ opacity: 0.5 }}>Avg. # {textAvg} words</Text>
      </Grid>

      <Text sx={{ fontSize: 3, fontWeight: 600, pt: 3 }}>Most common {textMostCommon} used</Text>

      <ol style={{ paddingLeft: '1em', width: 'min(100%, 280px)', margin: '1rem auto' }}>
        {freqMapped.map(({ word, freq }) => (
          <li key={word} style={{ paddingLeft: '1em' }}>
            <Grid
              sx={{
                gridTemplateColumns: 'auto 48px',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              <Text
                title={word}
                sx={{
                  fontSize: 4,
                  fontWeight: 'semibold',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {word}
              </Text>

              <Text sx={{ fontSize: 4, fontWeight: 'semibold', textAlign: 'right', color }}>
                x{freq}
              </Text>
            </Grid>
          </li>
        ))}
      </ol>
    </Flex>
  );
};
