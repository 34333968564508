import { ErrorMessage, Form, Formik, FormikConfig, FormikProps } from 'formik';
import * as React from 'react';

import { Question as IQuestion, QuestionType } from '../../../api/form';
import { Box } from '../../../components/common/Box';
import { ErrorMessage as ThemedErrorMessage } from '../../../components/common/form';
import { ScrollToTop } from '../../../components/Headless/ScrollToTop';
import { AdvertFormQuestionsContainer } from './AdvertForm';
import { BooleanQuestion } from './BooleanQuestion';
import { CollectionQuestion } from './CollectionQuestion';
import { ComboboxTextareaQuestion } from './ComboboxTextareaQuestion';
import { FormAwareAutoScrollToError } from './FormAwareAutoScrollToError';
import { FormAwareInvalidFormBanner } from './FormAwareInvalidFormBanner';
import { Question } from './Question';
import { SelectQuestion } from './SelectQuestion';
import { TextQuestion } from './TextQuestion';

const QuestionTypeNotImplemented = () => (
  <Box>This question type component has not been implemented yet</Box>
);

const questionTypeToComponentMap = {
  [QuestionType.ComboboxTextarea]: ComboboxTextareaQuestion,
  [QuestionType.Collection]: CollectionQuestion,
  [QuestionType.Boolean]: BooleanQuestion,
  [QuestionType.Selection]: QuestionTypeNotImplemented,
  [QuestionType.Text]: TextQuestion,
  [QuestionType.Combobox]: QuestionTypeNotImplemented,
  [QuestionType.MultiSelect]: QuestionTypeNotImplemented,
  [QuestionType.Remuneration]: QuestionTypeNotImplemented,
  [QuestionType.Select]: SelectQuestion,
};

interface FormGeneratorProps<Values> {
  formikProps: FormikConfig<Values>;
  onFormSubmit?: (e: React.FormEvent<HTMLFormElement>, formikProps: FormikProps<any>) => void;
  questions: IQuestion[];
  // Provide a point at the end of the form where the consumer can add any extra modals or submit buttons
  extras?: (formikProps: FormikProps<any>) => React.ReactNode;
  questionsContainerProps?: any;
}

export const FormGenerator: React.FC<FormGeneratorProps<any>> = (props) => {
  return (
    <Formik {...props.formikProps}>
      {(formikProps) => {
        return (
          <Box
            as={Form}
            autoComplete="off"
            sx={{ fontSize: '1.125rem' }}
            // @ts-ignore
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              if (props.onFormSubmit) {
                props.onFormSubmit(e, formikProps);
              }
            }}
          >
            <FormAwareInvalidFormBanner />
            <FormAwareAutoScrollToError />

            <AdvertFormQuestionsContainer {...props.questionsContainerProps}>
              <ScrollToTop>
                {/* Hack to turn off auto complete */}
                <input autoComplete="off" name="hidden" type="text" style={{ display: 'none' }} />

                {props.questions.map((question, index) => {
                  const QuestionTypeComponent = questionTypeToComponentMap[question.type];
                  const id = question.id.toString();

                  return (
                    <Box key={question.id} sx={{ pt: 8, pb: 9 }}>
                      <Question name={id} label={question.label}>
                        <QuestionTypeComponent name={id} question={question} onChange={() => {}}/>
                        <ErrorMessage name={id} component={ThemedErrorMessage} />
                      </Question>
                    </Box>
                  );
                })}
              </ScrollToTop>
            </AdvertFormQuestionsContainer>

            {props.extras ? props.extras(formikProps) : null}
          </Box>
        );
      }}
    </Formik>
  );
};
