import {navigate} from '@reach/router';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import {useCallback, useContext, useEffect, useState} from 'react';
import * as React from 'react';

import {analytics} from '../analytics/analytics';
import {
    ApiUser,
    authApi,
    AuthCredentials,
    InviteRegistrationInformation,
    RegistrationInformation,
    ResetPasswordInformation,
} from '../api/auth';
import {client} from '../api/client';
import {attachApiRefreshAuthInterceptor} from '../api/middleware/api-refresh-auth-interceptor';
import {FullPageSpinner} from '../components/FullPageSpinner';
import {useInterval} from '../hooks/useInterval';
import {AffiliateUtils} from '../utils/affiliate';
import {AuthUtils} from '../utils/auth/auth-utils';
import logger from '../utils/logger';

const AuthContext = React.createContext({
    user: null as ApiUser | null,
    isNewUser: false,
    login: async (credentials: AuthCredentials) => {
        // no-op
    },
    register: async (registrationInformation: RegistrationInformation) => {
        // no-op
    },
    registerFromInvite: async (inviteInfo: InviteRegistrationInformation) => {
        // no-op
    },
    logout: (redirectUrl?: string) => {
        // no-op
    },
    forgotPassword: async (email: string) => ({passwordResetEmailSent: false}),
    resetPassword: async (info: ResetPasswordInformation) => ({passwordReset: false}),
    refreshUser: async () => {
        // no-op
    },
    fetchUser: async () => {
        // no-op
    },
});

interface AuthProviderProps {
}

export const AuthProvider: React.FC<AuthProviderProps> = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState<ApiUser | null>(null);
    const [isNewUser, setIsNewUser] = useState(false);

    const login = async (credentials: AuthCredentials) => {
        const response = await authApi.login(credentials);

        AuthUtils.setTokens(response.apiKey, response.refreshToken);
        AuthUtils.setSharedLoginCookie();

        setIsNewUser(false);
        setUser(response.user);
    };

    const logout = useCallback((redirectUrl?: string) => {
        AuthUtils.clearTokens();
        AuthUtils.setSwitchUserHeaderValue(undefined);
        AuthUtils.clearSharedLoginCookie();
        setIsNewUser(false);
        setUser(null);

        if (window.zE) {
            window.zE('webWidget', 'logout');
        }

        navigate(redirectUrl ?? '/login');
    }, []);

    const register = async (registrationInformation: RegistrationInformation) => {
        const response = await authApi.register(registrationInformation);

        AuthUtils.setTokens(response.apiKey, response.refreshToken);
        AuthUtils.setSharedLoginCookie();

        AffiliateUtils.recordLead(response.user.email);

        setIsNewUser(true);
        setUser(response.user);
    };

    const registerFromInvite = async (inviteInfo: InviteRegistrationInformation) => {
        const response = await authApi.registerFromInvite(inviteInfo);

        AuthUtils.setTokens(response.apiKey, response.refreshToken);
        AuthUtils.setSharedLoginCookie();

        setIsNewUser(true);
        setUser(response.user);

        analytics.invitationRegistrationComplete(response.user.company.businessType);
    };

    const forgotPassword = async (email: string) => {
        const response = await authApi.forgotPassword(email);

        return response;
    };

    const resetPassword = async (info: ResetPasswordInformation) => {
        const response = await authApi.resetPassword(info);
        return response;
    };

    const fetchUser = useCallback(async () => {
        try {
            const apiKey = AuthUtils.getApiKey();

            if (!apiKey) {
                setUser(null);
                return;
            }

            AuthUtils.setApiKey(apiKey);

            const result = await authApi.fetchUser();
            setUser(result.user);
        } catch (e) {
            logger.logError(e);
            setUser(null);
        }
    }, [setUser]);

    const refreshUser = useCallback(async () => {
        setIsLoading(true);
        await fetchUser();
        setIsLoading(false);
    }, [fetchUser]);

    useEffect(() => {
        attachApiRefreshAuthInterceptor(client, async () => {
            const apiKey = await AuthUtils.refreshApiKey();

            if (!apiKey) {
                logout();
                return null;
            }

            return apiKey;
        });
    }, [logout]);

    useEffect(() => {
        refreshUser();
    }, [refreshUser]);

    usePeriodicApiKeyRefresh();

    if (isLoading) {
        return <FullPageSpinner/>;
    }

    return (
        <AuthContext.Provider
            value={{
                user,
                isNewUser,
                login,
                logout,
                register,
                registerFromInvite,
                forgotPassword,
                resetPassword,
                refreshUser,
                fetchUser,
            }}
            {...props}
        />
    );
};

export const AuthConsumer = AuthContext.Consumer;

export const useAuth = () => useContext(AuthContext);

const usePeriodicApiKeyRefresh = () => {
    // 5 minutes
    const EARLY_EXPIRE_MAX_THRESHOLD = 300;
    /*
        30 seconds

        If the token expires within the next 30 seconds than ignore
        as we don't want to cross over with the expired token middleware
      */
    const EARLY_EXPIRE_MIN_THRESHOLD = 30;

    let isRefreshing = false;

    useInterval(async () => {
        //console.debug('usePeriodicApiKeyRefresh: Checking');

        if (isRefreshing) {
            //console.debug('usePeriodicApiKeyRefresh: Already refreshing');
            return;
        }

        const apiKey = AuthUtils.getApiKey();

        if (!apiKey) {
            console.debug('usePeriodicApiKeyRefresh: No API key found');
            return false;
        }

        const {exp} = AuthUtils.decodeApiToken(apiKey);
        // Multiply by 1000 to bring PHP timestamp inline with JS timestamp
        const expiresAt = exp * 1000;
        const expiresIn = differenceInSeconds(expiresAt, new Date());

        const isTokenExpiringSoon =
            expiresIn > EARLY_EXPIRE_MIN_THRESHOLD && expiresIn <= EARLY_EXPIRE_MAX_THRESHOLD;

        //console.debug(`usePeriodicApiKeyRefresh: expiresIn:${expiresIn}, isTokenExpiringSoon=${isTokenExpiringSoon}`);

        if (!isTokenExpiringSoon) {
            return false;
        }

        try {
            isRefreshing = true;

            await AuthUtils.refreshApiKey();

            isRefreshing = false;
        } catch (e) {
            logger.logError(e);
        }
    }, 10000);
};
