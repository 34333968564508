import { useState } from 'react';
import * as React from 'react';

import { Box } from '../components/common/Box';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTitle,
} from '../components/common/Modal';
import { Text } from '../components/common/Text';
import { useUser } from '../context/UserContext';
import { useToggle } from './useToggle';

const AdBlockWarningModal: React.FC = () => {
  const { on, set } = useToggle(true);
  const user = useUser();

  const onClose = () => set(false);

  return (
    <Modal aria-label="ad block detected" isOpen={on} onDismiss={onClose}>
      <ModalClose onClick={onClose} />
      <ModalContainer>
        <ModalTitle>AdBlocker detected</ModalTitle>

        <ModalContent>
          <Text mb={3}>Hi {user.firstName},</Text>
          <Text mb={3}>We've noticed that you're using an advert blocker.</Text>
          <Text mb={3}>
            Just to let you know, you may experience some techinical issues if you leave it on.
          </Text>
        </ModalContent>

        <ModalActions>
          <ModalButton onClick={onClose}>Thanks for letting me know</ModalButton>
        </ModalActions>
      </ModalContainer>
    </Modal>
  );
};

const AdBlockWarningBanner: React.FC = () => {
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 3,
        left: 3,
        bg: 'error',
        color: 'white',
        fontSize: 3,
        p: 5,
        borderRadius: '4px',
        zIndex: 1000,
      }}
    >
      Adblock is enabled. You may experience some techinical issues if you leave it on.
    </Box>
  );
};

export const useAdBlockDetector = () => {
  const [isAdBlockEnabled, setIsAdBlockEnabled] = useState(false);

  React.useEffect(() => {
    // grab a domain from https://github1s.com/gorhill/uBlock/blob/master/docs/tests/hostname-pool.js
    const url = 'https://www3.doubleclick.net';
    fetch(url, {
      method: 'HEAD',
      mode: 'no-cors',
      cache: 'no-store',
    })
      .then((response) => {
        //console.debug(response);
      })
      .catch((err) => {
        console.debug('adblock may be enabled');
        setIsAdBlockEnabled(true);
      });
  }, []);

  return {
    isAdBlockEnabled,
    AdBlockWarningModal,
    AdBlockWarningBanner,
  };
};
