import styled from '@emotion/styled/macro';
import { Link as RRLink, RouteComponentProps } from '@reach/router';
import { ErrorMessage, Formik } from 'formik';
import qs from 'qs';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import * as Yup from 'yup';

import { Box } from '../../components/common/Box';
import { Button } from '../../components/common/Button';
import { Flex } from '../../components/common/Flex';
import { TextField } from '../../components/common/form';
import { Heading } from '../../components/common/Heading';
import { Image } from '../../components/common/Image';
import { Text } from '../../components/common/Text';
import { useAuth } from '../../context/AuthContext';
import { useTheme } from '../../hooks/useTheme';
import { ApiFormError } from '../../utils/errors/ApiFormError';
import { EmailSchema } from '../../utils/form-validation/user-details';
import logger from '../../utils/logger';
import { AuthFormFooter } from './AuthFormFooter';
import { LeftContainer, RightContainer, SplitPageContainer } from './SplitPage';
import { Summary } from './Summary';

interface ForgotPasswordProps extends RouteComponentProps {}

const FormContainer = styled.form`
  padding-right: 3rem;
  padding-left: 3rem;

  @media (min-width: 1500px) {
    padding-right: 4.5rem;
    padding-left: 4.5rem;
  }

  @media (min-width: 1700px) {
    padding-right: 6rem;
    padding-left: 6rem;
  }
`;

const LoginLink = styled(RRLink)`
  color: ${(props) => props.theme.colors.grey[9]};
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  letter-spacing: -0.01rem;
  text-decoration: none;
  padding-right: 0;

  &:hover {
    color: ${(props) => props.theme.colors.accent};
  }

  @media (min-width: 1500px) {
    padding-right: 3rem;
  }

  @media (min-width: 1700px) {
    padding-right: 4.5rem;
  }
`;

const ForgotPasswordFormSchema = Yup.object().shape({
  email: EmailSchema,
});

export const ForgotPassword: React.FC<ForgotPasswordProps> = ({ location }) => {
  const { forgotPassword } = useAuth();
  const theme = useTheme();

  const queryParams = location ? qs.parse(location.search, { ignoreQueryPrefix: true }) : {};
  let { e: initialEmail } = queryParams;

  initialEmail = typeof initialEmail === 'string' ? initialEmail : '';

  return (
    <React.Fragment>
      <Helmet title="Forgot password" />
      <Formik
        initialValues={{ email: initialEmail }}
        initialStatus={{ resetEmailSent: false }}
        validationSchema={ForgotPasswordFormSchema}
        onSubmit={async (values, { setSubmitting, setErrors, setStatus }) => {
          if (typeof values.email !== 'string') {
            setErrors({ email: 'Please provide a valid e-mail address' });
            return;
          }

          try {
            setSubmitting(true);
            const response = await forgotPassword(values.email);
            setStatus({ resetEmailSent: response.passwordResetEmailSent });

            if (!response.passwordResetEmailSent) {
              setErrors({ email: 'Something went wrong whilst attempting to reset the e-mail' });
            }
          } catch (e) {
            if (e instanceof ApiFormError) {
              setErrors(e.errors);
            } else {
              logger.logError(e);
              setErrors({ email: 'Something went wrong whilst attempting to reset the e-mail' });
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ values, status, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <SplitPageContainer>
            <LeftContainer>
              <Flex
                sx={{
                  height: '100%',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <FormContainer onSubmit={handleSubmit}>
                  <Box pb={5}>
                    <Image
                      src={theme.assets.logo.dark}
                      alt="AdGrader logo"
                      sx={{ width: '15rem' }}
                    />
                  </Box>

                  <Heading
                    as="h1"
                    sx={{
                      fontSize: 7,
                      letterSpacing: '-0.075rem',
                      pb: 5,
                      color: 'text',
                      fontWeight: 'semibold',
                      minWidth: '4rem',
                    }}
                  >
                    Forgot Password
                  </Heading>

                  {status.resetEmailSent ? (
                    <Text sx={{ pb: 5 }}>
                      We've sent you a password reset email. Please check your inbox (including
                      spam!).
                    </Text>
                  ) : (
                    <React.Fragment>
                      <Flex sx={{ flexDirection: 'row', paddingBottom: 5 }}>
                        <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                          <TextField
                            type="email"
                            name="email"
                            placeholder="Email address"
                            data-testid="email"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={values.email}
                          />
                          <ErrorMessage name="email" />
                        </Flex>
                      </Flex>
                      <Flex
                        sx={{
                          flexDirection: 'row',
                          paddingBottom: 5,
                          justifyContent: 'space-between',
                        }}
                      >
                        <Flex>
                          <Button
                            variant="submit"
                            type="submit"
                            disabled={isSubmitting}
                            data-testid="submit"
                            sx={{
                              fontWeight: 500,
                              letterSpacing: '-0.01rem',
                              fontSize: '1.1rem',
                            }}
                          >
                            Reset password &rarr;
                          </Button>
                        </Flex>
                        <Flex sx={{ alignItems: 'center', justifyContent: 'flex-end' }}>
                          <LoginLink to={`/login`}>
                            <Box
                              sx={{
                                borderBottom: '2px solid rgb(51, 51, 51)',
                                '&:hover': {
                                  borderBottom: `2px solid ${theme.colors.accent}`,
                                },
                              }}
                            >
                              Already have an account?
                            </Box>
                          </LoginLink>
                        </Flex>
                      </Flex>
                    </React.Fragment>
                  )}

                  <AuthFormFooter email="" />
                </FormContainer>
              </Flex>
            </LeftContainer>
            <RightContainer>
              <Summary />
            </RightContainer>
          </SplitPageContainer>
        )}
      </Formik>
    </React.Fragment>
  );
};
