import { RouteComponentProps, useNavigate } from '@reach/router';
import * as React from 'react';
import { useQueries } from 'react-query';
import { toast } from 'react-toastify';

import { ApiUser } from '../../api/auth';
import { companyApi } from '../../api/company';
import { ApiInvite } from '../../api/invitation';
import {
  ApiSubscriptionPlanPeriodUnit,
  SubscriptionPlanTier,
} from '../../api/subscription-plan-pricing';
import { Box } from '../../components/common/Box';
import { Button } from '../../components/common/Button';
import { Flex } from '../../components/common/Flex';
import { Heading } from '../../components/common/Heading';
import { Text } from '../../components/common/Text';
import { ErrorBoundary } from '../../components/ErrorBoundary';
import { PlanHeader } from '../../components/PlanHeader';
import { useUser } from '../../context/UserContext';
import logger from '../../utils/logger';
import { CompanyInviteList, RevokeAction } from '../Company/UserList/CompanyInviteList';
import { CompanyLicencesStats } from '../Company/UserList/CompanyLicencesStats';
import { UserList } from '../Company/UserList/UserList';
import { SubscriptionPageError } from './SubscriptionPageError';
import { SubscriptionPageHeader } from './SubscriptionPageHeader';
import { SubscriptionPageLoading } from './SubscriptionPageLoading';

interface RemoveUsersProps extends RouteComponentProps {
  selectedPlanTier: SubscriptionPlanTier;
  seats: number;
  onContinue: () => void;
}

export const RemoveUsers = (props: RemoveUsersProps) => {
  const navigate = useNavigate();
  const user = useUser();

  const [companyMembersQuery, companyInvitesQuery] = useQueries([
    {
      queryKey: ['remove_users.team_members', user.company.id],
      queryFn: () => companyApi.fetchCompanyMembers(user.company.id),
      retry: 0,
      cacheTime: 0,
      staleTime: 0,
    },
    {
      queryKey: ['remove_users.team_invites', user.company.id],
      queryFn: () => companyApi.fetchCompanyInvites(user.company.id),
      retry: 0,
      cacheTime: 0,
      staleTime: 0,
    },
  ]);

  if (companyMembersQuery.isError || companyInvitesQuery.isError) {
    return (
      <FetchingFailed
        goToPricingPage={() => {
          navigate('/subscriptions/change');
        }}
      />
    );
  }

  if (companyMembersQuery.isLoading && companyInvitesQuery.isLoading) {
    return <FetchingInitialData />;
  }

  if (companyMembersQuery.isSuccess && companyMembersQuery.isSuccess) {
    return (
      <CompanyUsersOverview
        selectedPlanTier={props.selectedPlanTier}
        seats={props.seats}
        companyMembers={
          (companyMembersQuery.data as { companyMembers: ApiUser[] })?.companyMembers ?? []
        }
        companyInvites={(companyInvitesQuery.data as { invites: ApiInvite[] })?.invites ?? []}
        isFetchingCompanyMembers={companyMembersQuery.isLoading}
        isFetchingCompanyInvites={companyInvitesQuery.isLoading}
        refetchCompanyMembers={companyMembersQuery.refetch}
        refetchCompanyInvites={companyInvitesQuery.refetch}
        onContinue={props.onContinue}
      />
    );
  }

  return null;
};

interface FetchingFailedProps {
  goToPricingPage: () => void;
}

const FetchingFailed = (props: FetchingFailedProps) => {
  return (
    <SubscriptionPageError
      title="Oops. We encountered an error"
      subTitle="Something went wrong whilst fetching your members & invites"
      errorMessage=""
      renderAction={
        <Button variant="primary" onClick={props.goToPricingPage}>
          Go back to pricing page
        </Button>
      }
    />
  );
};

const FetchingInitialData = () => {
  return (
    <SubscriptionPageLoading
      title="Loading"
      subTitle="Fetching your existing team members and invites"
    />
  );
};

interface CompanyUsersOverviewProps {
  selectedPlanTier: SubscriptionPlanTier;
  seats: number;
  companyMembers: ApiUser[];
  companyInvites: ApiInvite[];
  isFetchingCompanyMembers: boolean;
  isFetchingCompanyInvites: boolean;
  refetchCompanyMembers: () => void;
  refetchCompanyInvites: () => void;
  onContinue: () => void;
}

const CompanyUsersOverview = (props: CompanyUsersOverviewProps) => {
  const user = useUser();

  const licencesInUse = props.companyMembers.length + props.companyInvites.length;
  const hasTooManyLicencesInUse = licencesInUse > props.seats;

  return (
    <React.Fragment>
      <Box>
        <SubscriptionPageHeader
          title="You've got too many users for your plan."
          subTitle={
            <div>
              Before you can progress with AdGrader, you need to remove some users below, or upgrade
              your plan.
            </div>
          }
        />

        <Box sx={{ height: '3.75rem' }} />

        <Flex sx={{ justifyContent: 'center', px: 5, pt: 7, pb: 9 }}>
          <Flex sx={{ flex: 1, flexDirection: 'column', maxWidth: '1000px' }}>
            <ErrorBoundary>
              <Flex sx={{ flex: 1, flexDirection: 'column', mb: 8 }}>
                <Box pb="5">
                  <PlanHeader
                    title="Selected Plan:"
                    plan={
                      <Text as="span">
                        {props.selectedPlanTier.name}{' '}
                        <Text as="span">
                          (billed{' '}
                          {props.selectedPlanTier.billingPeriod ===
                          ApiSubscriptionPlanPeriodUnit.Month
                            ? 'monthly'
                            : 'annually'}
                          )
                        </Text>
                      </Text>
                    }
                  />
                </Box>

                <CompanyLicencesStats total={props.seats} used={licencesInUse} />
              </Flex>
            </ErrorBoundary>

            <ErrorBoundary>
              <Heading as="h2" mb="3">
                User list:
              </Heading>

              <UserList
                currentUser={user}
                users={props.companyMembers}
                fetchingMembers={props.isFetchingCompanyMembers}
                actionsBackgroundColour="#f6f6f6"
                onFetchMembers={props.refetchCompanyMembers}
                onRemoveMember={async (member: ApiUser) => {
                  try {
                    await companyApi.removeCompanyMember(user.company.id, member.id);
                    toast.success('The user has been removed');
                  } catch (e) {
                    logger.logError(e);
                    toast.error('Something went wrong whilst removing the user');
                  }
                }}
              />
            </ErrorBoundary>

            {props.companyInvites.length > 0 ? (
              <ErrorBoundary>
                <h2>Invite list:</h2>

                <CompanyInviteList
                  invites={props.companyInvites}
                  fetchingInvites={props.isFetchingCompanyInvites}
                  actionsBackgroundColour="#f6f6f6"
                  renderActions={(invite) => {
                    return (
                      <RevokeAction invite={invite} onFetchInvites={props.refetchCompanyInvites} />
                    );
                  }}
                />
              </ErrorBoundary>
            ) : null}

            <Box sx={{ pb: 5 }} />

            <Flex sx={{ justifyContent: 'flex-start' }}>
              <Button
                disabled={hasTooManyLicencesInUse}
                variant={hasTooManyLicencesInUse ? 'disabled' : 'primary'}
                onClick={() => {
                  if (hasTooManyLicencesInUse) {
                    return;
                  }

                  props.onContinue();
                }}
              >
                Continue
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </Box>
    </React.Fragment>
  );
};
