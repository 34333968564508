import * as Yup from 'yup';

export const FirstNameSchema = Yup.string()
  .min(2, 'Too Short')
  .max(50, 'Too Long')
  .required('Required');

export const LastNameSchema = Yup.string()
  .min(2, 'Too Short')
  .max(50, 'Too Long')
  .required('Required');

export const EmailSchema = Yup.string().email('Invalid email').required('Required');

const passwordErrorMessage = '8 characters or more, at least one number and one letter';

export const PasswordSchema = Yup.string()
  .min(8, passwordErrorMessage)
  .matches(/\d/, passwordErrorMessage)
  .matches(/[a-zA-Z]/, passwordErrorMessage)
  .required('Required');

const recaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED === 'true';

export const ReCaptchaSchema = recaptchaEnabled
  ? Yup.string().required('Recaptcha is required')
  : Yup.string();
