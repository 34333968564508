export const subscriptionTheme = {
  forms: {
    label: {
      fontWeight: 700,
      fontSize: '0.8rem',
      letterSpacing: '-0.0125rem',
    },
    input: {
      backgroundColor: '#ffffff',
      borderWidth: 1,
      borderColor: 'grey.3',
      padding: 3,
    },
    select: {
      button: {
        backgroundColor: '#ffffff',
        border: '1px solid #cccccc',
      },
      menu: {
        position: 'absolute',
        top: 'calc(100% + 0.1rem)',
        left: 0,
        width: '100%',
      },
      item: {
        cursor: 'pointer',
        fontWeight: 400,
      },
    },
    errorMessage: {
      mt: 2,
      p: 0,
      color: 'error',
      backgroundColor: 'transparent',
      fontSize: '0.9rem',
    },
  },
};
