import * as React from 'react';

import {
  ApiCompanyAnalyticsDetails,
  inclusivityWordTypes,
  readabilityWordTypes,
} from '../../../api/company-analytics';
import { Grid } from '../../../components/common/Grid';
import { StatisticRow, StatisticRowSection } from './StatisticRow';
import { StatsBox } from './StatsBox';

interface IAnalyticsOverviewProps {
  companyAnalytics: ApiCompanyAnalyticsDetails;
}

export const AnalyticsOverview: React.FC<IAnalyticsOverviewProps> = ({ companyAnalytics }) => {
  const termsFlaggedInclusion = companyAnalytics
    ? inclusivityWordTypes.map((wordType) => companyAnalytics?.GradedWords[wordType]).flat().length
    : undefined;

  const termsFlaggedReadability = companyAnalytics
    ? readabilityWordTypes.map((wordType) => companyAnalytics?.GradedWords[wordType]).flat().length
    : undefined;

  return (
    <StatisticRowSection title="At a glance" sx={{ gridColumn: 'span 2' }}>
      <StatsBox>
        <Grid sx={{ gridTemplateColumns: '1fr 1fr', gap: 3 }}>
          <StatisticRow name="Total ads" value={companyAnalytics?.AdCount} />

          <StatisticRow
            name="Total ads with content"
            value={companyAnalytics?.AdWithContentCount}
          />

          <StatisticRow
            name="Avg. inclusivity score"
            value={companyAnalytics?.StatsAverages.GradingInclusivityScore}
            unit="%"
          />

          <StatisticRow
            name="Avg. readability score"
            value={companyAnalytics?.StatsAverages.GradingReadabilityScore}
            unit="%"
          />

          <StatisticRow name="Total terms flagged (inclusion)" value={termsFlaggedInclusion} />

          <StatisticRow name="Total terms flagged (readability)" value={termsFlaggedReadability} />

          <StatisticRow name="Avg. word count" value={companyAnalytics?.StatsAverages.WordCount} />
        </Grid>
      </StatsBox>
    </StatisticRowSection>
  );
};
