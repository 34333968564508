import { useMemo } from 'react';

import { QuestionFieldOption } from '../components/QuestionFieldProps';
import { removeAutoFillPrefix } from './autoFillPrefixUtils';

export const useExampleAnswerMatch = (
  options: QuestionFieldOption[],
  input: string,
  prefix = ''
) => {
  return useMemo(() => {
    if (!options || options.length === 0) {
      return [];
    }

    if (!input) {
      return options;
    }

    const val = removeAutoFillPrefix(prefix, input).toLowerCase();

    return options.filter((option) => {
      if (!option.value) {
        return false;
      }

      const withoutPrefix = removeAutoFillPrefix(prefix, option.value).toLowerCase();

      // Don't return exact match
      if (withoutPrefix === val) {
        return false;
      }

      return withoutPrefix.includes(val);
    });
  }, [options, input, prefix]);
};
