import styled from '@emotion/styled/macro';
import { navigate, RouteComponentProps } from '@reach/router';
import { useMachine } from '@xstate/react';
import { ErrorMessage, Field, FieldProps, Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import * as React from 'react';
import { assign, createMachine } from 'xstate';
import * as Yup from 'yup';

import { ApiTeam, ApiTeamAdvertCompanyData, teamApi, TeamData } from '../../../api/team';
import { ColouredTooltip } from '../../../components/ColouredTooltip';
import { Box } from '../../../components/common/Box';
import { Button } from '../../../components/common/Button';
import { Flex } from '../../../components/common/Flex';
import { ErrorMessage as ThemedErrorMessage, TextField } from '../../../components/common/form';
import { Heading } from '../../../components/common/Heading';
import { Table, TableBody, TableCell, TableHead, TableRow } from '../../../components/common/Table';
import { Text } from '../../../components/common/Text';
import { ErrorBoundary } from '../../../components/ErrorBoundary/index';
import { Pencil } from '../../../components/Icon/index';
import { EditUserModal } from '../../../components/Modal/EditUserModal';
import { CompanyOwnerOnly } from '../../../components/Permissions/CompanyOwnerOnly';
import { RuntimeError, UserFacingError } from '../../../Error/BaseErrors';
import { useToggle } from '../../../hooks/useToggle';
import { assertEventType, assertEventTypeOneOf } from '../../../utils/xstate-helpers';
import { DeleteTeam } from './DeleteTeam';
import { EditCompanyDefaultAnswers } from './EditCompanyDefaultAnswers';

const ClickableText = styled(Text)`
  color: ${(props) => props.theme.colors.accent};
  text-decoration: none;
  font-weight: ${(props) => props.theme.fontWeights.semibold};
  cursor: pointer;
  border-bottom: 2px solid;
  border-bottom-color: transparent;
  letter-spacing: -0.02rem;

  &:hover {
    border-bottom-color: ${(props) => props.theme.colors.accent};
    border-radius: 0;
  }
`;

interface TeamDetailsMachineContext {
  teamId?: number;
  team?: ApiTeam;
  error?: string;
}

type TeamDetailsMachineInternalEvent =
  | {
      type: 'done.invoke.fetchTeamDetails';
      data: { team: ApiTeam };
    }
  | {
      type: 'done.invoke.updateTeamDetails';
      data: { team: ApiTeam };
    }
  | {
      type: 'done.invoke.updateTeamAdvertCompanyData';
      data: { advertCompanyId: number; companyData: ApiTeamAdvertCompanyData };
    };

type TeamDetailsMachineEvent =
  | { type: 'FETCH_TEAM_DETAILS'; data: { teamId: number } }
  | { type: 'UPDATE_DETAILS'; data: { teamData: TeamData } }
  | {
      type: 'UPDATE_TEAM_ADVERT_COMPANY_DATA';
      data: { advertCompanyId: number; companyData: ApiTeamAdvertCompanyData };
    }
  | { type: 'RELOAD' }
  | TeamDetailsMachineInternalEvent;

type TeamDetailsMachineState =
  | {
      value: 'idle';
      context: TeamDetailsMachineContext;
    }
  | {
      value: 'loading';
      context: TeamDetailsMachineContext;
    }
  | {
      value: 'ready';
      context: TeamDetailsMachineContext & { team: ApiTeam; error: undefined };
    }
  | {
      value: 'error';
      context: TeamDetailsMachineContext & { team: undefined; error: string };
    };

const teamDetailsMachine = createMachine<
  TeamDetailsMachineContext,
  TeamDetailsMachineEvent,
  TeamDetailsMachineState
>(
  {
    id: 'teamDetails',
    initial: 'idle',
    states: {
      idle: {
        on: {
          FETCH_TEAM_DETAILS: {
            target: 'loading',
            actions: 'cacheTeamId',
          },
        },
      },
      loading: {
        id: 'loading',
        entry: ['clearError'],
        invoke: {
          id: 'fetchTeamDetails',
          src: 'fetchTeamDetails',
          onDone: {
            target: 'ready',
            actions: ['cacheTeamDetails'],
          },
          onError: {
            target: 'error',
            actions: ['setTeamDetailsFetchError'],
          },
        },
      },
      ready: {
        initial: 'idle',
        entry: ['clearError'],
        states: {
          idle: {
            on: {
              UPDATE_DETAILS: {
                target: 'updatingTeamDetails',
              },
              UPDATE_TEAM_ADVERT_COMPANY_DATA: {
                target: 'updatingTeamAdvertCompanyData',
              },
              RELOAD: {
                target: '#loading',
              },
            },
          },
          updatingTeamDetails: {
            invoke: {
              id: 'updateTeamDetails',
              src: 'updateTeamDetails',
              onDone: {
                target: 'idle',
                actions: ['cacheTeamDetails'],
              },
              onError: {
                target: 'idle',
                actions: ['notifyTeamDetailsUpdateError'],
              },
            },
          },
          updatingTeamAdvertCompanyData: {
            invoke: {
              id: 'updateTeamAdvertCompanyData',
              src: 'updateTeamAdvertCompanyData',
              onDone: {
                target: 'idle',
                actions: ['cacheTeamAdvertCompanyData'],
              },
              onError: {
                target: 'idle',
                actions: ['notifyTeamAdvertCompanyDataUpdateError'],
              },
            },
          },
        },
      },
      error: {
        on: {
          RELOAD: {
            target: 'loading',
          },
        },
      },
    },
  },
  {
    actions: {
      clearError: assign({ error: (ctx) => undefined }),
      setTeamDetailsFetchError: assign({
        error: (ctx) => 'Something went wrong whilst fetching the team details',
      }),
      notifyTeamDetailsUpdateError: assign({
        error: (ctx) => 'Something went wrong whilst updating the team details',
      }),
      notifyTeamAdvertCompanyDataUpdateError: assign({
        error: (ctx) => 'Something went wrong whilst updating the company data',
      }),

      cacheTeamId: assign({
        teamId: (ctx, ev) => {
          assertEventType(ev, 'FETCH_TEAM_DETAILS');

          return ev.data.teamId;
        },
      }),
      cacheTeamDetails: assign({
        team: (ctx, ev) => {
          assertEventTypeOneOf(ev, [
            'done.invoke.fetchTeamDetails',
            'done.invoke.updateTeamDetails',
          ]);

          return ev.data.team;
        },
      }),
      cacheTeamAdvertCompanyData: assign({
        team: (ctx, ev) => {
          assertEventType(ev, 'done.invoke.updateTeamAdvertCompanyData');

          if (!ctx.team) {
            return ctx.team;
          }

          const companies = ctx.team?.teamCompanies.map((company) => {
            if (company.rootId !== ev.data.advertCompanyId) {
              return company;
            }

            return {
              ...company,
              data: ev.data.companyData,
            };
          });

          return {
            ...ctx.team,
            teamCompanies: companies,
          };
        },
      }),
    },
    services: {
      fetchTeamDetails: async (ctx) => {
        if (!ctx.teamId) {
          throw new RuntimeError(
            'Something went wrong whilst fetching the team details',
            'No team id is set on context. Initialise this before fetching the team details'
          );
        }

        return teamApi.getTeam(ctx.teamId);
      },
      updateTeamDetails: async (ctx, ev) => {
        if (!ctx.teamId) {
          throw new RuntimeError(
            'Something went wrong whilst updating the team details',
            'No team id is set on context. Initialise this before updating the team details'
          );
        }

        assertEventType(ev, 'UPDATE_DETAILS');

        return teamApi.updateTeam(ctx.teamId, ev.data.teamData);
      },
      updateTeamAdvertCompanyData: async (ctx, ev) => {
        if (!ctx.teamId) {
          throw new RuntimeError(
            'Something went wrong whilst updating the team details',
            'No team id is set on context. Initialise this before updating the company data'
          );
        }

        assertEventType(ev, 'UPDATE_TEAM_ADVERT_COMPANY_DATA');

        await teamApi.updateTeamAdvertCompanyData(
          ctx.teamId,
          ev.data.advertCompanyId,
          ev.data.companyData
        );

        return ev.data;
      },
    },
  }
);

interface TeamDetailsProps extends RouteComponentProps<{ id: string }> {}

export const TeamDetails: React.FC<TeamDetailsProps> = ({ id }) => {
  if (!id) {
    throw new UserFacingError('No team id provided');
  }

  const [state, send] = useMachine(teamDetailsMachine, {
    context: {
      teamId: parseInt(id, 10),
    },
  });

  useEffect(() => {
    send({ type: 'FETCH_TEAM_DETAILS', data: { teamId: parseInt(id, 10) } });
  }, [id, send]);

  return (
    <Flex
      sx={{
        justifyContent: 'center',
        px: 5,
        pt: 7,
        pb: 9,
        letterSpacing: '-0.01rem',
      }}
    >
      <Flex sx={{ flex: 1, flexDirection: 'column', maxWidth: '1000px' }}>
        <ErrorBoundary>
          <Flex sx={{ flex: 1, flexDirection: 'column', mb: 7 }}>
            {state.matches('idle') || state.matches('loading') ? (
              <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                <Text>Fetching team details</Text>
              </Flex>
            ) : state.matches('error') ? (
              <Flex sx={{ flex: 1, flexDirection: 'column', alignItems: 'center' }}>
                <Text>{state.context.error}</Text>
                <Box py={1} />
                <div>
                  <Button
                    variant="warning"
                    onClick={() => {
                      send('RELOAD');
                    }}
                  >
                    Retry
                  </Button>
                </div>
              </Flex>
            ) : state.matches('ready') ? (
              <TeamDetailsImpl
                team={state.context.team}
                onUpdate={async (data: TeamData) => {
                  send({
                    type: 'UPDATE_DETAILS',
                    data: {
                      teamData: data,
                    },
                  });
                }}
                onUpdateCompanyData={async (
                  advertCompanyId: number,
                  data: ApiTeamAdvertCompanyData
                ) => {
                  send({
                    type: 'UPDATE_TEAM_ADVERT_COMPANY_DATA',
                    data: {
                      advertCompanyId,
                      companyData: data,
                    },
                  });
                }}
                onReload={async () => {
                  send('RELOAD');
                }}
              />
            ) : null}
          </Flex>
        </ErrorBoundary>
      </Flex>
    </Flex>
  );
};

const TeamName = styled(Text)`
  cursor: pointer;

  i {
    color: ${(props) => props.theme.colors.grey[4]};
    font-size: 1.2rem;
    padding-left: 0.25rem;
  }

  &:hover {
    i {
      color: ${(props) => props.theme.colors.accent};
    }
  }
`;

const teamValidationSchema = Yup.object().shape({
  name: Yup.string().min(1, 'Too Short').max(50, 'Too Long').required(),
});

interface TeamDetailsImplProps {
  team: ApiTeam;
  onUpdate: (data: TeamData) => Promise<void>;
  onUpdateCompanyData: (advertCompanyId: number, data: ApiTeamAdvertCompanyData) => Promise<void>;
  onReload: () => Promise<void>;
}

const TeamDetailsImpl: React.FC<TeamDetailsImplProps> = ({
  team,
  onUpdate,
  onUpdateCompanyData,
  onReload,
}) => {
  const [editingName, setEditingName] = useState(false);

  return (
    <React.Fragment>
      <ErrorBoundary>
        <Flex sx={{ flex: 1, flexDirection: 'column', mb: 7 }}>
          <Flex sx={{ justifyContent: 'space-between', alignItems: 'center', pb: 3 }}>
            <Heading
              as="h2"
              sx={{
                fontSize: 7,
                width: '60%',
                fontWeight: 'semibold',
                letterSpacing: '-0.065rem',
                color: 'accent',
              }}
            >
              {editingName ? (
                <Formik
                  initialValues={{ name: team.name }}
                  validationSchema={teamValidationSchema}
                  onSubmit={async (values) => {
                    await onUpdate(values);
                    setEditingName(false);
                  }}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Flex>
                        <Flex sx={{ flex: 1 }}>
                          <Field name="name">
                            {({ field }: FieldProps) => (
                              <>
                                <TextField
                                  {...field}
                                  id="name"
                                  data-testid="team-edit-name-input"
                                />
                                <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                              </>
                            )}
                          </Field>
                        </Flex>
                        <Button type="submit" variant="primary" disabled={isSubmitting} ml={5}>
                          Update
                        </Button>
                      </Flex>
                    </Form>
                  )}
                </Formik>
              ) : (
                <TeamName as="span" onClick={() => setEditingName(true)}>
                  {team.name} <Pencil data-testid="team-edit-name" />
                </TeamName>
              )}
            </Heading>

            <DeleteTeam
              team={team}
              onTeamTransfered={() => {
                navigate('/settings/teams');
              }}
            />
          </Flex>
        </Flex>
      </ErrorBoundary>

      <ErrorBoundary>
        <Flex sx={{ flex: 1, flexDirection: 'column', mb: 7 }}>
          <Heading
            as="h2"
            sx={{
              pt: 5,
              m: '0 0 2rem',
              fontSize: 6,
              fontWeight: 'semibold',
              borderTop: 1,
              borderColor: 'grey.3',
            }}
          >
            Team members
          </Heading>

          <Box pb="3">
            {team.teamUsers.length === 0 ? (
              <Text>This team does not have any users</Text>
            ) : (
              <Box sx={{ maxHeight: '500px', overflow: 'auto' }}>
                <Table>
                  <TableHead fixedHeader>
                    <TableRow>
                      <TableCell sx={{ bg: 'white' }}>Name</TableCell>
                      <TableCell sx={{ bg: 'white' }}>Email</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody bg="grey.1">
                    {team.teamUsers.map((user) => (
                      <TableRow key={user.id}>
                        <CompanyOwnerOnly fallback={<TableCell>{user.displayName}</TableCell>}>
                          <UserName user={user} teamId={team.id} onUpdated={onReload} />
                        </CompanyOwnerOnly>
                        <TableCell>{user.email}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Box>
        </Flex>
      </ErrorBoundary>

      <ErrorBoundary>
        <Flex sx={{ flex: 1, flexDirection: 'column', mb: 7 }}>
          <Heading
            as="h2"
            sx={{
              pt: 5,
              m: '0 0 2rem',
              fontSize: 6,
              fontWeight: 'semibold',
              borderTop: 1,
              borderColor: 'grey.3',
            }}
          >
            Team adverts
          </Heading>

          <Box pb="3">
            {team.teamAdverts.length === 0 ? (
              <Text>This team does not have any adverts yet</Text>
            ) : (
              <Box sx={{ maxHeight: '500px', overflow: 'auto' }}>
                <Table fixedHeader>
                  <TableHead sx={{ bg: 'white' }}>
                    <TableRow>
                      <TableCell sx={{ bg: 'white' }}>Job title</TableCell>
                      <TableCell sx={{ bg: 'white' }}>Company name</TableCell>
                      <TableCell sx={{ bg: 'white' }}>Created by</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody bg="grey.1">
                    {team.teamAdverts.map((advert) => (
                      <TableRow key={advert.id}>
                        <AdvertJobTitle advertId={advert.id} jobTitle={advert.jobTitle} />
                        <TableCell>{advert.companyName}</TableCell>
                        <TableCell>{advert.createdByName}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            )}
          </Box>
        </Flex>
      </ErrorBoundary>

      <ErrorBoundary>
        <CompaniesList team={team} onUpdateCompanyData={onUpdateCompanyData} />
      </ErrorBoundary>
    </React.Fragment>
  );
};

interface UserNameProps {
  user: ApiTeam['teamUsers'][0];
  teamId: number;
  onUpdated: () => Promise<void>;
}

export const UserName: React.FC<UserNameProps> = (props) => {
  const { on, toggle, set } = useToggle();

  return (
    <React.Fragment>
      <td>
        <ColouredTooltip label={`Edit ${props.user.displayName}'s details`} sx={{ bg: 'accent' }}>
          <ClickableText as="span" onClick={toggle}>
            {props.user.displayName}
          </ClickableText>
        </ColouredTooltip>
      </td>

      <EditUserModal
        isOpen={on}
        user={{
          ...props.user,
          teamId: props.teamId,
        }}
        onUserEdited={() => {
          set(false);
          props.onUpdated();
        }}
        onClose={() => {
          set(false);
        }}
      />
    </React.Fragment>
  );
};

interface AdvertJobTitleProps {
  advertId: number;
  jobTitle: string;
}

export const AdvertJobTitle: React.FC<AdvertJobTitleProps> = (props) => {
  return (
    <React.Fragment>
      <td>
        <ColouredTooltip label="View this advert" sx={{ bg: 'accent' }}>
          <ClickableText as="span" onClick={function(){}}>
            {props.jobTitle}
          </ClickableText>
        </ColouredTooltip>
      </td>
    </React.Fragment>
  );
};

interface CompaniesListProps {
  team: ApiTeam;
  onUpdateCompanyData: (advertCompanyId: number, data: ApiTeamAdvertCompanyData) => Promise<void>;
}

const CompaniesList: React.FC<CompaniesListProps> = (props) => {
  return (
    <Flex sx={{ flex: 1, flexDirection: 'column', mb: 7 }}>
      <Heading
        as="h2"
        sx={{
          pt: 5,
          m: '0 0 2rem',
          fontSize: 6,
          fontWeight: 'semibold',
          borderTop: 1,
          borderColor: 'grey.3',
        }}
      >
        Team companies
      </Heading>

      <Box pb="3">
        {props.team.teamCompanies.length === 0 ? (
          <Text>This team does not have any companies yet</Text>
        ) : (
          <Box sx={{ maxHeight: '500px', overflow: 'auto' }}>
            <Table fixedHeader>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ bg: 'white' }}>Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody bg="grey.1">
                {props.team.teamCompanies.map((company) => (
                  <TableRow key={company.id}>
                    <TableCell>
                      <EditCompanyDefaultAnswers
                        company={company}
                        team={props.team}
                        onUpdateCompanyData={async (data) => {
                          await props.onUpdateCompanyData(company.rootId, data);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Box>
        )}
      </Box>
    </Flex>
  );
};
