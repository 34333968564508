import * as React from 'react';

import { Flex } from '../../../components/common/Flex';
import { Label } from '../../../components/common/form';
import { useAdvertBuilder } from '../AdvertBuilderContext';

interface QuestionProps {
  name: string;
  label: string;
}

export const Question: React.FC<QuestionProps> = (props) => {
  const state = useAdvertBuilder();

  return state ? <AnimatedQuestion {...props} /> : <RawQuestion {...props} />;
};

const AnimatedQuestion: React.FC<QuestionProps> = (props) => {
  return (
    <RawQuestion {...props} />
  );
};

const RawQuestion: React.FC<QuestionProps> = (props) => {
  return (
    <Flex
    sx={{
      backgroundColor: '#e8e8e8',
      border: '1px solid #dadada',
      alignItems: 'center',
      position: 'relative'
    }}
    >
      <Label
        sx={{
          display: 'inline-block',
          paddingLeft: '1rem',
          width: '130px',
          fontSize: '13px',
          fontWeight: '500',
        }}
        htmlFor={props.name}
        dangerouslySetInnerHTML={{ __html: props.label+":" }}
      />
      {props.children}
    </Flex>
  );
};
