import { useNavigate } from '@reach/router';
import { ErrorMessage, Form, Formik } from 'formik';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { businessTypes, RegistrationMetadata } from '../../../api/auth';
import { isAxiosError } from '../../../api/util';
import { Box } from '../../../components/common/Box';
import { Button } from '../../../components/common/Button';
import { Flex } from '../../../components/common/Flex';
import { ErrorMessage as ThemedErrorMessage, TextField } from '../../../components/common/form';
import { Grid } from '../../../components/common/Grid';
import { Image } from '../../../components/common/Image';
import { ReCaptchaFieldComponent } from '../../../components/common/ReCaptchaField';
import { Text } from '../../../components/common/Text';
import { LinkedInOAuthSignIn } from '../../../components/LinkedInOAuthSignIn';
import { RegistrationWizard } from '../../../components/Registration/RegistrationWizardContainer';
import { RegistrationFormLabel } from '../../../components/RegistrationFormLabel';
import { REGISTRATION_WIZARD_STEPS } from '../../../config/registration';
import { useAuth } from '../../../context/AuthContext';
import { FlagGuard } from '../../../hooks/useFlag';
import { useTheme } from '../../../hooks/useTheme';
import { ApiFormError } from '../../../utils/errors/ApiFormError';
import {
  EmailSchema,
  FirstNameSchema,
  LastNameSchema,
  PasswordSchema,
  ReCaptchaSchema,
} from '../../../utils/form-validation/user-details';
import logger from '../../../utils/logger';

interface RegistrationWizardVariantProps {
  initialValues: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    businessType: typeof businessTypes[0];
    recaptcha: string;
  };
  metadata: RegistrationMetadata;
}

export const RegistrationWizardVariant = (props: RegistrationWizardVariantProps) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Helmet title="Registration" />
      <Box sx={{ position: 'absolute', top: 0, left: 0 }}>
        <Box p={4}>
          <a href="https://adgrader.io">
            <Image sx={{ width: '12rem' }} src={theme.assets.logo.dark} alt="AdGrader logo" />
          </a>
        </Box>
      </Box>

      <RegistrationWizard.Container>
        <RegistrationWizard.FreeTrialHeader />
        <RegistrationWizard.StepIndicator activeStepIndex={0} steps={REGISTRATION_WIZARD_STEPS} />
        <RegistrationWizard.Content>
          <UserDetailsForm initialValues={props.initialValues} metadata={props.metadata} />
        </RegistrationWizard.Content>
      </RegistrationWizard.Container>
    </React.Fragment>
  );
};

const UserDetailsFormSchema = Yup.object().shape({
  firstName: FirstNameSchema,
  lastName: LastNameSchema,
  email: EmailSchema,
  password: PasswordSchema,
  recaptcha: ReCaptchaSchema,
});

interface UserDetailsFormProps {
  initialValues: {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    businessType: typeof businessTypes[0];
    recaptcha: string;
  };
  metadata: RegistrationMetadata;
}

const UserDetailsForm = (props: UserDetailsFormProps) => {
  const { register, logout } = useAuth();
  const navigate = useNavigate();

  const recaptchaEnabled = process.env.REACT_APP_RECAPTCHA_ENABLED === 'true';

  return (
    <Box sx={{ p: 5 }}>
      <FlagGuard flag="linkedin_oauth_enabled" defaultVal={false}>
        {({ value: isActive }) => {
          return isActive ? (
            <React.Fragment>
              <Flex sx={{ justifyContent: 'center', mt: 3, mb: 3 }}>
                <LinkedInOAuthSignIn />
              </Flex>
              <Box sx={{ py: 5 }}>
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    height: '1px',
                    bg: 'grey.3',
                    textAlign: 'center',
                  }}
                >
                  <Flex
                    sx={{
                      position: 'absolute',
                      top: '-13px',
                      width: '100%',
                      justifyContent: 'center',
                    }}
                  >
                    <Text
                      sx={{
                        fontSize: '1.2rem',
                        bg: 'white',
                        color: 'grey.3',
                        px: '8px',
                        fontStyle: 'italic',
                      }}
                    >
                      or
                    </Text>
                  </Flex>
                </Box>
              </Box>
            </React.Fragment>
          ) : null;
        }}
      </FlagGuard>

      <Formik
        initialValues={props.initialValues}
        validationSchema={UserDetailsFormSchema}
        onSubmit={async (values, { setErrors }) => {
          try {
            await register({
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              password: values.password,
              metadata: props.metadata,
              recaptcha: values.recaptcha,
            });

            navigate(`/account-setup/company?bt=${props.initialValues.businessType.id}`);
          } catch (e) {
            if (e instanceof ApiFormError) {
              setErrors(e.errors);
            } else if (isAxiosError(e)) {
              logger.logError(e);

              if (e.response?.data?.error) {
                toast.error(e.response?.data?.error);
              }
            } else {
              logger.logError(e);
            }
          }
        }}
      >
        {({ values, isSubmitting, handleChange, handleBlur, setFieldValue }) => (
          <Form>
            <Grid
              sx={{
                gridTemplateColumns: [null, '1fr 1fr'],
                columnGap: [0, 3],
                gridTemplateRows: ['1fr 1fr', 'unset'],
                rowGap: [3, 0],
                pb: 3,
                letterSpacing: '-0.01rem',
              }}
            >
              <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                <RegistrationFormLabel>First name:</RegistrationFormLabel>
                <TextField
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.firstName}
                />
                <ErrorMessage name="firstName">
                  {(msg) => <ThemedErrorMessage>{msg}</ThemedErrorMessage>}
                </ErrorMessage>
              </Flex>

              <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                <RegistrationFormLabel>Last name:</RegistrationFormLabel>
                <TextField
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.lastName}
                />
                <ErrorMessage name="lastName">
                  {(msg) => <ThemedErrorMessage>{msg}</ThemedErrorMessage>}
                </ErrorMessage>
              </Flex>
            </Grid>

            <Flex sx={{ pb: 3, letterSpacing: '-0.01rem' }}>
              <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                <RegistrationFormLabel>Work email address:</RegistrationFormLabel>
                <TextField
                  type="email"
                  name="email"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.email}
                />
                <ErrorMessage name="email">
                  {(msg) => <ThemedErrorMessage>{msg}</ThemedErrorMessage>}
                </ErrorMessage>
              </Flex>
            </Flex>

            <Flex sx={{ pb: 5 }}>
              <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                <RegistrationFormLabel>Choose a password:</RegistrationFormLabel>
                <TextField
                  type="password"
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                />
                <ErrorMessage name="password">
                  {(msg) => <ThemedErrorMessage>{msg}</ThemedErrorMessage>}
                </ErrorMessage>
              </Flex>
            </Flex>

            {recaptchaEnabled && (
              <Flex sx={{ pb: 6 }}>
                <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                  <Box sx={{ pb: 2 }}>
                    <ReCaptchaFieldComponent
                      onChange={(token) => setFieldValue('recaptcha', token)}
                    />
                  </Box>

                  <div style={{ width: 300 }}>
                    <ErrorMessage name="recaptcha">
                      {(msg) => <ThemedErrorMessage>{msg}</ThemedErrorMessage>}
                    </ErrorMessage>
                  </div>
                </Flex>
              </Flex>
            )}

            <Flex
              sx={{
                flexDirection: ['column', 'row'],
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Button
                type="submit"
                variant="primaryInverted"
                disabled={isSubmitting}
                sx={{
                  fontWeight: 500,
                  letterSpacing: '-0.01rem',
                  fontSize: '1.1rem',
                  p: '1.25rem 2.5rem',
                  mb: [5, 0],
                }}
              >
                Next &rarr;
              </Button>

              <Box>
                <Text sx={{ fontWeight: 500, letterSpacing: '-0.02rem' }}>
                  Already got an account?{' '}
                  <Button
                    variant="text"
                    onClick={() => {
                      logout('/login');
                    }}
                    sx={{
                      borderRadius: '0px',
                      fontWeight: 500,
                      color: '#FF2779',
                      letterSpacing: '-0.01rem',
                      borderBottom: '2px solid',
                      '&:hover': {
                        textDecoration: 'none',
                        borderBottom: '2px solid #FF2779',
                      },
                    }}
                  >
                    Log in &rarr;
                  </Button>
                </Text>
              </Box>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
