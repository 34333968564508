import { navigate } from '@reach/router';
import { useCombobox } from 'downshift';
import { Fragment, useState } from 'react';
import * as React from 'react';
import { useQuery } from 'react-query';

import { analytics } from '../../analytics/analytics';
import { advertApi } from '../../api/advert';
import { Box } from '../../components/common/Box';
import { TextField } from '../../components/common/form';
import { SelectMenu, SelectMenuItem } from '../../components/common/Select';
import { Text } from '../../components/common/Text';
import { useDebounce } from '../../hooks/useDebounce';
import { useLogError } from '../../hooks/useLogError';
import { ThemeProvider } from '../../theme/ThemeProvider';

const theme = {
  forms: {
    input: {
      pt: '0.45rem',
      pb: '0.45rem',
      backgroundColor: 'rgba(255,255,255,0.1)',
      border: '2px solid #ffffff',
      borderRadius: '4px',
      color: 'white',
      minWidth: '250px',
      fontSize: 1,
      '::placeholder': {
        color: 'rgba(255,255,255,0.5)',
      },
    },
    select: {
      menu: {
        position: 'absolute',
        top: 'calc(100% + 0.1rem)',
        maxHeight: '220px',
        width: '400px',
        right: 0,
      },
      item: {
        cursor: 'pointer',
        fontSize: 1,
        '&:hover, &:focus': {
          background: '#eeeeee',
        },
      },
    },
  },
};

export const SearchInput = React.forwardRef(
  (_, inputRef: React.Ref<HTMLInputElement | undefined>) => {
    const [value, setValue] = useState('');
    const debouncedValue = useDebounce(value.trim(), 150);

    const isQueryValid = (val: string) => val.trim().length >= 2;

    const { status, data, error } = useQuery(
      ['advertSearch', debouncedValue],
      async () => {
        const val = debouncedValue.trim();

        return advertApi.search(val);
      },
      {
        cacheTime: 0,
        staleTime: 0,
        retry: false,
        enabled: isQueryValid(debouncedValue),
        onSuccess: (data) => {
          analytics.dashboardAdvertSearch(debouncedValue, data.adverts.length);
        },
      }
    );

    useLogError(error);

    const {
      isOpen,
      highlightedIndex,
      getComboboxProps,
      getInputProps,
      getItemProps,
      getMenuProps,
    } = useCombobox({
      items: data?.adverts ?? [],
      inputValue: value,
      onInputValueChange: ({ inputValue }) => {
        setValue(inputValue || '');
      },
      onSelectedItemChange: ({ selectedItem }) => {
        if (!selectedItem) {
          return;
        }

        navigate(`/advert/${selectedItem.id}`);
      },
    });

    const isMenuOpen = Boolean(isOpen && isQueryValid(value));

    return (
      <ThemeProvider theme={theme as TSThemeFixMe}>
        <Box {...getComboboxProps()} sx={{ position: 'relative', letterSpacing: '-0.01rem' }}>
          <TextField
            {...getInputProps({
              ref: inputRef as React.Ref<HTMLInputElement>,
              placeholder: 'Search all adverts',
            })}
          />

          {status === 'loading' ? (
            <Box
              variant="forms.select.menu"
              sx={{ padding: 3, fontWeight: 400, color: 'blackfade50', cursor: 'default' }}
            >
              Fetching adverts for "{debouncedValue}"
            </Box>
          ) : status === 'error' ? (
            <Box
              variant="forms.select.menu"
              sx={{ padding: 3, fontWeight: 400, color: 'blackfade50', cursor: 'default' }}
            >
              Something went wrong whilst fetching adverts for "{debouncedValue}"
            </Box>
          ) : status === 'success' && (!data || data.adverts.length <= 0) ? (
            <Box
              variant="forms.select.menu"
              sx={{ padding: 3, fontWeight: 400, color: 'blackfade50', cursor: 'default' }}
            >
              No adverts found for "{debouncedValue}"
            </Box>
          ) : null}

          <SelectMenu
            {...getMenuProps()}
            sx={{
              boxShadow: isMenuOpen && status === 'success' ? 'selectMenu' : undefined,
            }}
          >
            {status === 'success' ? (
              <Fragment>
                {isMenuOpen
                  ? data?.adverts.map((item, index) => (
                      <SelectMenuItem
                        sx={{
                          padding: 3,
                          fontWeight: 400,
                          color: 'blackfade85',
                          ...(highlightedIndex === index ? { backgroundColor: '#eeeeee' } : {}),
                        }}
                        {...getItemProps({
                          key: index,
                          item,
                          index,
                        })}
                      >
                        <Text sx={{ color: 'accent', fontWeight: 500 }}>{item.jobTitle}</Text>
                        <Text as="span" sx={{ fontWeight: 500 }}>
                          {item.createdByName}, {item.createdDate}
                        </Text>
                      </SelectMenuItem>
                    ))
                  : null}
              </Fragment>
            ) : null}
          </SelectMenu>
        </Box>
      </ThemeProvider>
    );
  }
);
