import styled from '@emotion/styled/macro';
import { useState } from 'react';
import * as React from 'react';

import { Box } from '../../../components/common/Box';
import { Flex } from '../../../components/common/Flex';
import { Label } from '../../../components/common/form';
import { Grid } from '../../../components/common/Grid';
import {
  Modal,
  ModalActions,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTextButton,
  ModalTitle,
} from '../../../components/common/Modal';
import { Text } from '../../../components/common/Text';
import { useUserPreferences } from '../../../context/UserPreferencesContext';

export interface AdvertFormProps {
  onSubmit: React.FormEventHandler<HTMLFormElement>;
  onBlur: React.FormEventHandler<HTMLFormElement>;
  onChange: React.FormEventHandler<HTMLFormElement>;
}

const Form = styled.form`
  font-size: 1.125rem;
`;

export const AdvertForm: React.FC<AdvertFormProps> = (props) => (
  <Form autoComplete='off' onSubmit={props.onSubmit} onBlur={props.onBlur} onChange={props.onChange}>
    {props.children}
  </Form>
);

export const AdvertFormQuestionsContainer: React.FC<{ outerSx?: any; innerSx?: any }> = (props) => (
  <Flex sx={{ ...props.outerSx }}>
    <Grid
      sx={{
        width: '100%',
        display: 'grid',
        gridTemplateColumns: '50% 50%',
        gridGap: '5px',
        pb: '1.5rem',
        ...props.innerSx,
      }}
    >
      {props.children}
    </Grid>
  </Flex>
);

interface AdvertOverwriteWarningModalProps {
  isOpen: boolean;
  onConfirm: (options: { dontShowAgain: boolean }) => void;
  onCancel: (options: { dontShowAgain: boolean }) => void;
}

const AdvertOverwriteWarningModal: React.FC<AdvertOverwriteWarningModalProps> = (props) => {
  const [dontShowAgain, setDontShowAgain] = useState(false);

  return (
    <Modal
      aria-label='Overwrite variations confirmation'
      data-testid='confirm-advert-overwrite'
      variant='destructive'
      isOpen={props.isOpen}
      onDismiss={() => props.onCancel({ dontShowAgain })}
    >
      <ModalClose type='button' onClick={() => props.onCancel({ dontShowAgain })} />
      <ModalContainer>
        <ModalTitle sx={{ pb: 4 }}>Save</ModalTitle>

        <ModalContent sx={{ pb: 0 }}>
          <Text sx={{ mb: 5, pb: 2, color: '#e8e8e8' }}>
            Are you sure? If you proceed you will overwrite your old adverts and any manual amends
            you previously made.
          </Text>
        </ModalContent>

        <ModalActions>
          <ModalButton mr={3} onClick={() => props.onConfirm({ dontShowAgain })}>
            Yes I'm sure
          </ModalButton>

          <ModalTextButton type='button' onClick={() => props.onCancel({ dontShowAgain })}>
            Cancel
          </ModalTextButton>
        </ModalActions>

        <Box mt='5'>
          <Label sx={{ color: 'white', fontSize: 2 }}>
            <input
              type='checkbox'
              value={dontShowAgain as any}
              onChange={() => setDontShowAgain(!dontShowAgain)}
            />
            Don't show me again
          </Label>
        </Box>
      </ModalContainer>
    </Modal>
  );
};

interface AdvertFormButtonsProps {
  isFormComplete: boolean;
  isSubmitting: boolean;
  hasErrors: boolean;
  onSubmit: (shouldRedirect: boolean) => void;
}

export const AdvertFormButtons: React.FC<AdvertFormButtonsProps> = (props) => {
  const [userPreferences, updateUserPreferences] = useUserPreferences();
  const [showOverwriteAdvertWarning, setShowOverwriteAdvertWarning] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = (e: any) => {
    e.preventDefault();

    if (props.hasErrors) {
      // Submit even though we have errors to force the errors to show
      props.onSubmit(true);

      return;
    }

    if (userPreferences.showOverwriteAdvertWarning) {
      setShowOverwriteAdvertWarning(true);
      return;
    }

    props.onSubmit(true);
  };

  return (
    <>
      <AdvertOverwriteWarningModal
        isOpen={showOverwriteAdvertWarning}
        onConfirm={(options: { dontShowAgain: boolean }) => {
          setShowOverwriteAdvertWarning(false);
          props.onSubmit(true);
          updateUserPreferences({
            ...userPreferences,
            showOverwriteAdvertWarning: !options.dontShowAgain,
          });
        }}
        onCancel={(options: { dontShowAgain: boolean }) => {
          setShowOverwriteAdvertWarning(false);
          updateUserPreferences({
            ...userPreferences,
            showOverwriteAdvertWarning: !options.dontShowAgain,
          });
        }}
      />
    </>
  );
};
