import styled from '@emotion/styled/macro';
import {navigate} from "@reach/router";
import * as React from 'react';

import { Button } from '../../components/common/Button';
import { Flex } from '../../components/common/Flex';
import noAdvertsImageSrc from '../../theme/images/no-adverts.png';
import { useAdvertTableMachineActions } from './AdvertTableMachineContext';

export const EmptyListContainer = styled(Flex)`
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const EmptyListTitle = styled.h2`
  font-size: 2rem;
  margin: 0;
  letter-spacing: -0.05rem;
`;

const StyledImage = styled.img`
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

export const EmptyListImage = () => (
  <StyledImage src={noAdvertsImageSrc} alt="Empty list" height="350" width="325" />
);

export const EmptyListButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  padding: 20px 65px;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 700;
  &:hover,
  :focus {
    background-color: #4c9404;
  }
  &:disabled {
    background: #8ea287;
  }
`;

export const EmptyListCreateNewAdvert: React.FC = () => {
  return (
    <React.Fragment>
      <EmptyListButton
        data-testid="create-new-advert-toggle"
        variant="primaryInverted"
        onClick={() => navigate(`/advert/new`)}
      >
        Grade a New Advert +
      </EmptyListButton>

    </React.Fragment>
  );
};

export const EmptyListFiltersActiveView = () => {
  const actions = useAdvertTableMachineActions();

  return (
    <EmptyListContainer>
      <EmptyListTitle>No adverts were found for the provided filters.</EmptyListTitle>
      <EmptyListImage />
      <EmptyListButton
        data-testid="clear-all-filters"
        variant="primaryInverted"
        onClick={actions.clearAllFilters}
      >
        Clear filters
      </EmptyListButton>
    </EmptyListContainer>
  );
};
