import { useService } from '@xstate/react';
import { useCallback } from 'react';
import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import ReactPaginate from 'react-paginate';
import { useTable } from 'react-table';

import { ColouredTooltip } from '../../components/ColouredTooltip';
import { Box } from '../../components/common/Box';
import { Flex } from '../../components/common/Flex';
import { Menu } from '../../components/common/Menu';
import { Table, TableBody, TableCell, TableHead, TableRow } from '../../components/common/Table';
import { Text } from '../../components/common/Text';
import { ChevronUp } from '../../components/Icon';
import { RuntimeError } from '../../Error/BaseErrors';
import { ThemeProvider } from '../../theme/ThemeProvider';
import { useAdvertTableMachine } from './AdvertTableMachineContext';
import { AdvertTableMachineService } from './machines/advertTableMachine';

interface DashboardTableProps {
  service: AdvertTableMachineService;
  ListEmptyComponent: any;
}

export const DashboardTable = React.memo<DashboardTableProps>(({ service, ListEmptyComponent }) => {
  const [state] = useService(service);

  const showTable =
    state.matches('fetchingInitialData') ||
    state.matches('fetchingAdverts') ||
    state.matches({ ready: 'withData' });

  switch (true) {
    case state.matches({ ready: 'withoutData' }):
      return (
        <Container>
          <ListEmptyComponent />
        </Container>
      );
    case showTable:
      return <DashboardTableImpl service={service} />;
    default:
      console.debug('Handle me', state.value);
      throw new RuntimeError(
        `Something went wrong whilst loading the dashboard.`,
        `Attempted to a render a state that isn't handled`
      );
  }
});

interface DashboardTableImplProps {
  service: AdvertTableMachineService;
}

const DashboardTableImpl: React.FC<DashboardTableImplProps> = ({ service }) => {
  const [state, send] = useService(service);

  const { columns, adverts, pagination } = state.context;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns: columns,
    data: adverts,
  });

  const goToPage = useCallback(
    (page) => {
      send({
        type: 'PAGINATION.GO_TO_PAGE',
        data: { page: page + 1 },
      });
    },
    [send]
  );

  const updatePerPage = useCallback(
    (perPage) => {
      send({
        type: 'PAGINATION.UPDATE_PER_PAGE',
        data: { perPage },
      });
    },
    [send]
  );

  const isLoading = state.matches('fetchingInitialData') || state.matches('fetchingAdverts');

  return (
    <Container>
      <React.Fragment>
        <Table
          {...getTableProps()}
          loading={isLoading}
          loadingRows={10}
          loadingColumns={columns.length}
          data-testid="adverts"
        >
          <TableHead>
            {headerGroups.map((headerGroup: any) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column: any) => {
                  return (
                    <TableCell {...column.getHeaderProps()} sx={{ width: column.width }}>
                      {column.render('Header')}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row: any) => {
              prepareRow(row);

              return (
                <TableRow {...row.getRowProps({ 'data-testid': `advert-row-${row.original.id}` })}>
                  {row.cells.map((cell: any) => {
                    return <TableCell {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>;
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>

        <Pagination
          pageIndex={pagination.page - 1}
          pageSize={pagination.perPage}
          pageCount={pagination.totalPages}
          goToPage={goToPage}
          setPageSize={updatePerPage}
        />
      </React.Fragment>
    </Container>
  );
};

const Container: React.FC<any> = ({ sx, ...props }) => {
  return (
    <Box
      sx={{
        bg: '#f6f6f6',
        pt: '2.5rem',
        pr: ['10px', null, null, '15px', null, '30px', null, '56px'],
        pb: '4rem',
        pl: ['20px', null, null, '25px', null, '30px', null, '56px'],
        table: {
          letterSpacing: '-0.02rem',
          borderSpacing: '0 8px',
          mb: '5',
        },
        'thead th': {
          border: 'none',
          outline: 'none',
          bg: 'transparent',
          p: 0,
          fontWeight: 400,
          fontSize: '12px',
          transition: '150ms ease-in-out',
        },
        'thead th > div': {
          ml: '7px',
        },
        tbody: {
          bg: '#ffffff',
        },
        'tbody > tr': {
          transition: 'all 200ms ease-in-out',
        },
        'tbody > tr:hover': {
          backgroundColor: 'rgb(255 231 240 / 75%)',
        },
        'tr td': {
          borderTop: '1px solid #cccccc',
          borderBottom: '1px solid #cccccc',
          borderRight: 'none',
          fontSize: '0.9rem',
          padding: '22px 22px 22px 7px',
        },
        'tr td:first-of-type': {
          borderLeft: '1px solid #cccccc',
          pr: '1.5rem',
          pl: '1.5rem',
        },
        'tr td:last-child': {
          borderRight: '1px solid #cccccc',
          pr: '1.5rem',
        },
        '[data-reach-tooltip-trigger]': {
          display: 'inline',
        },
        '.DashboardAdvertTable-Pagination li': {
          display: 'inline-block',
          p: '0 1rem',
          m: 0,
        },
        '.DashboardAdvertTable-Pagination li a': {
          cursor: 'pointer',
        },
        '.DashboardAdvertTable-Pagination .previous a, .DashboardAdvertTable-Pagination .next a': {
          display: 'block',
          backgroundColor: '#eeeeee',
          width: '100px',
          textAlign: 'center',
          p: '0.5rem 0',
          fontSize: '0.9rem',
          transition: 'all 200ms ease-in-out',
        },
        '.DashboardAdvertTable-Pagination .previous a:hover, .DashboardAdvertTable-Pagination .previous a:focus, .DashboardAdvertTable-Pagination .next a:hover, .DashboardAdvertTable-Pagination .next a:focus': {
          backgroundColor: '#c1def1',
        },
        '.DashboardAdvertTable-Pagination .selected': {
          fontWeight: 700,
        },
        ...sx,
      }}
      {...props}
    />
  );
};

const dashboardAdvertTablePaginationTheme = {
  forms: {
    select: {
      button: {
        bg: 'white',
        border: '1px solid #cccccc',
      },
    },
  },
};

interface PaginationProps {
  pageIndex: number;
  pageCount: number;
  pageSize: number;
  goToPage: (page: number) => void;
  setPageSize: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = (props) => {
  return (
    <ThemeProvider theme={dashboardAdvertTablePaginationTheme as TSThemeFixMe}>
      <Flex sx={{ justifyContent: 'center', alignItems: 'center' }} data-testid="pagination">
        <ReactPaginate
          forcePage={props.pageIndex}
          pageCount={props.pageCount}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          containerClassName="DashboardAdvertTable-Pagination"
          onPageChange={(data: { selected: number }) => props.goToPage(data.selected)}
        />

        <div>
          <Text sx={{ fontSize: 1 }}>
            Show adverts:{' '}
            <Menu
              id="per-page"
              options={[10, 25, 50, 100, 500, 1000].map((amount) => ({
                label: amount.toString(),
                value: amount,
              }))}
              renderOption={(option: any) => (
                <FormattedNumber
                  value={
                    typeof option.value === 'string' ? parseInt(option.value, 10) : option.value
                  }
                />
              )}
              value={props.pageSize}
              onChange={(newValue: number | string) => {
                const value = typeof newValue === 'string' ? parseInt(newValue, 10) : newValue;
                props.setPageSize(value);
              }}
            />{' '}
            per page
          </Text>
        </div>
      </Flex>
    </ThemeProvider>
  );
};

interface InlineFilterableProps {
  helperText: string;
  onClick: () => void;
}

export const InlineFilterable: React.FC<InlineFilterableProps> = ({
  children,
  helperText,
  onClick,
}) => {
  return (
    <ColouredTooltip label={helperText} sx={{ bg: 'black' }}>
      <Box
        as="button"
        sx={{
          display: 'inline',
          border: 'none',
          borderBottom: '2px solid',
          borderBottomColor: 'transparent',
          bg: 'unset',
          p: 0,
          mt: '0.3rem',
          fontSize: '0.8125rem',
          color: '#464646',
          cursor: 'pointer',
          transition: 'all 200ms ease-in-out',
          textTransform: 'capitalize',
          '&:hover': {
            borderBottomColor: 'accent',
            color: 'accent',
          },
        }}
        onClick={onClick}
      >
        {children}
      </Box>
    </ColouredTooltip>
  );
};

interface FilterableProps {
  helperText: string;
  onClick: () => void;
}

export const Filterable: React.FC<FilterableProps> = ({ children, helperText, onClick }) => {
  return (
    <ColouredTooltip label={helperText} sx={{ bg: 'black' }}>
      <Text
        sx={{
          display: 'inline',
          m: 0,
          fontWeight: 500,
          color: 'black',
          textDecoration: 'none',
          cursor: 'pointer',
          borderBottom: '2px solid',
          borderBottomColor: 'transparent',
          '&:hover, &:focus': {
            borderBottomColor: 'black',
          },
        }}
        onClick={onClick}
      >
        {children}
      </Text>
    </ColouredTooltip>
  );
};

interface SortableHeaderProps {
  sortName: string;
}

export const SortableHeader: React.FC<SortableHeaderProps> = ({ children, sortName }) => {
  const [{ isSorted, isSortedDescending }, { setSortBy }] = useAdvertTableMachine();

  const sorted = isSorted(sortName);

  return (
    <Flex
      sx={{
        alignItems: 'center',
        cursor: 'pointer',
        '.chevron-wrapper': {
          opacity: sorted ? 1 : 0,
          transform: isSortedDescending(sortName) ? 'rotate(180deg)' : 'none',
          ml: sorted ? 0 : '-8px',
          color: sorted ? 'text' : 'textDim',
          lineHeight: 0,
          transition: 'all 150ms ease-in',
        },
        '&:hover': {
          color: 'accent',
          '.chevron-wrapper': {
            opacity: 1,
            ml: 0,
          },
        },
      }}
      onClick={() => {
        setSortBy(sortName);
      }}
    >
      <Flex sx={{ flexDirection: 'column' }}>{children}</Flex>

      <Flex sx={{ flexDirection: 'column', ml: 2 }}>
        <Text className="chevron-wrapper">
          <ChevronUp />
        </Text>
      </Flex>
    </Flex>
  );
};

export const ActiveFilterPillList: React.FC = ({ children }) => {
  return <Box sx={{ display: 'flex' }}>{children}</Box>;
};

interface ActiveFilterPillProps {
  borderColor?: string;
  onClear: () => void;
}

export const ActiveFilterPill: React.FC<ActiveFilterPillProps> = ({
  children,
  borderColor = '#000000',
  onClear,
}) => {
  return (
    <Box
      sx={{
        position: 'relative',
        fontSize: '12px',
        px: '9px',
        py: '3px',
        bg: 'white',
        mb: 0,
        border: `2px solid ${borderColor}`,
        borderRadius: '4px',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          right: '-6px',
          top: '-6px',
          cursor: 'pointer',
        }}
        onClick={onClear}
      >
        <Box
          sx={{
            height: '15px',
            width: '15px',
            color: 'white',
            bg: 'black',
            borderRadius: '50%',
            justifyContent: 'center',
            alignItems: 'center',
            transition: 'all 200ms ease-in-out',
            '&:hover': {
              bg: 'red.5',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              m: '0 !important',
              height: '100%',
              width: '100%',
            }}
          >
            <ActiveFilterPillClearButton />
          </Box>
        </Box>
      </Box>
      {children}
    </Box>
  );
};

const ActiveFilterPillClearButton = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 36 36"
      fill="none"
      stroke="#ffffff"
      strokeWidth="8"
      strokeMiterlimit="2.6131"
      height="7px"
      width="7px"
    >
      <path d="M1.41 35.41l34-34" />
      <path d="M35.41 35.41l-34-34" />
    </svg>
  );
};
