import { Redirect, RouteComponentProps, Router } from '@reach/router';

import { ErrorBoundary } from './components/ErrorBoundary';
import { ZendeskIdentitySync } from './components/ZendeskIdentitySync';
import { CompanyProvider } from './context/CompanyContext';
import { UserProvider, useUser } from './context/UserContext';
import { RuntimeError } from './Error/BaseErrors';
import { SetupCompanyAccount } from './screens/Account/SetupCompanyAccount';
import { SetupCreateSubscription } from './screens/Account/SetupCreateSubscription';
import { SetupInviteTeamMembers } from './screens/Account/SetupInviteTeamMembers';
import { Logout } from './screens/Auth/Logout';

export const AccountSetupApp = () => {
  return (
    <ErrorBoundary>
      <UserProvider>
        <CompanyProvider>
          <ZendeskIdentitySync />

          <Router primary={false} style={{ height: '100%' }}>
            <Logout path="/logout" />

            <SetupCompanyAccount path="/account-setup/company" />
            <SetupCreateSubscription path="/account-setup/subscription" />
            <SetupInviteTeamMembers path="/account-setup/invite-team" />

            <NotFound default />
          </Router>
        </CompanyProvider>
      </UserProvider>
    </ErrorBoundary>
  );
};

const NotFound: React.FC<RouteComponentProps> = () => {
  const user = useUser();

  let redirectUrl =
    user.registrationStatus === 'awaiting_company'
      ? '/account-setup/company'
      : user.registrationStatus === 'awaiting_subscription'
      ? '/account-setup/subscription'
      : null;

  if (!redirectUrl) {
    throw new RuntimeError(
      'Something went wrong whilst setting up the account',
      `Unsure where to redirect the user. [status: ${user.registrationStatus}]`
    );
  }

  return <Redirect path="/" from="/" to={redirectUrl} noThrow />;
};
