import styled from '@emotion/styled/macro';

import { Flex } from '../../components/common/Flex';

export const SplitPageContainer = styled.div`
  display: flex;
  position: relative;
  min-height: 100vh;
  background-color: #f2f2f2;
`;

export const LeftContainer = styled(Flex)`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media (min-width: 900px) {
    width: 45%;
  }
`;

export const RightContainer = styled(Flex)`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(to right bottom, rgb(32, 51, 51), #FF2779) 0% 0% / 250%;

  @media (min-width: 900px) {
    display: flex;
    width: 55%;
  }

  @media (min-width: 1500px) {
    flex-direction: row;
  }
`;
