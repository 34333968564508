import Component from '@reach/component-component';
import { ErrorMessage, Form as FormikForm, Formik } from 'formik';
import { useEffect, useRef } from 'react';
import * as React from 'react';
import * as Yup from 'yup';

import {
  BooleanType,
  BooleanTypeOption,
  booleanTypes,
  getBooleanTypeByValue,
} from '../../api/advert';
import {
  AuthorityLevel,
  AuthorityLevelOption,
  AuthorityLevelOptions,
  getAuthorityLevelOption,
} from '../../api/auth';
import { teamApi } from '../../api/team';
import { RuntimeError, UserFacingError } from '../../Error/BaseErrors';
import { authTheme } from '../../theme/authTheme';
import { ThemeProvider } from '../../theme/ThemeProvider';
import { ApiFormError } from '../../utils/errors/ApiFormError';
import {
  EmailSchema,
  FirstNameSchema,
  LastNameSchema,
} from '../../utils/form-validation/user-details';
import logger from '../../utils/logger';
import { BasicSelect } from '../common/BasicSelect';
import { Box } from '../common/Box';
import { Button } from '../common/Button';
import { Flex } from '../common/Flex';
import { ErrorMessage as ThemedErrorMessage, Label, TextField } from '../common/form';
import { Grid } from '../common/Grid';
import {
  Modal,
  ModalButton,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTextButton,
  ModalTitle,
} from '../common/Modal';
import { Text } from '../common/Text';

export const NEW_TEAM = {
  id: -1,
  name: '+ Create new team',
};

interface UserDetailsModalProps {
  isOpen: boolean;
  title: string;
  initialValues?: {
    firstName: string;
    lastName: string;
    email: string;
    authorityLevel: AuthorityLevelOption;
    isTeamOwner: BooleanTypeOption;
    team?: number;
  };
  confirmationTitle: React.ReactNode;
  cancelable?: boolean;
  formMode: FormMode;
  onSubmit: (values: any, formikActions: any) => Promise<void>;
  onClose: () => void;
}

export enum FormMode {
  Create,
  Edit,
}

const userDetailsModalTheme = {
  ...authTheme,
  forms: {
    label: {
      paddingBottom: '5px',
      fontSize: '14px',
      fontWeight: '600',
    },
    select: {
      menu: {
        position: 'absolute',
        top: 'calc(100% + 0.1rem)',
        maxHeight: '220px',
        width: '100%',
        left: 0,
        zIndex: 2,
      },
    },
  },
};

const validationSchema = Yup.object().shape({
  firstName: FirstNameSchema,
  lastName: LastNameSchema,
  email: EmailSchema,
  team: Yup.number(),
  newTeamName: Yup.string()
    .nullable()
    .when('team', {
      is: (val: number) => val === NEW_TEAM.id,
      then: Yup.string().required('Team name is required'),
    }),
});

export const UserDetailsModal: React.FC<UserDetailsModalProps> = ({
  isOpen,
  title,
  initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    authorityLevel: getAuthorityLevelOption(AuthorityLevel.User),
    isTeamOwner: getBooleanTypeByValue(BooleanType.No),
    team: undefined,
  },
  confirmationTitle,
  cancelable = true,
  formMode,
  onSubmit,
  onClose,
}) => {
  const {
    status: fetchTeamsStatus,
    data: fetchTeamsData,
    refetch: refetchTeams,
  } = teamApi.useTeamsQuery();

  const newTeamNameRef = useRef<any>();

  return (
    <ThemeProvider theme={userDetailsModalTheme as TSThemeFixMe}>
      <Modal aria-label="User details" isOpen={isOpen} onDismiss={() => onClose()}>
        {cancelable && <ModalClose onClick={() => onClose()} />}

        <ModalContainer>
          <ModalTitle>{title}</ModalTitle>
          <ModalContent sx={{ pb: 0 }}>
            <Formik
              initialValues={{ ...initialValues, newTeamName: '' }}
              validationSchema={validationSchema}
              onSubmit={async (values, actions) => {
                const { newTeamName, ...formValues } = values;

                if (values.team === NEW_TEAM.id) {
                  // First create the new team. We could offload this to the UserDetailsModal consumer but for now we'll do it here.
                  try {
                    const result = await teamApi.createTeam({ name: newTeamName });
                    formValues.team = result.team.id;
                  } catch (e) {
                    if (e instanceof ApiFormError) {
                      actions.setErrors({
                        newTeamName: e.errors['name'],
                      });

                      return;
                    }

                    logger.logError(e);
                    actions.setErrors({
                      newTeamName: 'Something went wrong whilst creating the new Team',
                    });

                    return;
                  }
                }

                await onSubmit(formValues, actions);

                refetchTeams();
              }}
            >
              {({
                isSubmitting,
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                setFieldTouched,
                setFieldValue,
              }) => {
                if (!values.authorityLevel) {
                  throw new UserFacingError(
                    'Something went wrong whilst loading the user authority level'
                  );
                }

                return (
                  <Form sx={{ maxWidth: '650px', width: '100%' }} onSubmit={handleSubmit}>
                    <Grid sx={{ gridTemplateColumns: 'auto auto', columnGap: 5, pb: 5 }}>
                      <Box>
                        <Label>First Name*</Label>
                        <TextField
                          type="text"
                          name="firstName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.firstName}
                        />
                        <ErrorMessage name="firstName" component={ThemedErrorMessage} />
                      </Box>
                      <Box>
                        <Label>Last Name*</Label>
                        <TextField
                          type="text"
                          name="lastName"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.lastName}
                        />
                        <ErrorMessage name="lastName" component={ThemedErrorMessage} />
                      </Box>
                    </Grid>

                    <Flex sx={{ flex: 1, flexDirection: 'column', pb: 5 }}>
                      <Label>Their Work Email Address*</Label>
                      <TextField
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                        disabled={formMode === FormMode.Edit}
                      />
                      <ErrorMessage name="email" component={ThemedErrorMessage} />
                    </Flex>

                    <Flex sx={{ flex: 1, flexDirection: 'column', pb: 5 }}>
                      <Label>Authority Level</Label>

                      <BasicSelect
                        name="authorityLevel"
                        items={AuthorityLevelOptions}
                        selectedItem={values.authorityLevel}
                        renderButtonText={(item) => item.label}
                        baseSx={{ width: '100%', border: '2px solid #E1E1E1 !important', borderRadius: '4px' }}
                        buttonSx={{fontSize: '16px'}}
                        renderItem={(item) => item.label}
                        onChange={(item) => {
                          setFieldValue('authorityLevel', item);
                          setFieldTouched('authorityLevel', true);
                        }}
                      />

                      <ErrorMessage name="authorityLevel" component={ThemedErrorMessage} />
                    </Flex>

                    <Flex sx={{ flex: 1, flexDirection: 'column', pb: 5 }}>
                      <Label>Is a Team Owner</Label>

                      <BasicSelect
                        name="isTeamOwner"
                        items={booleanTypes}
                        selectedItem={values.isTeamOwner}
                        renderButtonText={(item) => item.label}
                        baseSx={{ width: '100%', border: '2px solid #E1E1E1 !important', borderRadius: '4px' }}
                        buttonSx={{fontSize: '16px'}}
                        renderItem={(item) => item.label}
                        onChange={(item) => {
                          setFieldValue('isTeamOwner', item);
                          setFieldTouched('isTeamOwner');
                        }}
                      />

                      <ErrorMessage name="isTeamOwner" component={ThemedErrorMessage} />
                    </Flex>

                    <Flex sx={{ flex: 1, flexDirection: 'column', pb: 5 }}>
                      <Label>
                        Team
                        {values.team === NEW_TEAM.id && (
                          <Button
                            variant="text"
                            sx={{ ml: 2 }}
                            onClick={() => {
                              setFieldValue('team', initialValues.team);
                              setFieldValue('newTeamName', '');
                              setFieldTouched('team');
                            }}
                          >
                            Show all teams
                          </Button>
                        )}
                      </Label>

                      {fetchTeamsStatus === 'loading' ? (
                        <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
                          <Text>Fetching teams...</Text>
                        </Flex>
                      ) : fetchTeamsStatus === 'error' || !fetchTeamsData ? (
                        <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
                          <Text>Encountered an issue whilst fetching the teams.</Text>
                        </Flex>
                      ) : (
                        <React.Fragment>
                          {values.team === NEW_TEAM.id ? (
                            <TextField
                              type="text"
                              name="newTeamName"
                              ref={newTeamNameRef}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.newTeamName}
                            />
                          ) : (
                            <TeamSelect
                              teams={fetchTeamsData.teams}
                              selected={values.team}
                              onChange={(team) => {
                                setFieldValue('team', team.id);
                                setFieldTouched('team');
                              }}
                            />
                          )}
                        </React.Fragment>
                      )}

                      <ErrorMessage name="team" component={ThemedErrorMessage} />
                      <ErrorMessage name="newTeamName" component={ThemedErrorMessage} />
                    </Flex>

                    <Flex sx={{ pb: 0 }}>
                      <ModalButton type="submit" mr={2} disabled={isSubmitting}>
                        {confirmationTitle}
                      </ModalButton>

                      {cancelable ? (
                        <ModalTextButton type="button" onClick={onClose}>
                          Cancel
                        </ModalTextButton>
                      ) : null}
                    </Flex>

                    <Component
                      team={values.team}
                      didUpdate={({ prevProps, props }: any) => {
                        if (prevProps.team !== props.team && props.team === NEW_TEAM.id) {
                          if (newTeamNameRef.current) {
                            newTeamNameRef.current.focus();
                          }
                        }
                      }}
                    />
                  </Form>
                );
              }}
            </Formik>
          </ModalContent>
        </ModalContainer>
      </Modal>
    </ThemeProvider>
  );
};

const Form: React.FC<any> = (props) => {
  return <Box as={FormikForm} {...props} />;
};

type Team = { id: number; name: string };

interface TeamSelectProps {
  teams: Team[];
  selected?: number;
  onChange: (team: Team) => void;
}

const TeamSelect: React.FC<TeamSelectProps> = ({ teams, selected, onChange }) => {
  const hasSelectedDefault = useRef(false);

  useEffect(() => {
    if (!selected && !hasSelectedDefault.current) {
      onChange(teams[0]);
      hasSelectedDefault.current = true;
    }
  }, [selected, teams, onChange]);

  if (!selected) {
    return (
      <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
        <Text>Fetching teams...</Text>
      </Flex>
    );
  }

  const team = teams.find((team) => team.id === selected);

  if (!team) {
    const teamIds = teams.map((t) => t.id);

    throw new RuntimeError(
      "Could not find the user's team",
      `Team id ${selected} could not be matched to any of the teamIds ${teamIds}`
    );
  }

  return (
    <BasicSelect
      name="teams"
      items={[NEW_TEAM, ...teams]}
      selectedItem={team}
      renderButtonText={(item) => item.name}
      baseSx={{ width: '100%', border: '2px solid #E1E1E1 !important', borderRadius: '4px' }}
      buttonSx={{fontSize: '16px'}}
      renderItem={(item) => item.name}
      onChange={onChange}
    />
  );
};
