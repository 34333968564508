import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { AppHeader } from '../../components/AppHeader';
import { AppShell } from '../../components/AppShell';
import { Box } from '../../components/common/Box';
import { ErrorBoundary } from '../../components/ErrorBoundary';

export const AnalyticsScreen: React.FC<RouteComponentProps> = (props) => (
  <AppShell backgroundColor="#f6f6f6">
    <Helmet title="Analytics" />
    <Box pb={4}>
      <Box sx={{ '@media screen and (max-width: 650px)': { display: 'none' } }}>
        <AppHeader title="Analytics" />
      </Box>

      <ErrorBoundary>{props.children}</ErrorBoundary>
    </Box>
  </AppShell>
);
