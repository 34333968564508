import { useFormikContext } from 'formik';
import { useEffect } from 'react';

export const FormAwareAutoScrollToError = () => {
  const { isSubmitting, isValidating, errors } = useFormikContext();

  useEffect(() => {
    const keys = Object.keys(errors);

    if (keys.length > 0 && isSubmitting && !isValidating) {
      let errorElement = document.querySelector(`[name="${keys[0]}"]`) as any;

      if (!errorElement) {
        // Could be a collection input
        errorElement = document.querySelector(`[name="${keys[0]}.0"]`) as any;
      }

      if (errorElement) {
        //errorElement.focus();
      }
    }
  }, [errors, isSubmitting, isValidating]);

  return null;
};
