import styled from '@emotion/styled/macro';
import { Redirect, RouteComponentProps } from '@reach/router';
import { ErrorMessage, Formik } from 'formik';
import qs from 'qs';
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Box } from '../../components/common/Box';
import { Button } from '../../components/common/Button';
import { Flex } from '../../components/common/Flex';
import { Label as BaseLabel, TextField } from '../../components/common/form';
import { Heading } from '../../components/common/Heading';
import { useAuth } from '../../context/AuthContext';
import { ApiFormError } from '../../utils/errors/ApiFormError';
import logger from '../../utils/logger';

const Label = styled(BaseLabel)`
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  padding-bottom: 10px;
`;

const ResetPasswordFormSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  token: Yup.string().required('Required'),
  password: Yup.string().min(8, 'Too short').required('Required'),
});

export const ResetPassword: React.FC<RouteComponentProps> = ({ location }) => {
  const { resetPassword } = useAuth();

  const queryParams = location ? qs.parse(location.search, { ignoreQueryPrefix: true }) : {};

  let { e: email, t: token } = queryParams;

  if (!email || !token || Array.isArray(email) || Array.isArray(token)) {
    return <Redirect path="/" from="/" to="/" noThrow />;
  }

  email = email.toString();
  token = token.toString();

  return (
    <React.Fragment>
      <Helmet title="Reset Password" />
      <Formik
        initialValues={{ email, token, password: '' }}
        initialStatus={{ passwordReset: false }}
        validationSchema={ResetPasswordFormSchema}
        onSubmit={async (values, { setSubmitting, setErrors, setStatus }) => {
          if (typeof values.email !== 'string') {
            setErrors({ email: 'Please provide a valid e-mail address' });
            return;
          }

          try {
            setSubmitting(true);
            const response = await resetPassword(values);
            setStatus({ passwordReset: response.passwordReset });

            if (!response.passwordReset) {
              setErrors({
                email:
                  'Something went wrong whilst attempting to reset the password for that account',
              });
              return;
            }

            toast.success('Your password has been reset!');
          } catch (e) {
            if (e instanceof ApiFormError) {
              setErrors(e.errors);
            } else {
              logger.logError(e);
              setErrors({
                email:
                  'Something went wrong whilst attempting to reset the password for that account',
              });
            }
          } finally {
            setSubmitting(false);
          }
        }}
      >
        {({ values, status, isSubmitting, handleSubmit, handleChange, handleBlur }) => (
          <Flex
            sx={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              minHeight: '100%',
              bg: 'header',
              px: 0,
              py: 7,
            }}
          >
            <Box
              sx={{
                bg: 'white',
                maxWidth: '600px',
                px: 8,
                py: 4,
                borderRadius: 2,
                border: 4,
                borderColor: 'grey.3',
              }}
            >
              <Heading as="h1" sx={{ fontSize: 8, fontWeight: 'bold', color: 'grey.9', mb: 4 }}>
                Reset Password
              </Heading>
              {status.passwordReset ? (
                <Redirect path="/" from="/" to="/" noThrow />
              ) : (
                <form onSubmit={handleSubmit}>
                  <Flex sx={{ pb: 5 }}>
                    <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                      <Label>Your email address:</Label>
                      <TextField
                        type="email"
                        name="email"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.email}
                      />
                      <ErrorMessage name="email" />
                    </Flex>
                  </Flex>

                  <Flex sx={{ pb: 5 }}>
                    <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                      <Label>Password:</Label>
                      <TextField
                        type="password"
                        name="password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                      />
                      <ErrorMessage name="password" />
                    </Flex>
                  </Flex>

                  <Flex sx={{ pb: 5 }}>
                    <Button
                      type="submit"
                      variant={isSubmitting ? 'disabled' : 'primaryInverted'}
                      disabled={isSubmitting}
                    >
                      Reset password
                    </Button>
                  </Flex>
                </form>
              )}
            </Box>
          </Flex>
        )}
      </Formik>
    </React.Fragment>
  );
};
