import { format } from 'date-fns';
import * as React from 'react';

import { ApiCompany } from '../../../api/auth';
import {
  ApiCompanyAnalyticsDetails,
  inclusivityWordTypes,
  readabilityWordTypes,
} from '../../../api/company-analytics';
import { Button } from '../../../components/common/Button';
import { Flex } from '../../../components/common/Flex';
import { Heading } from '../../../components/common/Heading';
import { Text } from '../../../components/common/Text';
import { arrayToCsv } from '../../../utils/arrayToCsv';
import { downloadFile } from '../../../utils/downloadFile';
import { DateRangeOption, DateRangePicker, StartAndEndDates } from './DateRangePicker';

interface IAnalyticsHeaderProps {
  company: ApiCompany;
  companyAnalytics?: ApiCompanyAnalyticsDetails;
  selectedDateRangeOption: DateRangeOption;
  setSelectedDateRangeOption: (option: DateRangeOption) => void;
  startAndEndDate: StartAndEndDates;
  disableExport?: boolean;
}

export const AnalyticsHeader: React.FC<IAnalyticsHeaderProps> = ({
  company,
  companyAnalytics,
  selectedDateRangeOption,
  setSelectedDateRangeOption,
  startAndEndDate,
  disableExport,
}) => {
  const exportAnalytics = () => {
    if (!companyAnalytics) return;

    const data = Object.entries({
      'Total ads': companyAnalytics?.AdCount,
      'Total ads with content': companyAnalytics?.AdWithContentCount,
      'Avg. inclusivity score': companyAnalytics?.StatsAverages.GradingInclusivityScore,
      'Avg. readability score': companyAnalytics?.StatsAverages.GradingReadabilityScore,
      'Total terms flagged (inclusion)': termsFlaggedInclusion,
      'Total terms flagged (readability)': termsFlaggedReadability,
      'Avg. word count': companyAnalytics?.StatsAverages.WordCount,
    }).map(([key, value]) => ({
      Statistic: key,
      Value: value,
    }));

    const df = (date: Date) => format(date, 'yyyyMMdd');
    const filenameDate = `${df(startAndEndDate.startDate)}-${df(startAndEndDate.endDate)}`;
    downloadFile(`analytics_${company.id}_${filenameDate}.csv`, arrayToCsv(data));
  };

  const termsFlaggedInclusion = companyAnalytics
    ? inclusivityWordTypes.map((wordType) => companyAnalytics?.GradedWords[wordType]).flat().length
    : undefined;

  const termsFlaggedReadability = companyAnalytics
    ? readabilityWordTypes.map((wordType) => companyAnalytics?.GradedWords[wordType]).flat().length
    : undefined;

  return (
    <Flex sx={{ alignItems: 'center', gap: 4, pt: 4, pb: 2 }}>
      <Heading
        as="h1"
        sx={{
          letterSpacing: '-0.05rem',
          fontSize: 6,
          fontWeight: 'semibold',
          marginRight: 'auto',
        }}
      >
        <Text as="span" sx={{ color: 'grey.9' }}>
          Statistics for{' '}
        </Text>
        <Text as="span" sx={{ color: 'accent', fontWeight: 'bold' }}>
          {company.companyName}
        </Text>
      </Heading>

      <DateRangePicker value={selectedDateRangeOption} onChange={setSelectedDateRangeOption} />

      <Button
        onClick={exportAnalytics}
        disabled={disableExport}
        variant={disableExport ? 'disabled' : 'primary'}
        title={
          disableExport ? 'No data available for export. Please alter the date range' : undefined
        }
      >
        Export
      </Button>
    </Flex>
  );
};
