import {
  endOfMonth,
  endOfYear,
  format,
  startOfMonth,
  startOfYear,
  subMonths,
  subYears,
} from 'date-fns';
import { Flex, ThemeProvider } from 'theme-ui';

import { BasicSelect } from '../../../components/common/BasicSelect';

export enum DateRange {
  PastYear,
  PastMonth,
  ThisYear,
  ThisMonth,
  AllTime,
}

export interface DateRangeOption {
  label: string;
  value: DateRange;
}

export const dateRangeOptions: DateRangeOption[] = [
  {
    label: 'Past Year',
    value: DateRange.PastYear,
  },
  {
    label: 'Past Month',
    value: DateRange.PastMonth,
  },
  {
    label: 'This Year',
    value: DateRange.ThisYear,
  },
  {
    label: 'This Month',
    value: DateRange.ThisMonth,
  },
  {
    label: 'All Time',
    value: DateRange.AllTime,
  },
];

export interface StartAndEndDates {
  startDate: Date;
  endDate: Date;
}

export const getStartAndEndDates = (dateRange: DateRange): StartAndEndDates => {
  let startDate = new Date();
  let endDate = new Date();

  if (dateRange === DateRange.PastYear) {
    startDate = subYears(new Date(), 1);
    endDate = new Date();
  } else if (dateRange === DateRange.PastMonth) {
    startDate = subMonths(new Date(), 1);
    endDate = new Date();
  } else if (dateRange === DateRange.ThisYear) {
    startDate = startOfYear(new Date());
    endDate = endOfYear(new Date());
  } else if (dateRange === DateRange.ThisMonth) {
    startDate = startOfMonth(new Date());
    endDate = endOfMonth(new Date());
  } else if (dateRange === DateRange.AllTime) {
    startDate = new Date(0);
    endDate = new Date();
  }

  startDate!.setHours(0, 0, 0, 0);
  endDate!.setHours(23, 59, 59, 999);

  return { startDate, endDate };
};

interface IDateRangePickerProps {
  value: DateRangeOption;
  onChange: (value: DateRangeOption) => void;
}

const formatStartAndEndDates = ({ startDate, endDate }: StartAndEndDates) => {
  const dateFormat = 'dd/MM/yyyy';
  return `${format(startDate, dateFormat)} – ${format(endDate, dateFormat)}`;
};

export const DateRangePicker: React.FC<IDateRangePickerProps> = ({ value, onChange }) => {
  const formatOption = (option: DateRangeOption) => (
    <Flex sx={{ gap: 4, py: 1 }}>
      {option.label}

      {option.value !== DateRange.AllTime && (
        <span style={{ opacity: 0.5, marginLeft: 'auto' }}>
          {formatStartAndEndDates(getStartAndEndDates(option.value))}
        </span>
      )}
    </Flex>
  );

  return (
    <ThemeProvider theme={dateRangePickerTheme}>
      <BasicSelect
        name="dateRange"
        items={dateRangeOptions}
        selectedItem={value}
        baseSx={{
          width: '360px',
          border: '1px solid #ccc',
          borderRadius: '4px',
        }}
        buttonSx={{ fontSize: '16px', background: 'white', height: '54px', alignItems: 'center' }}
        onChange={onChange}
        renderButtonText={formatOption}
        renderItem={formatOption}
      />
    </ThemeProvider>
  );
};

const dateRangePickerTheme: TSThemeFixMe = {
  forms: {
    select: {
      menu: {
        position: 'absolute',
        top: 'calc(100% + 0.1rem)',
        maxHeight: '220px',
        width: '100%',
        left: 0,
        zIndex: 2,
      },
    },
  },
};
