import * as React from 'react';

import { ApiCompanyAnalyticsDetails } from '../../../api/company-analytics';
import { Flex } from '../../../components/common/Flex';
import { Grid } from '../../../components/common/Grid';
import { Text } from '../../../components/common/Text';
import { GenderToneIndicator } from '../../Dashboard/AdvertTableColumnDefinitions';
import { GradedWordsTable } from './GradedWordsTable';
import { ScoreIndicator } from './ScoreIndicator';
import { StatisticRowSection } from './StatisticRow';
import { StatsBox } from './StatsBox';

interface IAnalyticsInclusivityProps {
  companyAnalytics: ApiCompanyAnalyticsDetails;
}

export const AnalyticsInclusivity: React.FC<IAnalyticsInclusivityProps> = ({
  companyAnalytics,
}) => {
  return (
    <StatisticRowSection title="Inclusivity">
      <Grid sx={{ gridTemplateColumns: '1fr 1fr', gap: 4 }}>
        <StatsBox sx={{ p: 3 }}>
          <Flex sx={{ alignItems: 'center', justifyContent: 'space-between', gap: 1 }}>
            <Text sx={{ fontSize: 3, fontWeight: 'semibold' }}>Avg. Inclusivity Score</Text>

            <ScoreIndicator
              score={companyAnalytics?.StatsAverages?.GradingInclusivityScore ?? null}
            />
          </Flex>
        </StatsBox>

        <StatsBox sx={{ p: 3 }}>
          <Flex
            sx={{
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: 1,
              height: '100%',
            }}
          >
            <Text sx={{ fontSize: 3, fontWeight: 'semibold' }}>Avg. Gender Tone</Text>

            {companyAnalytics ? (
              <GenderToneIndicator score={companyAnalytics.GenderResult.bias} sx={{ px: 2 }} />
            ) : null}
          </Flex>
        </StatsBox>

        <StatsBox sx={{ py: 4, px: 3 }}>
          {companyAnalytics ? (
            <GradedWordsTable
              average={companyAnalytics.GradedWordsAverages['masculine']}
              frequency={companyAnalytics.GradedWordsFrequencies['masculine']}
              color="#0088d0"
              textAvg="masculine coded"
              textMostCommon="masculine words"
            />
          ) : null}
        </StatsBox>

        <StatsBox sx={{ py: 4, px: 3 }}>
          {companyAnalytics ? (
            <GradedWordsTable
              average={companyAnalytics.GradedWordsAverages['feminine']}
              frequency={companyAnalytics.GradedWordsFrequencies['feminine']}
              color="#f30071"
              textAvg="feminine coded"
              textMostCommon="feminine words"
            />
          ) : null}
        </StatsBox>

        <StatsBox sx={{ py: 4, px: 3 }}>
          {companyAnalytics ? (
            <GradedWordsTable
              average={companyAnalytics.GradedWordsAverages['exclusionary']}
              frequency={companyAnalytics.GradedWordsFrequencies['exclusionary']}
              color="#9b5aa7"
              textAvg="exclusionary terms"
              textMostCommon="exclusionary terms"
            />
          ) : null}
        </StatsBox>

        <StatsBox sx={{ py: 4, px: 3 }}>
          {companyAnalytics ? (
            <GradedWordsTable
              average={companyAnalytics.GradedWordsAverages['blocking_inexperience']}
              frequency={companyAnalytics.GradedWordsFrequencies['blocking_inexperience']}
              color="#d66600"
              textAvg="inexperience terms"
              textMostCommon="inexperience terms"
            />
          ) : null}
        </StatsBox>
      </Grid>
    </StatisticRowSection>
  );
};
