// TODO - Think about extracting all of this config to the API
export const REGISTRATION_WIZARD_STEPS = ['Your details', 'Business details', 'Confirm'];

export const EMPLOYEE_COUNT_OPTIONS = [
  { id: 1, name: '1' },
  { id: 2, name: '2-5' },
  { id: 3, name: '6-20' },
  { id: 4, name: '21-50' },
  { id: 5, name: '51-200' },
  { id: 6, name: '201-500' },
  { id: 7, name: '500+' },
];

export enum ReferredFrom {
  SearchEngine = 1,
  Friend = 2,
  Network = 3,
  LinkedIn = 4,
  SocialMedia = 5,
  Blog = 6,
  Other = 7,
}

export const REFERRED_FROM_OPTIONS = [
  { id: ReferredFrom.SearchEngine, name: 'Search Engine (Google, Yahoo, etc.)' },
  { id: ReferredFrom.Friend, name: 'Recommended by a friend or colleague' },
  { id: ReferredFrom.Network, name: 'Network or group' },
  { id: ReferredFrom.LinkedIn, name: 'LinkedIn' },
  { id: ReferredFrom.SocialMedia, name: 'Other social media (Facebook, Twitter, etc.)' },
  { id: ReferredFrom.Blog, name: 'Blog or publication' },
  { id: ReferredFrom.Other, name: 'Other (please specify in the next question)' },
];