import styled from '@emotion/styled/macro';
import { Link as RRLink, RouteComponentProps } from '@reach/router';
import { parseISO } from 'date-fns';
import format from 'date-fns/format';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import chunk from 'lodash.chunk';
import { useState } from 'react';
import * as React from 'react';

import { analytics } from '../../../analytics/analytics';
import { companySubscriptionApi } from '../../../api/company-subscription';
import { Box } from '../../../components/common/Box';
import { Button } from '../../../components/common/Button';
import { Flex } from '../../../components/common/Flex';
import { Heading } from '../../../components/common/Heading';
import { Link } from '../../../components/common/Link';
import {
  Modal,
  ModalActions,
  ModalClose,
  ModalContainer,
  ModalContent,
  ModalTextButton,
  ModalTitle,
} from '../../../components/common/Modal';
import { Text } from '../../../components/common/Text';
import { useAuth } from '../../../context/AuthContext';
import { useCompany } from '../../../context/CompanyContext';
import { useUser } from '../../../context/UserContext';
import { RuntimeError } from '../../../Error/BaseErrors';
import logger from '../../../utils/logger';

interface PreQuestionnaireViewProps {
  onContinue: () => void;
}

export const PreQuestionnaireView: React.FC<PreQuestionnaireViewProps> = ({ onContinue }) => (
  <Box sx={{ pr: 4, mb: 4 }}>
    <Heading
      sx={{
        m: 0,
        pb: 5,
        letterSpacing: '-0.05rem',
        color: 'accent',
        fontSize: '1.75rem',
        fontWeight: 500,
      }}
    >
      Cancel Your Subscription
    </Heading>

    <React.Fragment>
      <Text mb="4">You're thinking of leaving?</Text>

      <Text mb="4">Boo... We hate to see anybody go.</Text>

      <Text mb="4">
        But did you know, if you cancel, you'll lose access to all of your saved adverts? You'll
        still have access right up to the end of your current biling cycle, but after that, they'll
        get permanently deleted and all that work you've put in will be lost.
      </Text>

      <Text mb="4">And nobody wants that.</Text>

      <Text mb="4">
        So if you're paying a little more than you'd like,{' '}
        <Link to="/subscriptions/change">we can downgrade your account</Link>. Click the change my
        plan button to do exactly that.
      </Text>

      <Text mb="4">
        Or if you're just not using AdGrader enough,{' '}
        <Link to="/settings/billing/pause-subscription">you can pause your account</Link> for £10
        per month.
      </Text>
      <Text mb="4">
        You won't be able to access AdGrader but it will keep your adverts live in the background
        so that when you need them and you need to reactivate your account, they're there waiting
        for you.
      </Text>
    </React.Fragment>

    <Flex pb="5">
      <Button
        as={RRLink}
        // @ts-ignore
        to="/subscriptions/change"
        variant="accent"
        sx={{
          mr: 3,
          textDecoration: 'none',
          fontWeight: 500,
          fontSize: '2',
          letterSpacing: '-0.02rem',
        }}
      >
        Change my current plan &rarr;
      </Button>

      <Button
        as={RRLink}
        // @ts-ignore
        to="/settings/billing/pause-subscription"
        variant="primary"
        sx={{ textDecoration: 'none', fontWeight: 500, fontSize: '2', letterSpacing: '-0.02rem' }}
      >
        Pause my subscription &rarr;
      </Button>
    </Flex>

    <Button variant="text" onClick={onContinue}>
      I still want to cancel &rarr;
    </Button>
  </Box>
);

enum CancellationReason {
  NotUsed = 1,
  StopRecruitment = 2,
  Complicated = 3,
  CustomerService = 4,
  Expensive = 5,
  Other = 6,
}

interface PauseSubscriptionModalProps {
  isOpen: boolean;
  title: string;
  content: string | React.ReactNode;
  actions: React.ReactNode;
  onCancel: () => void;
}

export const PauseSubscriptionModal: React.FC<PauseSubscriptionModalProps> = ({
  isOpen,
  title,
  content,
  actions,
  onCancel,
}) => (
  <Modal aria-label="Pause subscription confirmation" isOpen={isOpen} onDismiss={() => onCancel()}>
    <ModalClose onClick={() => onCancel()} />
    <ModalContainer>
      <ModalTitle>{title}</ModalTitle>
      <ModalContent>
        <Box>{typeof content === 'string' ? <Text>{content}</Text> : content}</Box>
      </ModalContent>
      <ModalActions
        alignItems="flex-start"
        sx={{
          flexDirection: 'column',
        }}
      >
        <Box mb={6}>{actions}</Box>

        <KeepSubscriptionButton type="button" onClick={() => onCancel()}>
          Nevermind, I want to stay subscribed &rarr;
        </KeepSubscriptionButton>
      </ModalActions>
    </ModalContainer>
  </Modal>
);

const KeepSubscriptionButton = styled(ModalTextButton)`
  padding: 0;
  &:hover {
    background: none;
    border: 2px solid transparent;
  }
`;

interface ReasonButtonProps {
  selected: boolean;
}

const ReasonButton = styled(Button)<ReasonButtonProps>`
  flex: 1;
  min-height: 105px;
  max-width: 15rem;
  margin-right: 1.5rem;
  padding: 2rem;
  font-weight: 500;
  font-size: ${(props) => props.theme.fontSizes[2]};
  letter-spacing: -0.02rem;
  background-color: ${(props) =>
    props.selected ? props.theme.colors.primary : props.theme.colors.offWhite};
  letter-spacing: -0.01rem;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: ${(props) => (props.selected ? props.theme.colors.white : 'rgba(0, 0, 0, 0.6)')};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 5px -1px, rgba(0, 0, 0, 0.07) 0px 6px 10px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 18px 0px;
  transition: ${(props) => props.theme.transitions[0]};
  &:hover {
    border: 1px solid #ffffff;
    color: ${(props) => props.theme.colors.white};
    background-color: ${(props) => props.theme.colors.primary};
  }
`;

interface ReasonProps {
  title: string;
  selected: boolean;
  modal: React.ReactNode;
  onClick: () => void;
}

export const Reason: React.FC<ReasonProps> = ({ title, selected, modal, onClick }) => {
  return (
    <ReasonButton selected={selected} onClick={onClick}>
      {title}
      {selected && modal}
    </ReasonButton>
  );
};

enum ModalType {
  InitialReason,
  Confirmation,
  Confirmed,
}

export const CancelSubscription: React.FC<RouteComponentProps> = () => {
  const { company } = useCompany();

  if (!company) {
    throw new RuntimeError(
      'Something went wrong whilst loading',
      "User doesn't have belong to a company yet so they shouldn't have access"
    );
  }

  const { fetchUser } = useAuth();
  const user = useUser();
  const [showQuiz, setShowQuiz] = useState(false);

  const [selectedReason, setSelectedReason] = useState<CancellationReason | undefined>(undefined);
  const [activeModalType, setActiveModalType] = useState<ModalType | undefined>(undefined);
  const [isCancelling, setIsCancelling] = useState(false);
  const [cancellationError, setCancellationError] = useState<Error | undefined>(undefined);

  if (company.subscriptionStatus === 'cancelled') {
    return (
      <React.Fragment>
        <Heading mb="4">Your subscription has been cancelled</Heading>
        Your account will close in{' '}
        {formatDistanceStrict(parseISO(user.company.subscriptionCurrentPeriodEnds), new Date())}.
      </React.Fragment>
    );
  }

  if (!showQuiz) {
    return <PreQuestionnaireView onContinue={() => setShowQuiz(true)} />;
  }

  const cancelSubscription = async () => {
    if (!selectedReason) {
      return;
    }

    setIsCancelling(true);

    try {
      await companySubscriptionApi.cancelSubscription(user.company.id, selectedReason);
      await fetchUser();
      setActiveModalType(ModalType.Confirmed);
    } catch (e) {
      logger.logError(e);
      setCancellationError(e);
    } finally {
      setIsCancelling(false);
    }
  };

  const reasons = [
    {
      id: CancellationReason.NotUsed,
      title: "We're not using it right now",
      modal: (
        <PauseSubscriptionModal
          isOpen={activeModalType === ModalType.InitialReason}
          title="Not using AdGrader enough right now?"
          content={
            <Box>
              <Text sx={{ mb: 3 }}>
                You can pause you account indefinitely for just £10 per month. That way you won't
                lose any of the work you and your team have put in and your adverts won't be
                deleted.
              </Text>
              <Text>And when you're ready, you can reactive your account at any time.</Text>
            </Box>
          }
          actions={
            <React.Fragment>
              <Box mb="4">
                <Button
                  as={RRLink}
                  // @ts-ignore
                  to="/settings/billing/pause-subscription"
                  variant="primary"
                  sx={{
                    display: 'block',
                    textDecoration: 'none',
                    fontWeight: 500,
                    fontSize: '2',
                    letterSpacing: '-0.02rem',
                  }}
                >
                  Pause account &rarr;
                </Button>
              </Box>

              <Button
                variant="danger"
                sx={{
                  fontWeight: 500,
                  fontSize: '2',
                  letterSpacing: '-0.02rem',
                }}
                onClick={() => setActiveModalType(ModalType.Confirmation)}
              >
                Stop subscription &rarr;
              </Button>
            </React.Fragment>
          }
          onCancel={() => setActiveModalType(undefined)}
        />
      ),
    },
    {
      id: CancellationReason.StopRecruitment,
      title: "We've got a hold on recruitment",
      modal: (
        <PauseSubscriptionModal
          isOpen={activeModalType === ModalType.InitialReason}
          title="Not recruiting right now?"
          content={
            <Box>
              <Text sx={{ mb: 3 }}>
                It happens to us all. No worries, you can pause your account indefinitely for just
                <Text as="span" sx={{ color: 'accent', fontWeight: 500 }}>
                  £10 per month
                </Text>
                .
              </Text>
              <Text sx={{ mb: 3 }}>
                That way you won't lose any of the work you and your team have put in and your
                adverts won't be deleted.
              </Text>

              <Text>
                And when you start recruiting again, you can reactive you account and your adverts
                will be there for you to instantly access.
              </Text>
            </Box>
          }
          actions={
            <React.Fragment>
              <Box mb="4">
                <Button
                  as={RRLink}
                  // @ts-ignore
                  to="/settings/billing/pause-subscription"
                  variant="primary"
                  sx={{
                    display: 'block',
                    textDecoration: 'none',
                    fontWeight: 500,
                    fontSize: '2',
                    letterSpacing: '-0.02rem',
                  }}
                >
                  Pause account &rarr;
                </Button>
              </Box>

              <Button
                variant="danger"
                sx={{ fontWeight: 500, fontSize: '2', letterSpacing: '-0.02rem' }}
                onClick={() => setActiveModalType(ModalType.Confirmation)}
              >
                Stop subscription &rarr;
              </Button>
            </React.Fragment>
          }
          onCancel={() => setActiveModalType(undefined)}
        />
      ),
    },
    {
      id: CancellationReason.Complicated,
      title: "It's too complicated to use",
      modal: (
        <PauseSubscriptionModal
          isOpen={activeModalType === ModalType.InitialReason}
          title="Struggling with AdGrader?"
          content={
            <Box>
              <Text sx={{ mb: 3 }}>
                We're sorry to hear that. Have you tried our{' '}
                <Text
                  as="a"
                  // @ts-ignore
                  href={process.env.REACT_APP_KNOWLEDGE_BASE_URL}
                  sx={{ color: 'accent', fontWeight: 500 }}
                >
                  Knowledge Base
                </Text>
                ?
              </Text>
              <Text sx={{ mb: 3 }}>
                Or failing that would you like to speak to someone in our support team to see if
                they can help you out?
              </Text>
              <Text sx={{ mb: 3 }}>
                Sometimes it's the simplest of fixes that can make the biggest difference
              </Text>
            </Box>
          }
          actions={
            <React.Fragment>
              <Box mb="4">
                <Button
                  as="a"
                  href="mailto:support@adgrader.io"
                  variant="primary"
                  sx={{
                    display: 'block',
                    textDecoration: 'none',
                    fontWeight: 500,
                    fontSize: '2',
                    letterSpacing: '-0.02rem',
                  }}
                  onClick={() => {
                    analytics.contact();
                  }}
                >
                  Contact Support &rarr;
                </Button>
              </Box>

              <Button
                variant="danger"
                sx={{ fontWeight: 500, fontSize: '2', letterSpacing: '-0.02rem' }}
                onClick={() => setActiveModalType(ModalType.Confirmation)}
              >
                Stop subscription &rarr;
              </Button>
            </React.Fragment>
          }
          onCancel={() => setActiveModalType(undefined)}
        />
      ),
    },
    {
      id: CancellationReason.CustomerService,
      title: 'Disatissfied with customer service',
      modal: (
        <PauseSubscriptionModal
          isOpen={activeModalType === ModalType.InitialReason}
          title="Don't like our service?"
          content={
            <Box>
              <Text sx={{ mb: 3 }}>
                That's really disappointing because we pride ourselves on outstanding levels of
                service.
              </Text>
              <Text sx={{ mb: 3 }}>
                Before you leave us, we'd love the oppurtunity to talk through what happened. Maybe
                we can work this out...
              </Text>
              <Text>Click the contact support button below and we'll get in touch.</Text>
            </Box>
          }
          actions={
            <React.Fragment>
              <Box mb="4">
                <Button
                  as="a"
                  href="mailto:support@adgrader.io"
                  variant="primary"
                  sx={{
                    display: 'block',
                    textDecoration: 'none',
                    fontWeight: 500,
                    fontSize: '2',
                    letterSpacing: '-0.02rem',
                  }}
                  onClick={() => {
                    analytics.contact();
                  }}
                >
                  Contact Support &rarr;
                </Button>
              </Box>

              <Button
                variant="danger"
                sx={{ fontWeight: 500, fontSize: '2', letterSpacing: '-0.02rem' }}
                onClick={() => setActiveModalType(ModalType.Confirmation)}
              >
                Stop subscription &rarr;
              </Button>
            </React.Fragment>
          }
          onCancel={() => setActiveModalType(undefined)}
        />
      ),
    },
    {
      id: CancellationReason.Expensive,
      title: "It's too expensive",
      modal: (
        <PauseSubscriptionModal
          isOpen={activeModalType === ModalType.InitialReason}
          title="Your plan is too expensive?"
          content={
            <Box>
              <Text sx={{ mb: 3 }}>
                We've got a range of plans to cater for all needs. Switching plans is really easy
                too.
              </Text>
              <Text>
                To downgrade your plan, just click on the charge plan button below, select the new
                plan you need and follow the on-screen prompts.
              </Text>
            </Box>
          }
          actions={
            <React.Fragment>
              <Box mb="4">
                <Button
                  as={RRLink}
                  // @ts-ignore
                  to="/subscriptions/change"
                  variant="primary"
                  sx={{
                    display: 'block',
                    textDecoration: 'none',
                    fontWeight: 500,
                    fontSize: '2',
                    letterSpacing: '-0.02rem',
                  }}
                >
                  Change plan &rarr;
                </Button>
              </Box>

              <Button
                variant="danger"
                sx={{ fontWeight: 500, fontSize: '2', letterSpacing: '-0.02rem' }}
                onClick={() => setActiveModalType(ModalType.Confirmation)}
              >
                Stop subscription &rarr;
              </Button>
            </React.Fragment>
          }
          onCancel={() => setActiveModalType(undefined)}
        />
      ),
    },
    {
      id: CancellationReason.Other,
      title: 'Other',
      modal: undefined,
    },
  ];

  return (
    <React.Fragment>
      <Box pr="4" mb="4">
        <Box mb="5">
          <Heading>Why do you want to leave us?</Heading>
        </Box>

        {chunk(reasons, 3).map((row, index) => (
          <Flex key={index} mb="4">
            {row.map((reason) => (
              <Reason
                key={reason.id}
                title={reason.title}
                selected={selectedReason === reason.id}
                modal={reason.modal}
                onClick={() => setSelectedReason(reason.id)}
              />
            ))}
          </Flex>
        ))}

        <Box mt="8">
          <Button
            type="button"
            variant="accent"
            sx={{ mr: 4, fontWeight: 500, fontSize: '2', letterSpacing: '-0.02rem' }}
            onClick={() => setShowQuiz(false)}
          >
            Nevermind
          </Button>
          <Button
            type="button"
            variant={selectedReason ? 'primaryInverted' : 'disabled'}
            sx={{
              fontWeight: 500,
              fontSize: '2',
              letterSpacing: '-0.02rem',
            }}
            onClick={() => {
              if (!selectedReason) {
                return;
              }

              if (selectedReason === CancellationReason.Other) {
                setActiveModalType(ModalType.Confirmation);
                return;
              }

              setActiveModalType(ModalType.InitialReason);
            }}
          >
            Continue
          </Button>
        </Box>
      </Box>

      <Modal
        aria-label="Cancellation confirmation"
        isOpen={activeModalType === ModalType.Confirmation}
        onDismiss={() => setActiveModalType(undefined)}
      >
        <ModalClose onClick={() => setActiveModalType(undefined)} />
        <ModalContainer>
          <ModalTitle>We hate to see you go.</ModalTitle>
          <ModalContent>
            <Text>
              If there's something we can do, or if you want to let us know what happened, please
              contact us. Maybe we can work this out.
            </Text>

            <Text
              as="a"
              // @ts-ignore
              href="mailto:support@adgrader.io"
              sx={{ display: 'block', py: 3 }}
              onClick={() => {
                analytics.contact();
              }}
            >
              Contact member services &rarr;
            </Text>

            {company.subscriptionStatus === 'in_trial' ? (
              <Text>
                If you choose to stop your subscription during your trial your account will be
                terminated and all of your saved adverts will be deleted.
              </Text>
            ) : (
              <Text>
                If you choose to stop your subscription you'll still have full access to AdGrader
                until {format(parseISO(company.subscriptionCurrentPeriodEnds), 'do LLL yyyy')},
                after which point you account will be terminated and all of your saved adverts will
                be deleted.
              </Text>
            )}
          </ModalContent>
          <ModalActions
            sx={{
              flexDirection: 'column',
              alignItems: 'flex-start !important',
            }}
          >
            <Button
              variant="danger"
              disabled={isCancelling}
              onClick={cancelSubscription}
              sx={{ mb: 2 }}
            >
              Stop subscription &rarr;
            </Button>

            {cancellationError && <Text>{cancellationError.message}</Text>}

            <ModalTextButton
              type="button"
              sx={{ pl: 0 }}
              onClick={() => setActiveModalType(undefined)}
            >
              Nevermind, I want to stay subscribed &rarr;
            </ModalTextButton>
          </ModalActions>
        </ModalContainer>
      </Modal>

      <Modal isOpen={activeModalType === ModalType.Confirmed}>
        <ModalContainer>
          <ModalTitle>That's confirmed</ModalTitle>
          <ModalContent>
            <Box mb="4">
              <Text>
                We're really sorry to see you go, but sometimes it just can't be helped. Your
                AdGrader account will remain live until the end of this current billing cycle (
                {format(parseISO(company.subscriptionCurrentPeriodEnds), 'dd LLL yyyy')}).
              </Text>
            </Box>

            <Box mb="4">
              <Text>
                Many, many thanks for your business. It is very much appreciated, and we're always
                here should you ever need our services in the future.
              </Text>
            </Box>

            <Box>
              <Text>
                And if you change your mind in the meantime, there will be a link on your dashboard
                to keep your subscription going.
              </Text>
            </Box>
          </ModalContent>

          <ModalActions justifyContent="space-between">
            <Box>
              <Text sx={{ fontWeight: 'bold', mb: 1 }}>James Ball</Text>
              <Text mt="0">CEO, AdGrader</Text>
            </Box>
            <Box>
              <Button
                as={RRLink}
                // @ts-ignore
                to="/dashboard"
                variant="primary"
                sx={{ textDecoration: 'none' }}
              >
                Go to the dashboard &rarr;
              </Button>
            </Box>
          </ModalActions>
        </ModalContainer>
      </Modal>
    </React.Fragment>
  );
};
