import React, { useState } from 'react';

import { UserPreference, useUserPreferences } from '../context/UserPreferencesContext';
import { useWindowSize } from '../context/WindowSizeContext';
import { useTheme } from '../hooks/useTheme';
import { Box } from './common/Box';
import { Button } from './common/Button';
import { Flex } from './common/Flex';
import { Heading } from './common/Heading';
import { Image } from './common/Image';
import { Text } from './common/Text';
import { ScrollToTop } from './Headless/ScrollToTop';

const MOBILE_SIZE_THRESHOLD = 768;

export const NotSuitableForSmallDevicesWarning: React.FC = (props) => {
  const theme = useTheme();
  const { warningVisible, hideWarning } = useCachedWarningToggle();

  if (warningVisible) {
    return (
      <ScrollToTop>
        <Box
          sx={{
            pt: 9,
            px: 4,
            background: theme.backgrounds.header.background,
            backgroundSize: theme.backgrounds.header.backgroundSize,
            height: '100vh',
          }}
        >
          <Image sx={{ width: '8rem', pb: 4 }} src={theme.assets.logo.light} alt="AdGrader logo" />
          <Heading sx={{ color: 'white', fontSize: 5, pb: 5 }}>Before you proceed...</Heading>
          <Text sx={{ color: 'white', fontSize: 3, pb: 4 }}>
            AdGrader is best used on tablet or desktop.
          </Text>
          <Text sx={{ color: 'white', fontSize: 3, pb: 8 }}>Would you still like to continue?</Text>

          <Flex sx={{ justifyContent: 'center', alignItems: 'center' }}>
            <Button variant="primaryInverted" onClick={hideWarning}>
              Take me to AdGrader
            </Button>
          </Flex>
        </Box>
      </ScrollToTop>
    );
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

const useCachedWarningToggle = () => {
  const windowSize = useWindowSize();
  const [userPreferences, updateUserPreferences] = useUserPreferences();

  const [warningVisible, setWarningVisible] = useState(() => {
    if (userPreferences[UserPreference.SmallDevicesWarningCleared]) {
      return false;
    }

    return windowSize.width < MOBILE_SIZE_THRESHOLD;
  });

  const hideWarning = () => {
    setWarningVisible(false);
    updateUserPreferences({
      ...userPreferences,
      [UserPreference.SmallDevicesWarningCleared]: true,
    });
  };

  return {
    warningVisible,
    hideWarning,
  };
};
