import * as React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

import { Box } from '../../../components/common/Box';

interface StatsBoxProps {
  sx?: ThemeUIStyleObject;
}

export const StatsBox: React.FC<StatsBoxProps> = ({ children, sx }) => (
  <Box
    sx={{
      gridTemplateColumns: '1fr',
      gap: 4,
      p: 6,
      borderRadius: 8,
      background: 'white',
      border: '1px solid #ccc',
      flex: 1,
      minHeight: '92px',
      ...sx,
    }}
  >
    {children}
  </Box>
);
