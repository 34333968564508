import { Flex } from '../../../components/common/Flex';
import { Text } from '../../../components/common/Text';
import { LoadingSpinner } from '../../../components/LoadingSpinner';

export const LoadingAnalytics = () => {
  return (
    <Flex sx={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column', py: 12 }}>
      <Text pb={4}>Loading company analytics...</Text>
      <LoadingSpinner />
    </Flex>
  );
};
