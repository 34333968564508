import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { useEffect, useState } from 'react';

import { companyAnalyticsApi } from '../../../api/company-analytics';
import { Box } from '../../../components/common/Box';
import { Grid } from '../../../components/common/Grid';
import { useCompany } from '../../../context/CompanyContext';
import { RuntimeError } from '../../../Error/BaseErrors';
import { AnalyticsHeader } from './AnalyticsHeader';
import { AnalyticsInclusivity } from './AnalyticsInclusivity';
import { AnalyticsOverview } from './AnalyticsOverview';
import { AnalyticsReadability } from './AnalyticsReadability';
import { dateRangeOptions, getStartAndEndDates, StartAndEndDates } from './DateRangePicker';
import { LoadingAnalytics } from './LoadingAnalytics';
import { NoAdsWarning } from './NoAdsWarning';

interface CompanyAnalyticsProps extends RouteComponentProps {}

export const CompanyAnalytics: React.FC<CompanyAnalyticsProps> = () => {
  const { company } = useCompany();
  const [selectedDateRangeOption, setSelectedDateRangeOption] = useState(dateRangeOptions[0]);

  useEffect(() => {
    setStartAndEndDate(getStartAndEndDates(selectedDateRangeOption.value));
  }, [selectedDateRangeOption]);

  const [startAndEndDate, setStartAndEndDate] = useState<StartAndEndDates>(
    getStartAndEndDates(dateRangeOptions[0].value)
  );

  const { data: companyAnalytics, status } = companyAnalyticsApi.useCompanyAnalytics(
    company?.id,
    startAndEndDate
  );

  if (!company) {
    throw new RuntimeError(
      'Something went wrong whilst loading analytics',
      "User doesn't have belong to a company yet so they shouldn't have access"
    );
  }

  if (status === 'error') {
    throw new RuntimeError('Something went wrong whilst loading analytics', 'Unknown error');
  }

  if (['idle', 'loading'].includes(status)) return <LoadingAnalytics />;

  return (
    <Box px={4} sx={{ maxWidth: 1536, margin: '0 auto' }}>
      <AnalyticsHeader
        company={company}
        companyAnalytics={companyAnalytics}
        selectedDateRangeOption={selectedDateRangeOption}
        setSelectedDateRangeOption={setSelectedDateRangeOption}
        startAndEndDate={startAndEndDate}
        disableExport={!companyAnalytics?.AdWithContentCount}
      />

      {!companyAnalytics?.AdWithContentCount ? (
        <NoAdsWarning />
      ) : (
        <Grid sx={{ gridTemplateColumns: '1fr 1fr', gap: 4, pb: 4 }}>
          <AnalyticsOverview companyAnalytics={companyAnalytics} />
          <AnalyticsInclusivity companyAnalytics={companyAnalytics} />
          <AnalyticsReadability companyAnalytics={companyAnalytics} />
        </Grid>
      )}
    </Box>
  );
};
