import qs from 'qs';
import { useQuery } from 'react-query';

import { client } from './client';
import { GradingInclusivityGenderResult } from './grade-content';

export const inclusivityWordTypes = [
  'blocking_inexperience',
  'education',
  'essential_requirements',
  'exclusionary',
  'feminine',
  'masculine',
] as const;

export const readabilityWordTypes = [
  'complex_phrases',
  'corporate_cliche',
  'qualifying_phrases',
] as const;

type InclusivityWordType = typeof inclusivityWordTypes[number];
type ReadabilityWordType = typeof readabilityWordTypes[number];
type GradingWordType = InclusivityWordType | ReadabilityWordType;

export type GradedWordAverage = number | string;
export type GradedWordFrequency = { [word: string]: number };

export interface ApiCompanyAnalyticsDetails {
  AdCount: number;
  AdWithContentCount: number;
  StatsAverages: {
    WordCount: number;
    GradingInclusivityScore: number;
    GradingReadabilityScore: number;
    GradingReadabilityReadingEase: number;
    GradingReadabilityReadingGrade: number;
  };
  GenderResult: GradingInclusivityGenderResult;
  GradedWords: { [key in GradingWordType]: string[] };
  GradedWordsAverages: { [key in GradingWordType]: GradedWordAverage };
  GradedWordsFrequencies: { [key in GradingWordType]: GradedWordFrequency };
}

interface FetchCompanyAnalyticsOptions {
  startDate: Date;
  endDate: Date;
}

const fetchCompanyAnalytics = async (
  companyId: number | undefined,
  options: FetchCompanyAnalyticsOptions
): Promise<ApiCompanyAnalyticsDetails | undefined> => {
  if (!companyId) {
    return undefined;
  }

  const params = qs.stringify(
    {
      startDate: options.startDate.toISOString(),
      endDate: options.endDate.toISOString(),
    },
    { arrayFormat: 'indices' }
  );

  const response = await client.get(`/company/${companyId}/analytics?${params}`);
  return response.data;
};

const useCompanyAnalytics = (
  companyId: number | undefined,
  options: FetchCompanyAnalyticsOptions
) => {
  return useQuery(['companyAnalytics', companyId, options], () =>
    fetchCompanyAnalytics(companyId, options)
  );
};

export const companyAnalyticsApi = {
  fetchCompanyAnalytics,

  useCompanyAnalytics,
};
