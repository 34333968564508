import styled from '@emotion/styled/macro';
import { Link, RouteComponentProps } from '@reach/router';
import { ErrorMessage, Formik } from 'formik';
import qs from 'qs';
import { Component } from 'react';
import * as Yup from 'yup';

import { ApiInvite, invitationApi } from '../../api/invitation';
import { RequestState } from '../../api/types';
import { Box } from '../../components/common/Box';
import { Button } from '../../components/common/Button';
import { Flex } from '../../components/common/Flex';
import { ErrorMessage as ThemedErrorMessage, TextField } from '../../components/common/form';
import { Heading } from '../../components/common/Heading';
import { Image } from '../../components/common/Image';
import { Text } from '../../components/common/Text';
import { AuthConsumer } from '../../context/AuthContext';
import { ThemeConsumer } from '../../hooks/useTheme';
import { getRegistrationMetadata } from '../../utils/auth/registration-utils';
import { ApiFormError } from '../../utils/errors/ApiFormError';
import {
  EmailSchema,
  FirstNameSchema,
  LastNameSchema,
  PasswordSchema,
} from '../../utils/form-validation/user-details';
import logger from '../../utils/logger';

const Message = styled.p`
  font-size: 1.2rem;
  color: #696969;
  margin: 0 0 2.75rem 0;
  letter-spacing: -0.01rem;
`;

const SignInLink = styled(Link)`
  color: inherit;
  font-weight: 700;
`;

interface InvitationProps extends RouteComponentProps {}

interface InvitationState {
  errorMessage?: string;
  invite?: ApiInvite;
  token?: string;
  fetchInviteRequestState: RequestState;
}

const InvitationFormSchema = Yup.object().shape({
  firstName: FirstNameSchema,
  lastName: LastNameSchema,
  email: EmailSchema,
  plainPassword: PasswordSchema,
});

export class Invitation extends Component<InvitationProps, InvitationState> {
  state = {
    errorMessage: undefined,
    invite: undefined as ApiInvite | undefined,
    token: undefined as string | undefined,
    fetchInviteRequestState: RequestState.Pending,
  };

  componentDidMount = () => {
    const { email, token } = this.extractQueryParams();

    if (!email || !token) {
      this.setState({ errorMessage: 'An e-mail address and a token are required' });
      return;
    }

    if (Array.isArray(email) || Array.isArray(token)) {
      this.setState({ errorMessage: 'E-mail address and a token should be strings' });
      return;
    }

    this.fetchInvitation(email, token);
  };

  extractQueryParams = () => {
    const { location } = this.props;

    if (!location) {
      throw new Error('location not passed from the router');
    }

    const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    const { e: email, t: token, source } = queryParams;

    return {
      email: typeof email === 'string' ? email : undefined,
      token: typeof token === 'string' ? token : undefined,
      source: typeof source === 'string' ? source : 'direct',
    };
  };

  async fetchInvitation(email: string, token: string) {
    this.setState({ fetchInviteRequestState: RequestState.InProgress });

    try {
      const response = await invitationApi.fetchInvite(
        encodeURIComponent(email),
        encodeURIComponent(token)
      );

      this.setState({
        invite: response.invite,
        token,
        fetchInviteRequestState: RequestState.Success,
      });
    } catch (e) {
      logger.logError(e);
      this.setState({
        errorMessage: 'Could not find an invite for that token',
        fetchInviteRequestState: RequestState.Failed,
      });
    }
  }

  render() {
    const { errorMessage, fetchInviteRequestState, invite } = this.state;

    return (
      <AuthConsumer>
        {({ registerFromInvite }) => (
          <Box as="section" sx={{ minHeight: '100%', pt: 8, bg: 'offWhite' }}>
            <Box sx={{ maxWidth: '600px', mx: 'auto', my: 0 }}>
              <ThemeConsumer>
                {(theme) => (
                  <Box mb="7">
                    <Image sx={{ width: '12rem' }} src={theme.assets.logo.dark} alt="Logo" />
                  </Box>
                )}
              </ThemeConsumer>

              <Heading
                as="h1"
                sx={{ fontSize: 8, margin: '0 0 3rem 0', letterSpacing: '-0.075rem' }}
              >
                Invitation to join.
              </Heading>
              {errorMessage ? (
                <p>{errorMessage}</p>
              ) : fetchInviteRequestState === RequestState.Pending ||
                fetchInviteRequestState === RequestState.InProgress ? (
                <Message>Looking for your invite...</Message>
              ) : fetchInviteRequestState === RequestState.Failed ? (
                <Message>Failed to load invite</Message>
              ) : fetchInviteRequestState === RequestState.Success && invite ? (
                <div>
                  <Message>
                    {invite.createdBy.firstName} {invite.createdBy.lastName} has invited you to join
                    their AdGrader team
                  </Message>
                  <Formik
                    initialValues={{
                      firstName: invite.firstName,
                      lastName: invite.lastName ?? '',
                      email: invite.email,
                      plainPassword: '',
                    }}
                    validationSchema={InvitationFormSchema}
                    onSubmit={async (values, { setErrors, setSubmitting }) => {
                      try {
                        setSubmitting(true);

                        let metadata = getRegistrationMetadata(window.location.search);
                        metadata.variant = 'invitation';

                        await registerFromInvite({
                          firstName: values.firstName,
                          lastName: values.lastName,
                          email: values.email,
                          password: values.plainPassword,
                          token: this.state.token as string,
                          metadata: metadata,
                        });
                      } catch (e) {
                        if (e instanceof ApiFormError) {
                          setErrors(e.errors);
                        } else if (e.response && e.response.status === 404) {
                          setErrors({ email: 'Could not find the invitiation' });
                        } else {
                          logger.logError(e);
                        }
                      } finally {
                        setSubmitting(false);
                      }
                    }}
                  >
                    {({ values, isSubmitting, handleSubmit, handleBlur, handleChange }) => (
                      <form onSubmit={handleSubmit}>
                        <Flex sx={{ flexDirection: 'row', pb: 5 }}>
                          <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                            <Flex
                              as="label"
                              sx={{
                                justifyContent: 'space-between',
                                fontWeight: 600,
                                pb: '10px',
                              }}
                            >
                              Your first name:
                            </Flex>
                            <TextField
                              data-testid="first-nam"
                              type="text"
                              name="firstName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.firstName}
                            />
                            <ErrorMessage name="firstName">
                              {(msg) => <ThemedErrorMessage>{msg}</ThemedErrorMessage>}
                            </ErrorMessage>
                          </Flex>
                        </Flex>

                        <Flex sx={{ flexDirection: 'row', pb: 5 }}>
                          <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                            <Flex
                              as="label"
                              sx={{
                                justifyContent: 'space-between',
                                fontWeight: 600,
                                pb: '10px',
                              }}
                            >
                              Your last name:
                            </Flex>
                            <TextField
                              data-testid="last-name"
                              type="text"
                              name="lastName"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.lastName}
                            />
                            <ErrorMessage name="lastName">
                              {(msg) => <ThemedErrorMessage>{msg}</ThemedErrorMessage>}
                            </ErrorMessage>
                          </Flex>
                        </Flex>

                        <Flex sx={{ flexDirection: 'row', pb: 5 }}>
                          <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                            <Flex
                              as="label"
                              sx={{
                                justifyContent: 'space-between',
                                fontWeight: 600,
                                pb: '10px',
                              }}
                            >
                              Your email address:
                            </Flex>
                            <TextField
                              data-testid="email"
                              type="email"
                              name="email"
                              disabled
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <ErrorMessage name="email">
                              {(msg) => <ThemedErrorMessage>{msg}</ThemedErrorMessage>}
                            </ErrorMessage>
                          </Flex>
                        </Flex>

                        <Flex sx={{ flexDirection: 'row', pb: 5 }}>
                          <Flex sx={{ flex: 1, flexDirection: 'column' }}>
                            <Flex
                              as="label"
                              sx={{
                                justifyContent: 'space-between',
                                fontWeight: 600,
                                pb: '10px',
                              }}
                            >
                              <span>Choose a Password*</span>
                              <Box as="span" sx={{ fontWeight: 300, color: '#666666' }}>
                                8 characters or more, at least one number and one letter
                              </Box>
                            </Flex>
                            <TextField
                              data-testid="password"
                              type="password"
                              name="plainPassword"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.plainPassword}
                            />
                            <ErrorMessage name="plainPassword">
                              {(msg) => <ThemedErrorMessage>{msg}</ThemedErrorMessage>}
                            </ErrorMessage>
                          </Flex>
                        </Flex>

                        <Flex sx={{ flexDirection: 'row', pb: 5 }}>
                          <Button
                            variant="submit"
                            type="submit"
                            disabled={isSubmitting}
                            data-testid="submit"
                          >
                            Join Now &rarr;
                          </Button>
                        </Flex>
                      </form>
                    )}
                  </Formik>
                </div>
              ) : null}
              <hr />
              <Text as="p" sx={{ color: '#797979', mt: 5, pb: 5, letterSpacing: '-0.01rem' }}>
                Already have a AdGrader account? <SignInLink to="/login">Sign in</SignInLink> now.
              </Text>
            </Box>
          </Box>
        )}
      </AuthConsumer>
    );
  }
}
