import { ErrorMessage, Field, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { useCallback, useState } from 'react';
import * as React from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { authApi } from '../api/auth';
import { ErrorMessage as ThemedErrorMessage } from '../components/common/form';
import { useAuth } from '../context/AuthContext';
import { useUser } from '../context/UserContext';
import { ThemeProvider } from '../theme/ThemeProvider';
import { isApiFormError } from '../utils/errors/ApiFormError';
import {
  EmailSchema,
  FirstNameSchema,
  LastNameSchema,
} from '../utils/form-validation/user-details';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel } from './common/Accordion';
import { Box } from './common/Box';
import { Button } from './common/Button';
import { Flex } from './common/Flex';
import { Label, TextField } from './common/form';
import { Grid } from './common/Grid';
import { Modal, ModalClose, ModalContainer, ModalContent, ModalTitle } from './common/Modal';
import { Rotate } from './Headless/Rotate';
import { ChevronDown } from './Icon';

const theme = {
  accordion: {
    item: {
      primary: {
        mb: 2,
      },
    },
    button: {
      primary: {
        color: 'accent',
        fontWeight: 'semibold',
        px: 0,
        py: 3,
        width: '100%',
        border: 'none',
        bg: 'initial',
        fontSize: 4,
        cursor: 'pointer',
      },
    },
    panel: {
      primary: {
        px: 0,
        py: 3,
        border: 'none',
      },
    },
  },
};

interface ProfileModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const ProfileModal: React.FC<ProfileModalProps> = ({ isOpen, onClose }) => {
  const [accordionIndex, setAccordionIndex] = useState(0);
  const { fetchUser } = useAuth();
  const user = useUser();

  const { mutate: updateUserMutation } = useMutation(authApi.updateUser);

  const onUserDetailsSubmit = useCallback(
    async (values: UserDetailsFormValues, formikHelpers: FormikHelpers<UserDetailsFormValues>) => {
      await updateUserMutation(values, {
        onSuccess: () => {
          toast.success('Profile updated successfully');
          fetchUser();
        },
        onError: (error) => {
          if (isApiFormError(error)) {
            if (Object.entries(error.errors).length) {
              formikHelpers.setErrors(error.errors);
              return;
            }

            if (error.rootErrors.length) {
              toast.error(error.rootErrors[0]);
              return;
            }
          }

          toast.error('Something went wrong whilst updating your details');
        },
      });
    },
    [updateUserMutation, fetchUser],
  );

  const { mutate: changePasswordMutation } = useMutation(authApi.changePassword);

  const onChangePasswordSubmit = useCallback(
    async (
      values: ChangePasswordFormValues,
      formikHelpers: FormikHelpers<ChangePasswordFormValues>,
    ) => {
      await changePasswordMutation(values, {
        onSuccess: () => {
          toast.success('Password updated successfully');
          formikHelpers.resetForm();
          fetchUser();
        },
        onError: (error) => {
          if (isApiFormError(error)) {
            if (Object.entries(error.errors).length) {
              formikHelpers.setErrors(error.errors);
              return;
            }

            if (error.rootErrors.length) {
              toast.error(error.rootErrors[0]);
              return;
            }
          }

          toast.error('Something went wrong whilst updating your password');
        },
      });
    },
    [changePasswordMutation, fetchUser],
  );

  return (
    <Modal aria-label='Manage account details' isOpen={isOpen} onDismiss={() => onClose()}>
      <ModalClose onClick={() => onClose()} />
      <ModalContainer>
        <ModalTitle>Manage account details.</ModalTitle>

        <ModalContent>
          <ThemeProvider theme={theme as TSThemeFixMe}>
            <Accordion
              collapsible
              index={accordionIndex}
              onChange={(value: number) => setAccordionIndex(value)}
            >
              <AccordionItem>
                <AccordionButton>
                  <Flex sx={{ justifyContent: 'space-between' }}>
                    <Box>Edit your profile</Box>

                    <Box>
                      <Rotate active={accordionIndex === 0}>
                        <ChevronDown />
                      </Rotate>
                    </Box>
                  </Flex>
                </AccordionButton>

                <AccordionPanel>
                  <UserDetailsForm
                    initialValues={{
                      firstName: user.firstName,
                      lastName: user.lastName,
                      email: user.email,
                    }}
                    onSubmit={onUserDetailsSubmit}
                  />
                </AccordionPanel>
              </AccordionItem>

              <Box as='hr' sx={{ margin: 0, border: 'none', borderTop: '1px solid #cccccc' }} />

              <AccordionItem>
                <AccordionButton>
                  <Flex sx={{ justifyContent: 'space-between' }}>
                    <Box>Change password</Box>

                    <Box>
                      <Rotate active={accordionIndex === 1}>
                        <ChevronDown />
                      </Rotate>
                    </Box>
                  </Flex>
                </AccordionButton>

                <AccordionPanel>
                  <ChangePasswordForm onSubmit={onChangePasswordSubmit} />
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </ThemeProvider>
        </ModalContent>
      </ModalContainer>
    </Modal>
  );
};

const UserDetailsSchema = Yup.object().shape({
  firstName: FirstNameSchema,
  lastName: LastNameSchema,
  email: EmailSchema,
});

interface UserDetailsFormValues {
  firstName: string;
  lastName: string;
  email: string;
}

interface UserDetailsFormProps {
  initialValues: UserDetailsFormValues;
  onSubmit: (
    values: UserDetailsFormValues,
    formik: FormikHelpers<UserDetailsFormValues>,
  ) => Promise<void>;
}

const UserDetailsForm: React.FC<UserDetailsFormProps> = (props) => {
  return (
    <Formik
      initialValues={props.initialValues}
      validationSchema={UserDetailsSchema}
      onSubmit={props.onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Grid sx={{ gridTemplateColumns: '1fr 1fr', columnGap: '10px', pb: 4 }}>
            <Box>
              <Label
                htmlFor='firstName'
                sx={{
                  fontSize: '0.9rem',
                  fontWeight: 500,
                }}>
                First Name
                <Field name='firstName'>
                  {({ field }: FieldProps) => (
                    <React.Fragment>
                      <TextField
                        id='firstName'
                        type='text'
                        sx={{
                          borderRadius: '4px',
                          padding: '0.75rem 0.5rem',
                          border: '2px solid rgb(225, 225, 225)',
                          backgroundColor: 'rgb(248, 248, 248)',
                          fontSize: '1rem',
                          color: 'rgba(0, 0, 0, 0.85)',
                        }}
                        {...field} />
                      <Box mt={2}>
                        <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                      </Box>
                    </React.Fragment>
                  )}
                </Field>
              </Label>
            </Box>

            <Box>
              <Label htmlFor='lastName'>
                Last Name
                <Field name='lastName'>
                  {({ field }: FieldProps) => (
                    <React.Fragment>
                      <TextField
                        type='text'
                        {...field}
                        sx={{
                          borderRadius: '4px',
                          padding: '0.75rem 0.5rem',
                          border: '2px solid rgb(225, 225, 225)',
                          backgroundColor: 'rgb(248, 248, 248)',
                          fontSize: '1rem',
                          color: 'rgba(0, 0, 0, 0.85)',
                        }} />
                      <Box mt={2}>
                        <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                      </Box>
                    </React.Fragment>
                  )}
                </Field>
              </Label>
            </Box>
          </Grid>

          <Box sx={{ pb: 4 }}>
            <Label htmlFor='email'>
              Email Address
              <Field name='email'>
                {({ field }: FieldProps) => (
                  <React.Fragment>
                    <TextField
                      id='email'
                      type='email'
                      {...field}
                      sx={{
                        borderRadius: '4px',
                        padding: '0.75rem 0.5rem',
                        border: '2px solid rgb(225, 225, 225)',
                        backgroundColor: 'rgb(248, 248, 248)',
                        fontSize: '1rem',
                        color: 'rgba(0, 0, 0, 0.85)',
                      }} />
                    <Box mt={2}>
                      <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                    </Box>
                  </React.Fragment>
                )}
              </Field>
            </Label>
          </Box>

          <Box>
            <Button type='submit' variant='primaryInverted' disabled={isSubmitting}>
              Save settings
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};

interface ChangePasswordFormValues {
  password: string;
  newPassword: string;
  newPasswordConfirmation: string;
}

interface ChangePasswordFormProps {
  onSubmit: (
    values: ChangePasswordFormValues,
    formikHelpers: FormikHelpers<ChangePasswordFormValues>,
  ) => Promise<void>;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = (props) => {
  return (
    <Formik
      initialValues={{ password: '', newPassword: '', newPasswordConfirmation: '' }}
      onSubmit={props.onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Box sx={{ pb: 5 }}>
            <Label htmlFor='password'>
              Current password
              <Field name='password'>
                {({ field }: FieldProps) => (
                  <React.Fragment>
                    <TextField id='password' type='password' {...field} />
                    <Box mt={2}>
                      <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                    </Box>
                  </React.Fragment>
                )}
              </Field>
            </Label>
          </Box>

          <Box>
            <Label htmlFor='newPassword'>
              New password
              <Field name='newPassword'>
                {({ field }: FieldProps) => (
                  <React.Fragment>
                    <TextField id='newPassword' type='password' {...field} />
                    <Box mt={2}>
                      <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                    </Box>
                  </React.Fragment>
                )}
              </Field>
            </Label>
          </Box>

          <Box sx={{ pb: 4 }}>
            <Label htmlFor='newPasswordConfirmation'>
              New password confirmation
              <Field name='newPasswordConfirmation'>
                {({ field }: FieldProps) => (
                  <React.Fragment>
                    <TextField id='newPasswordConfirmation' type='password' {...field} />
                    <Box mt={2}>
                      <ErrorMessage name={field.name} component={ThemedErrorMessage} />
                    </Box>
                  </React.Fragment>
                )}
              </Field>
            </Label>
          </Box>

          <Box>
            <Button type='submit' variant='primaryInverted' disabled={isSubmitting}>
              Update password
            </Button>
          </Box>
        </Form>
      )}
    </Formik>
  );
};
