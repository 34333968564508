import { CSSObject } from '@emotion/styled/macro';
import styled from '@emotion/styled/macro';
import orderBy from 'lodash.orderby';
import { useEffect, useRef } from 'react';
import * as React from 'react';

import { ApiAdvertTag } from '../../api/advert-tags';
import { Box } from '../../components/common/Box';
import { MultiSelect } from '../../components/Headless/MultiSelect';

interface Tag {
  id: number;
  label: string;
  backgroundColor: string;
  borderColor: string;
  system: boolean;
}

interface TagAutocompleteProps {
  isOpen: boolean;
  tags: Tag[];
  selectedTags: ApiAdvertTag[];
  onTagsChanged: (tags: Tag[]) => void;
  onClose: () => void;
}

const Container = styled.div`
  position: absolute;
  left: -0.5rem;
  top: calc(100% + 0.1rem);
  margin-left: 0 !important;
  width: 300px;
  max-height: 16rem;
  background-color: #ffffff;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 4px;
  box-shadow: 0 0 0 1px hsla(0, 0%, 45%, 0.4), 0 2px 10px rgba(3, 18, 42, 0.45);
  outline: 0;
  overflow: hidden;
  z-index: 2;
`;

const Input = styled.input`
  width: 100%;
  background-color: #f6f6f6;
  border-radius: 0;
  outline: none;
  border: 1px solid ${(props) => props.theme.colors.grey[3]};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  font-size: ${(props) => props.theme.fontSizes[1]};
  margin-bottom: 0.5rem;
  -webkit-appearance: none;
`;

const ItemIdentifier = styled('div')<{ backgroundColor: string; borderColor: string }>(
  {
    width: '0.8rem',
    height: '0.8rem',
    borderRadius: '50%',
    marginRight: '0.5rem',
    border: '1px solid',
    marginLeft: '0 !important',
  },
  ({ backgroundColor, borderColor }) => ({
    backgroundColor,
    borderColor,
  })
);

const ItemList = styled('ul')`
  padding: 0;
  margin: 0;
  list-style: none;
  height: 12rem;
  overflow: auto;
`;

const Item = styled('li')<{ isActive: boolean; isSelected: boolean }>(
  {
    position: 'relative',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    border: 'none',
    height: 'auto',
    textAlign: 'left',
    borderTop: 'none',
    lineHeight: '1em',
    color: 'rgba(0,0,0,.87)',
    fontSize: '0.9rem',
    textTransform: 'none',
    boxShadow: 'none',
    padding: '0.8rem 1.3rem 0.8rem 0.8rem',
    whiteSpace: 'normal',
    wordWrap: 'normal',
  },
  ({ isActive, isSelected }) => {
    const styles: CSSObject = {};

    if (isActive || isSelected) {
      styles.color = 'rgba(0,0,0,.95)';
    }

    if (isActive) {
      styles.background = 'rgba(0,0,0,.03)';
    }

    if (isSelected) {
      styles.fontWeight = 'bold';
    }

    return styles;
  }
);

export const TagAutocomplete: React.FC<TagAutocompleteProps> = (props) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<typeof Input>(null);

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (!props.isOpen || menuRef.current === null) {
        return;
      }

      if (menuRef.current.contains(e.target as Node)) {
        // Internal click
        return;
      }

      // Clicked outside
      props.onClose();
    };

    document.addEventListener('mousedown', handleClick, false);

    return function cleanUp() {
      document.addEventListener('mousedown', handleClick, false);
    };
  }, [props.isOpen, menuRef, props]);

  return (
    <MultiSelect
      isOpen={props.isOpen}
      itemToString={(item) => (item ? item.label : '')}
      initialSelectedItem={props.selectedTags}
      onChange={props.onTagsChanged}
    >
      {({
        getRootProps,
        getInputProps,
        getMenuProps,
        removeItem,

        isOpen,
        inputValue,
        selectedItems,
        getItemProps,
        highlightedIndex,
        toggleMenu,
      }) => {
        if (!isOpen) {
          return null;
        }

        // Selected tags should be first then lexicographical
        const selectedItemIds = selectedItems.map((i: any) => i.id);

        const sortedTags = orderBy(
          props.tags,
          [
            (tag) => Boolean(selectedItemIds.find((id: number) => tag.id === id) !== undefined),
            'label',
          ],
          ['desc', 'asc']
        );

        return (
          <Box sx={{ borderRadius: '4px' }}>
            <Container
              {...getRootProps({
                ref: menuRef,
                'data-testid': 'tag-autocomplete',
                onClick: () => {
                  toggleMenu();
                  if (!isOpen && inputRef.current) {
                    (inputRef.current as any).focus();
                  }
                },
              })}
            >
              <Box
                sx={{
                  padding: '1rem 1.3rem 0 0.8rem',
                  marginLeft: '0 !important',
                }}
              >
                <Input
                  {...getInputProps({
                    ref: inputRef,
                    placeholder: 'Search Tags',
                    onClick(e: any) {
                      e.stopPropagation();
                    },
                    onKeyUp(e: any) {
                      if (e.key === 'Backspace' && !inputValue) {
                        removeItem(selectedItems[selectedItems.length - 1]);
                      }
                    },
                  })}
                />
              </Box>

              <ItemList {...getMenuProps({ isOpen })}>
                {sortedTags
                  .filter(
                    (item) =>
                      !inputValue || item.label.toLowerCase().includes(inputValue.toLowerCase())
                  )
                  .map((item, index) => (
                    <Item
                      key={item.id}
                      {...getItemProps({
                        item,
                        index,
                        isActive: highlightedIndex === index,
                        isSelected: selectedItems.findIndex((i: any) => i.id === item.id) !== -1,
                      })}
                    >
                      <ItemIdentifier
                        backgroundColor={item.backgroundColor}
                        borderColor={item.borderColor}
                      />
                      {item.label}
                    </Item>
                  ))}
              </ItemList>
            </Container>
          </Box>
        );
      }}
    </MultiSelect>
  );
};
