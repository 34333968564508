import * as React from 'react';
import { ThemeUIStyleObject } from 'theme-ui';

import { Flex } from '../../../components/common/Flex';
import { Grid } from '../../../components/common/Grid';
import { Text } from '../../../components/common/Text';

interface StatisticProps {
  name: string;
  value: string | number | undefined;
  unit?: string;
}

export const StatisticRow: React.FC<StatisticProps> = ({ name, value, unit }) => {
  return (
    <Grid key={name} sx={{ alignItems: 'center', gridTemplateColumns: '2fr 1fr', columnGap: 2 }}>
      <Text sx={{ fontSize: 4, fontWeight: 'semibold' }}>{name}</Text>
      <Text sx={{ fontSize: 5, fontWeight: 'bold' }}>
        {value !== undefined ? value : '-'}
        {unit}
      </Text>
    </Grid>
  );
};

interface StatsSectionProps {
  title?: string;
  sx?: ThemeUIStyleObject;
}

export const StatisticRowSection: React.FC<StatsSectionProps> = ({ children, title, sx }) => (
  <Flex sx={{ flexDirection: 'column', ...sx }}>
    <Text sx={{ fontSize: 4, fontWeight: 600, color: '#666', p: 2 }}>{title || <>&nbsp;</>}</Text>
    {children}
  </Flex>
);
