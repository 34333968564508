export const prependAutoFillPrefix = (
  autoFillPrefix?: string | any,
  value?: string | string[] | any
) => {
  if (autoFillPrefix == null || typeof autoFillPrefix !== 'string') {
    return value;
  }

  if (value == null || (typeof value !== 'string' && !Array.isArray(value))) {
    return value;
  }

  return Array.isArray(value)
    ? value.map((val) => prependPrefix(autoFillPrefix, val))
    : prependPrefix(autoFillPrefix, value);
};

const prependPrefix = (autoFillPrefix: string, value: string | any) => {
  if (value == null || typeof value !== 'string' || value.length === 0) {
    return value;
  }

  return prefixExists(autoFillPrefix, value) ? value : `${autoFillPrefix} ${value}`;
};

export const removeAutoFillPrefix = (
  autoFillPrefix?: string | any,
  value?: string | string[] | any
) => {
  if (autoFillPrefix == null || typeof autoFillPrefix !== 'string') {
    return value;
  }

  if (value == null || (typeof value !== 'string' && !Array.isArray(value))) {
    return value;
  }

  return Array.isArray(value)
    ? value.map((val) => removePrefix(autoFillPrefix, val))
    : removePrefix(autoFillPrefix, value);
};

const removePrefix = (autoFillPrefix: string, value: string | any) => {
  if (value == null || typeof value !== 'string') {
    return value;
  }

  return prefixExists(autoFillPrefix, value) ? value.replace(autoFillPrefix, '').trim() : value;
};

const prefixExists = (autoFillPrefix: string, value: string) => value.startsWith(autoFillPrefix);

type ChangeEvent = React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>;

interface AutoFillPrefixFocusOptions {
  autoFillPrefix?: string;
  event: ChangeEvent;
  setField: (val: string) => void;
}

export const onAutoFillPrefixFocus = (options: AutoFillPrefixFocusOptions) => {
  const { autoFillPrefix, event, setField } = options;

  const { value } = event.currentTarget;
  if (!autoFillPrefix || value) {
    return;
  }

  const newVal = `${autoFillPrefix} `;

  setField(newVal);

  // Hacky way to move the cursor to the end of line
  event.persist();
  setTimeout(() => {
    event.target.selectionStart = newVal.length;
    event.target.selectionEnd = newVal.length;
  }, 10);
};

interface AutoFillPrefixChangeOptions {
  autoFillPrefix?: string;
  event: ChangeEvent;
  setField: (val: string) => void;
  handleEvent: (e: ChangeEvent) => void;
}

export const onAutoFillPrefixChange = (options: AutoFillPrefixChangeOptions) => {
  const { autoFillPrefix, event, setField, handleEvent } = options;

  const { value, defaultValue } = event.currentTarget;

  // No prefix - Delegate back to the field handler
  // No value - User has deleted all of the text
  if (!autoFillPrefix || !value) {
    handleEvent(event);
    return;
  }

  // User has entered a character in an empty input. Prefix the value with the auto fill prefix
  if (value.length === 1) {
    setField(`${autoFillPrefix} ${value}`);
    return;
  }

  // User has backspaced on the prefix. Remove all of it.
  if (value.length === autoFillPrefix.length - 1) {
    setField('');
    return;
  }

  // User has added a space to the prefix
  if (defaultValue === autoFillPrefix && value.startsWith(`${autoFillPrefix} `)) {
    handleEvent(event);
    return;
  }

  // User started with an empty input and either pasted or ctrl-z a valid input
  if (defaultValue === '' && value.startsWith(`${autoFillPrefix} `)) {
    handleEvent(event);
    return;
  }

  // The value starts with the prefix but there is no following space
  // If this is the case then insert the space
  if (value.startsWith(autoFillPrefix) && !value.startsWith(`${autoFillPrefix} `)) {
    const remaining = value.replace(autoFillPrefix, '');

    // If the remaining is a blank string then use the provided value
    // this allows the user to remove the prefix

    if (remaining.trim() === '') {
      handleEvent(event);
      return;
    }

    setField(`${autoFillPrefix} ${remaining}`);
    return;
  }

  if (!defaultValue.startsWith(`${autoFillPrefix} `)) {
    setField(`${autoFillPrefix} ${value}`);
    return;
  }

  // User is trying to edit the prefix. Ignore.
  if (!value.startsWith(autoFillPrefix)) {
    return;
  }

  // No other checks. Delegate back to the field handler
  handleEvent(event);
};
