import { RouteComponentProps } from '@reach/router';
import * as React from 'react';
import { toast } from 'react-toastify';

import { ApiUser } from '../../../api/auth';
import { companyApi } from '../../../api/company';
import { Box } from '../../../components/common/Box';
import { Flex } from '../../../components/common/Flex';
import { Heading } from '../../../components/common/Heading';
import { ErrorBoundary } from '../../../components/ErrorBoundary';
import { InviteUserButton } from '../../../components/InviteUserButton';
import { PlanHeader } from '../../../components/PlanHeader';
import { useCompany } from '../../../context/CompanyContext';
import { useUser } from '../../../context/UserContext';
import { RuntimeError } from '../../../Error/BaseErrors';
import logger from '../../../utils/logger';
import { CompanyInviteList, ResendAction, RevokeAction } from './CompanyInviteList';
import { CompanyLicencesStats } from './CompanyLicencesStats';
import { UserList } from './UserList';

export const CompanyUserList: React.FC<RouteComponentProps> = () => {
  const { company } = useCompany();

  if (!company) {
    throw new RuntimeError(
      'Something went wrong whilst loading the users',
      "User doesn't have belong to a company yet so they shouldn't have access"
    );
  }

  const user = useUser();
  const {
    status: fetchingMembersStatus,
    data: companyMembersData,
    error: fetchingMembersError,
    refetch: refetchMembers,
  } = companyApi.useCompanyMembers(company.id);

  const {
    status: fetchingExpiredInvitesStatus,
    data: companyExpiredInvitesData,
    error: fetchingExpiredInvitesError,
    refetch: refetchExpiredInvites,
  } = companyApi.useCompanyExpiredInvites(company.id);

  const {
    status: fetchingInvitesStatus,
    data: companyInvitesData,
    error: fetchingInvitesError,
    refetch: refetchInvites,
  } = companyApi.useCompanyInvites(company.id);

  const { companyMembers } = companyMembersData || {};
  const { invites: companyInvites } = companyInvitesData || {};
  const { invites: companyExpiredInvites } = companyExpiredInvitesData || {};

  const licencesInUse =
    companyMembers && companyInvites ? companyMembers.length + companyInvites?.length : undefined;

  const onRefreshInvites = React.useCallback(async () => {
    await Promise.all([refetchInvites(), refetchExpiredInvites()]);
  }, [refetchInvites, refetchExpiredInvites]);

  return (
    <Flex sx={{ letterSpacing: '-0.01rem', justifyContent: 'center', px: 5, pt: 7, pb: 9 }}>
      <Flex sx={{ flex: 1, flexDirection: 'column', maxWidth: '1000px' }}>
        <ErrorBoundary>
          <Flex sx={{ flex: 1, flexDirection: 'column', mb: 7 }}>
            <Box sx={{ m: '0 0 2rem' }}>
              <PlanHeader
                title="Current Plan:"
                plan={
                  <React.Fragment>
                    {company.companySubscription.tierName}{' '}
                    {company.subscriptionStatus === 'in_trial' ? '(Trial)' : null}
                  </React.Fragment>
                }
              />
            </Box>

            <CompanyLicencesStats total={company.companySubscription.seats} used={licencesInUse} />
          </Flex>
        </ErrorBoundary>

        <ErrorBoundary>
          <Flex sx={{ flex: 1, flexDirection: 'column', mb: 7 }}>
            <Heading
              as="h2"
              sx={{
                pt: 5,
                m: '0 0 2rem',
                fontSize: 6,
                fontWeight: 'semibold',
                border: 0,
                borderTopWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'grey.3',
                letterSpacing: '-0.05rem',
              }}
            >
              User list:
            </Heading>

            <UserList
              currentUser={user}
              users={companyMembers || []}
              fetchingMembers={fetchingMembersStatus === 'loading'}
              error={fetchingMembersError ? fetchingMembersError.message : undefined}
              onFetchMembers={refetchMembers}
              onRemoveMember={async (member: ApiUser) => {
                try {
                  await companyApi.removeCompanyMember(company.id, member.id);
                  toast.success('The user has been removed from the company');
                } catch (e) {
                  logger.logError(e);
                  toast.error('Something went wrong whilst removing the user');
                }
              }}
            />
          </Flex>
        </ErrorBoundary>

        <ErrorBoundary>
          <Flex sx={{ flex: 1, flexDirection: 'column', mb: 7 }}>
            <Heading
              as="h2"
              sx={{
                pt: 5,
                m: '0 0 2rem',
                fontSize: 6,
                fontWeight: 'semibold',
                border: 0,
                borderTopWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'grey.3',
                letterSpacing: '-0.05rem',
              }}
            >
              Invite list:
            </Heading>

            <CompanyInviteList
              invites={companyInvites || []}
              fetchingInvites={fetchingInvitesStatus === 'loading'}
              error={fetchingInvitesError ? fetchingInvitesError.message : undefined}
              renderActions={(invite) => {
                return <RevokeAction invite={invite} onFetchInvites={refetchInvites} />;
              }}
            />

            <Flex sx={{ mt: 5 }}>
              <InviteUserButton
                disabled={licencesInUse ? licencesInUse >= company.companySubscription.seats : true}
                onInviteSent={() => {
                  toast.success('Invite sent successfully!');
                  refetchInvites();
                }}
              />
            </Flex>
          </Flex>
        </ErrorBoundary>

        <ErrorBoundary>
          <Flex sx={{ flex: 1, flexDirection: 'column', mb: 7 }}>
            <Heading
              as="h2"
              sx={{
                pt: 5,
                m: '0 0 2rem',
                fontSize: 6,
                fontWeight: 'semibold',
                border: 0,
                borderTopWidth: '1px',
                borderStyle: 'solid',
                borderColor: 'grey.3',
                letterSpacing: '-0.05rem',
              }}
            >
              Expired invite list:
            </Heading>

            <CompanyInviteList
              invites={companyExpiredInvites || []}
              fetchingInvites={fetchingExpiredInvitesStatus === 'loading'}
              error={fetchingExpiredInvitesError ? fetchingExpiredInvitesError.message : undefined}
              renderActions={(invite) => {
                return (
                  <Flex>
                    <Box sx={{ mr: 3 }}>
                      <ResendAction
                        invite={invite}
                        canResend={
                          licencesInUse ? licencesInUse >= company.companySubscription.seats : true
                        }
                        onRefresh={onRefreshInvites}
                      />
                    </Box>
                    <RevokeAction invite={invite} onFetchInvites={refetchExpiredInvites} />
                  </Flex>
                );
              }}
            />
          </Flex>
        </ErrorBoundary>
      </Flex>
    </Flex>
  );
};
