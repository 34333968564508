import qs from 'qs';
import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { ApiAdvertTag } from './advert-tags';
import { ApiUser } from './auth';
import { client } from './client';
import { Paginated } from './types';

export enum DropdownBoolean {
  No = 0,
  Yes = 1,
}

export enum BooleanType {
  No = 0,
  Yes = 1,
}

export interface BooleanTypeOption {
  label: string;
  value: BooleanType;
}

export const booleanTypes: BooleanTypeOption[] = [
  { label: 'Yes', value: BooleanType.Yes },
  { label: 'No', value: BooleanType.No },
];

export const getBooleanTypeByValue = (value: BooleanType) => {
  const matched = booleanTypes.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching boolean type for ${value}`);
  }

  return matched;
};

export enum LocationType {
  OnSite = 1,
  Office = 2,
  Field = 3,
  Home = 4,
  PartOfficeHome = 5,
  Other = 6,
}

export const locationTypes = [
  { label: 'Office', value: LocationType.Office },
  { label: 'Home', value: LocationType.Home },
  { label: 'Part office / Part-home working', value: LocationType.PartOfficeHome },
  { label: 'Field', value: LocationType.Field },
  { label: 'On-site', value: LocationType.OnSite },
  { label: 'Other', value: LocationType.Other },
];

export const getLocationTypeByValue = (value: LocationType) => {
  if(value === undefined) {
    value = LocationType.Office;
  }
  const matched = locationTypes.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching location type for ${value}`);
  }

  return matched;
};

export enum PositionType {
  Permanent = 1,
  FixedTermContract = 2,
  Temporary = 3,
  Apprentice = 4,
  ZeroHoursContract = 5,
}

export const positionTypes = [
  { label: 'Permanent', value: PositionType.Permanent },
  { label: 'Fixed Term Contract', value: PositionType.FixedTermContract },
  { label: 'Temporary', value: PositionType.Temporary },
  { label: 'Apprentice', value: PositionType.Apprentice },
  { label: 'Zero Hours Contract', value: PositionType.ZeroHoursContract },
];

export const getPositionTypeByValue = (value: PositionType) => {
  const matched = positionTypes.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching position type for ${value}`);
  }

  return matched;
};

export enum JobType {
  FullTime = 1,
  PartTime = 2,
}

export const jobTypes = [
  { label: 'Full time', value: JobType.FullTime },
  { label: 'Part time', value: JobType.PartTime },
];

export const getJobTypeByValue = (value: JobType) => {
  const matched = jobTypes.find((type) => type.value === value);

  if (!matched) {
    throw new Error(`Could not find a matching job type for ${value}`);
  }

  return matched;
};

interface ApiAdvertCompany {
  id: number;
  rootId: number;
  name: string;
  data: {
    [key: string]: any;
  };
}

interface ApiAdvert {
  id: number;
  createdAt: string;
  createdBy: ApiUser;
  updatedAt: string;
  updatedBy: ApiUser;
  jobTitle: string;
  scoreInclusivity: number;
  scoreReadability: number;
  scoreGenderTone: string;
  advertCompany: ApiAdvertCompany;
  locationType: LocationType;
  jobLocation: string;
  jobType: JobType;
  positionType: PositionType;
  advertAnswers: AdvertAnswer[];
  tags: ApiAdvertTag[];
  isFavourited: boolean;
  isSharedAndUnseen: boolean;
  sharedAt?: string;
  advertContent: string;
}

export interface AdvertAnswer {
  id: number;
  questionId: number;
  value: string;
}

export interface Advert
  extends Omit<ApiAdvert,
    'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy' | 'sharedAt'> {
  createdAt: Date;
  createdBy: ApiUser;
  updatedAt: Date;
  updatedBy: ApiUser;
  sharedAt?: Date;
  isComplete: boolean;
}

export function isAdvert(advert: Advert | undefined): advert is Advert {
  return (advert as Advert) !== undefined;
}

export interface ApiAdvertCounts {
  user: number;
  team: number;
  shared: number;
  unseenShared: number;
  favourite: number;
  company: number;
}

export interface ApiAdvertAutocompleteResult {
  id: number;
  jobTitle: string;
  companyName: string;
  location: string;
  createdByName: string;
  createdDate: string;
}

const formatApiAdvert = (apiAdvert: ApiAdvert): Advert => {
  return {
    ...apiAdvert,
    createdAt: new Date(apiAdvert.createdAt),
    updatedAt: new Date(apiAdvert.updatedAt),
    sharedAt: apiAdvert.sharedAt ? new Date(apiAdvert.sharedAt) : undefined,
    // TODO remove isComplete
    isComplete: true,
  };
};

export type ApiAdvertSummaryListResult = Paginated<ApiAdvertSummary>;

export interface ApiAdvertSummary {
  id: number;
  jobTitle: string;
  jobLocation: string;
  jobType: JobType;
  positionType: PositionType;
  advertCompany: {
    id: number;
    name: string;
  };
  tags: ApiAdvertTag[];
  createdAt: Date;
  createdBy: ApiUser;
  isFavourited: boolean;
  isSharedAndUnseen: boolean;
  sharedAt?: Date;
}

export interface FetchAdvertsOptions {
  page: number;
  perPage: number;
  sortFields: { field: string; direction: 'ASC' | 'DESC' }[];
  filters: { field: string; value: any }[];
}

const fetchAdverts = async (
  url: string,
  options: FetchAdvertsOptions,
): Promise<ApiAdvertSummaryListResult> => {
  const params = qs.stringify(
    {
      page: options.page,
      perPage: options.perPage,
      sortFields: options.sortFields,
      filters: options.filters,
    },
    { arrayFormat: 'indices' },
  );

  const response = await client.get(`${url}?${params}`);

  return {
    page: response.data.page,
    limit: response.data.limit,
    total: response.data.total,
    pages: response.data.pages,
    items: response.data.items.map(formatApiAdvert),
  };
};

const fetchMyAdverts = async (options: FetchAdvertsOptions): Promise<ApiAdvertSummaryListResult> =>
  fetchAdverts('/listing/me', options);

const fetchTeamAdverts = async (
  options: FetchAdvertsOptions,
): Promise<ApiAdvertSummaryListResult> => fetchAdverts('/listing/team', options);

const fetchSharedAdverts = async (
  options: FetchAdvertsOptions,
): Promise<ApiAdvertSummaryListResult> => fetchAdverts('/listing/shared', options);

const fetchFavouriteAdverts = async (
  options: FetchAdvertsOptions,
): Promise<ApiAdvertSummaryListResult> => fetchAdverts('/listing/favourite', options);

const fetchCompanyAdverts = async (
  options: FetchAdvertsOptions,
): Promise<ApiAdvertSummaryListResult> => fetchAdverts('/listing/company', options);

const fetchAdvertCounts = async (): Promise<ApiAdvertCounts> => {
  const response = await client.get('/listing/counts');

  return response.data;
};

const usePrefetchAdvertCountsFn = () => {
  const queryClient = useQueryClient();

  const prefetchAdvertCounts = useCallback(async () => {
    await queryClient.prefetchQuery(['advertCounts'], fetchAdvertCounts);
  }, [queryClient]);

  return prefetchAdvertCounts;
};

const search = async (query: string): Promise<{ adverts: ApiAdvertAutocompleteResult[] }> => {
  const response = await client.get(`/listing/search?query=${query}`);

  return {
    adverts: response.data.adverts,
  };
};

const fetchAdvert = async (advertId: number): Promise<{ advert: Advert }> => {
  const response = await client.get(`/listing/${advertId}`);

  return {
    advert: formatApiAdvert(response.data.advert),
  };
};

const usePrefetchAdvertFn = (advertId: number) => {
  const queryClient = useQueryClient();

  const prefetchAdvert = useCallback(async () => {
    await queryClient.prefetchQuery(['advert', advertId], async () => {
      const response = await advertApi.fetchAdvert(advertId);

      return response.advert;
    });
  }, [queryClient, advertId]);

  return prefetchAdvert;
};

export interface FormAdvert {
  jobTitle: string;
  jobLocation: string;
  jobType: JobType;
  positionType: PositionType;
}

const createAdvert = async (data: FormAdvert): Promise<{ advert: Advert }> => {
  const response = await client.post('/listing', data);

  return {
    advert: formatApiAdvert(response.data.advert),
  };
};

const updateAdvert = async (
  advertId: number,
  advert: FormAdvert,
  options?: {},
): Promise<{ advert: Advert }> => {
  const response = await client.post(`/listing/${advertId}`, {
    advert,
    ...options,
  });

  return {
    advert: formatApiAdvert(response.data.advert),
  };
};

const updateDynamicAnswers = async (
  advertId: number,
  advert: { advertAnswers: { [key: number]: any }[] },
  options?: {},
): Promise<{ advert: Advert }> => {
  const response = await client.post(`/listing/${advertId}`, {
    advert,
    ...options,
  });

  return {
    advert: formatApiAdvert(response.data.advert),
  };
};

const duplicateAdvert = async (advertId: number): Promise<{ advert: Advert }> => {
  const response = await client.post(`/listing/${advertId}/duplicate`);

  return {
    advert: formatApiAdvert(response.data.advert),
  };
};

export interface DeleteAdvertResponse {
  advert: {
    id: number;
  };
  deleted: boolean;
}

const deleteAdvert = async (advertId: number): Promise<DeleteAdvertResponse> => {
  const response = await client.delete(`/listing/${advertId}`);

  return {
    advert: response.data.advert,
    deleted: response.data.deleted,
  };
};

const shareAdvert = async (advertId: number, userIds: number[]): Promise<{ shared: boolean }> => {
  const response = await client.post(`/listing/${advertId}/share`, { users: userIds });

  return { shared: response.data.shared };
};

export interface FavouriteAdvertVariationResponse {
  advert: {
    id: number;
  };
  favourite: {
    id: number;
  };
}

const favouriteAdvertVariation = async (
  advertId: number,
  advertVariationId: number,
): Promise<FavouriteAdvertVariationResponse> => {
  const response = await client.post(
    `/listing/${advertId}/listing-variation/${advertVariationId}/favourite`,
  );

  return { advert: response.data.advert, favourite: response.data.favourite };
};

export interface UnfavouriteAdvertResponse {
  advert: {
    id: number;
  };
  unfavourite: boolean;
}

const unfavouriteAdvert = async (advertId: number): Promise<UnfavouriteAdvertResponse> => {
  const response = await client.post(`/listing/${advertId}/unfavourite`);

  return { advert: response.data.advert, unfavourite: response.data.unfavourite };
};

export const advertApi = {
  fetchMyAdverts,
  fetchTeamAdverts,
  fetchSharedAdverts,
  fetchFavouriteAdverts,
  fetchCompanyAdverts,
  fetchAdvertCounts,
  search,
  fetchAdvert,
  createAdvert,
  updateAdvert,
  updateDynamicAnswers,
  duplicateAdvert,
  deleteAdvert,
  shareAdvert,
  favouriteAdvertVariation,
  unfavouriteAdvert,
  usePrefetchAdvertFn,
  usePrefetchAdvertCountsFn,
};
