import { Flex } from '../../../components/common/Flex';
import { Heading } from '../../../components/common/Heading';
import { Text } from '../../../components/common/Text';

export const NoAdsWarning = () => {
  return (
    <Flex sx={{ justifyContent: 'center', alignItems: 'center', flexDirection: 'column', py: 12 }}>
      <Heading as="h1" sx={{ letterSpacing: '-0.05rem', fontSize: 6, fontWeight: 'semibold' }}>
        <Text>No adverts modified in selected date range</Text>
      </Heading>
      <Text sx={{ fontSize: 4, pt: 4 }}>Try selecting a different date range option</Text>
    </Flex>
  );
};
