import { RuntimeError } from '../Error/BaseErrors';
import { ApiCompany } from './auth';
import { client } from './client';
import { BillingDetails } from './company-subscription';

interface CreateCompanyRequestParams {
  companyName: string;
  businessType: number;
  employeeCount: number;
  usageReferredFrom: number;
  usageReferredFromOther: string;
}

interface CreateCompanyResponse {
  company: ApiCompany;
}

const createCompany = async (
  params: CreateCompanyRequestParams
): Promise<CreateCompanyResponse> => {
  const response = await client.post('/auth/register/company', {
    companyName: params.companyName,
    businessType: params.businessType,
    employeeCount: params.employeeCount,
    usageReferredFrom: params.usageReferredFrom,
    usageReferredFromOther: params.usageReferredFromOther,
  });

  return {
    company: response.data.company,
  };
};

export interface NewSubscriptionConfig {
  companyId: number;
  billingDetails: BillingDetails;
  planId: string;
  seats: number;
  discountToken?: string;
}

interface CreateSubscriptionResponse {
  subscriptionCreated?: boolean;
  error?: string;
}

const createSubscription = async (config: NewSubscriptionConfig) => {
  const { stripe, ...billingInformation } = config.billingDetails;

  if (!stripe) {
    throw new RuntimeError(
      'Something went wrong whilst attempting to create a subscription',
      'Attempted to create a new subscription but no stripe token was provided'
    );
  }

  const response = await client.post<CreateSubscriptionResponse>(`/auth/register/subscription`, {
    billingInformation,
    paymentGatewaySourceToken: stripe.token,
    planId: config.planId,
    seats: config.seats,
    discountToken: config.discountToken,
  });

  return response.data;
};

export const accountSetupApi = {
  createCompany,
  createSubscription,
};
